import React,{useState} from 'react';
import {useDispatch,useSelector,shallowEqual} from 'react-redux';

import Popup from "reactjs-popup";

import './ToolsPanel.scss';

import {
  createBasicStaff,
  crearCuadrado,
  crearCirculo,
  crearTextoEstatico,
  createText2,
  verElementosEditor,
  createImage,
  createExercise,
  createExerciseFFAndTest,
  createExerciseImageTest,
  createSvg,
  crearTriangulo} from './PanelHerramientasControlador.jsx';

import {tipoElemento} from '../../../Constantes/Elementos.js';
import {typeCss} from '../../../Constantes/TypeCss';

import MolEditorElementIcon from '../../Molecules/Mol_EditorElementIcon/Mol_EditorElementIcon';
import AtButton from '../../Atoms/At_Button/At_Button';
import InsertMultimediaIntoElement from '../PanelEditor/InsertMultimediaIntoElement';
import AtPopup from '../../Atoms/At_Popup/At_Popup';
import ColoringSvgInsert from '../Exercises/ColoringSvg/ColoringSvgInsert';



import { ImageIcon } from '../../Atoms/At_Image/At_Image';
import {tipoRecurso} from '../../../Constantes/TiposRecursos';
import {exercises} from '../../../Constantes/Exercises';

const ToolsPanel = (props) => {
    let {
      thisFile,
    }=props


  const dispatch=useDispatch();
  const elementosEditor=useSelector((state)=>{
    return state.elementosEditor;
  },shallowEqual);

  const onClickCrearCuadrado=()=>{
    crearCuadrado(dispatch);
  };

  const onClickCrearCirculo=()=>{
    crearCirculo(dispatch);
  };

  const onClickCrearTriangulo=()=>{
    crearTriangulo(dispatch);
  };

  const onClickCrearTextoEstatico=()=>{
    crearTextoEstatico(dispatch);
  };

  const onClickCreateText2=()=>{
    createText2(dispatch);
  }


  const onClickInsertPhoto=(multimedia)=>{

    createImage(dispatch,multimedia.id,multimedia,tipoElemento.Image);

  }

  const onClickInsertSvg=(svg)=>{
    
    createSvg(dispatch,exercises.colorItem,svg);
  }

  const onClickCreateExercise=(exercise)=>{
    createExercise(dispatch,exercise,thisFile[0].caracteristicas.dataExercise);
  }

  const onClickcreateExerciseFFAndTest=(exercise)=>{
    createExerciseFFAndTest(dispatch,exercise);
  }

  const onClickcreateExerciseImageTest=(exercise)=>{
    createExerciseImageTest(dispatch,exercise);
  }

  const onClickCreateBasicStaff=(exercise)=>{
    createBasicStaff(dispatch,exercise);
  }

  const [popupClassNameGeometry,setPopupClassNameGeometry]= useState("");
  const [popupClassNameExercises,setPopupClassNameExercises]= useState("");

  const onClickOpenPopupGeometry=()=>{
    if(popupClassNameGeometry===""){
      setPopupClassNameGeometry("show");
    }else{
      setPopupClassNameGeometry("");
    }
    
  }

  const onClickOpenPopupExercises=()=>{
    if(popupClassNameExercises===""){
      setPopupClassNameExercises("show");
    }else{
      setPopupClassNameExercises("");
    }
    
  }

  let stylePopupContent= {
         
    "width": "941px",
    "position": "relative",
    "background": "rgb(255, 255, 255)",
    "margin": "auto",
    "padding":0,
    "border":"none",
  }


  return (   
    <div className="tools-panel">

      <div className="tools-panel__item popup" onClick={onClickOpenPopupGeometry}>
      <MolEditorElementIcon text="Geometria" image={ImageIcon.figureToolFigureSquare} widgth="40px" height="40px" ></MolEditorElementIcon>
          <div className={"popuptext "+popupClassNameGeometry}>

            <div className="tools-panel__popup-item" >
                <AtButton image={ImageIcon.figureSquare} widthImg="22"  heightImg="22" type={typeCss.tertiary} onClick={onClickCrearCuadrado}></AtButton>
            </div>

            <div className="tools-panel__popup-item" >
                <AtButton image={ImageIcon.figureCircle} widthImg="22"  heightImg="22" type={typeCss.tertiary} onClick={onClickCrearCirculo}></AtButton>
            </div>

          </div>
      </div>

      

      <div className="tools-panel__item">
          <MolEditorElementIcon text="Texto" image={ImageIcon.toolText} widgth="40px" height="40px" onClick={onClickCrearTextoEstatico}></MolEditorElementIcon>
      </div>

      <div className="tools-panel__item">
          <MolEditorElementIcon text="Texto2" image={ImageIcon.toolText} widgth="40px" height="40px" onClick={onClickCreateText2}></MolEditorElementIcon>
      </div>

      <div className="tools-panel__item">
          <AtPopup 
          trigger={
            <MolEditorElementIcon text="Imagen" image={ImageIcon.toolPhoto} widgth="40px" height="40px" ></MolEditorElementIcon>
          }  className="popup-insert-multimedia">
                <InsertMultimediaIntoElement  filterBy={tipoRecurso.imagen} onClick={onClickInsertPhoto}></InsertMultimediaIntoElement>
          </AtPopup>
          
      </div>


      <div className="tools-panel__item popup" onClick={onClickOpenPopupExercises}>
      <MolEditorElementIcon text="Ejercicios" image={ImageIcon.figureToolFigureSquare} widgth="40px" height="40px" ></MolEditorElementIcon>
          <div className={"popuptext "+popupClassNameExercises}>

            <div className="tools-panel__popup-item" >
                <AtButton image={ImageIcon.TestSelect} widthImg="22"  heightImg="22" type={typeCss.tertiary} onClick={()=>{onClickcreateExerciseFFAndTest(exercises.textSelect)}}></AtButton>
            </div>

            <div className="tools-panel__popup-item" >
                <AtButton image={ImageIcon.TestSelectImage} widthImg="22"  heightImg="22" type={typeCss.tertiary} onClick={()=>{onClickcreateExerciseImageTest(exercises.imagesTest)}}></AtButton>
            </div>

            <div className="tools-panel__popup-item" >
                <AtButton image={ImageIcon.FillableField} widthImg="22"  heightImg="22" type={typeCss.tertiary} onClick={()=>{onClickcreateExerciseFFAndTest(exercises.fillableField)}}></AtButton>
            </div>

            <div className="tools-panel__popup-item" >
                <AtButton image={ImageIcon.DraggAndDrop} widthImg="22"  heightImg="22" type={typeCss.tertiary} onClick={()=>{onClickCreateExercise(exercises.dragAndDrop)}}></AtButton>
            </div>

            <div className="tools-panel__popup-item" >
              <AtPopup trigger={<AtButton image={ImageIcon.ColoringSvg} widthImg="22"  heightImg="22" type={typeCss.tertiary}></AtButton>}>
                  <ColoringSvgInsert onClickElem={onClickInsertSvg}></ColoringSvgInsert>
              </AtPopup>
            </div>

          </div>
      </div>
    </div>
  )
};

export default ToolsPanel;


import React,{useState} from 'react';
import {useDispatch} from 'react-redux';
import {setColorSvg} from '../../../../Store/Actions/EditorElementosActions';



const ColoringSvgCreate=(props)=>{
    let {
        idElement,
        color
    }=props;
    let dispatch=useDispatch();
    
    const [newColor,setNewColor]=useState(color);

    const selectColor=(eventColor)=>{
       
        setNewColor(eventColor);
    }

    const onClickSetColor=()=>{
       
        dispatch(setColorSvg(idElement,newColor));
    }

    return(
        <div>
            <input onChange={event=>selectColor(event.target.value)}  type="color"></input>
            <button onClick={onClickSetColor}>COLOR</button>
        </div>
    )
}

export default ColoringSvgCreate;
import React,{useState,useEffect} from 'react';
import {useDispatch} from 'react-redux';
import './ImageTestCreate.scss';

import AtInputText from '../../../Atoms/At_InputText/At_InputText';
import AtTitle from '../../../Atoms/At_Title/At_Title';
import AtButton from '../../../Atoms/At_Button/At_Button';
import AtSelect from '../../../Atoms/At_Select/At_Select';
import SelectImage from './SelectImage';
import MolEnrichedTextFontOptions from '../../../Molecules/Mol_EnrichedTextFontOptions/Mol_EnrichedTextFontOptions';
import AtPopup from '../../../Atoms/At_Popup/At_Popup';
import {toolbarTypes} from '../../../Atoms/At_EnrichedText/At_EnrichedText';

import QuestionAltImage from '../../../../Images/ExerciseResponse1.png';
import AnswerAltImage from '../../../../Images/ExerciseResponse2.png';

import {editExerciseIT} from '../../../../Store/Actions/EditorElementosActions';
import {PositionAnswerSelect,imagePrevAnswerSelect,typeQuestionOfITSelect,typeQuestionOfIT} from '../../../../Constantes/ExercisesVars';

import {idiomas,idioms} from '../../../../Constantes/idiomas';
import {typeCss} from '../../../../Constantes/TypeCss';
import { exercises } from '../../../../Constantes/Exercises';


let allQuestion;
const ImageTestCreate=(props)=>{
    let {
        element,
        exercise
    }=props;
  
    const RightWrong =[
        [true,"Correcto"],
        [false,"Incorrecto"]
    ];
   
    //const [numCorrect,setNumCorrect]=useState(exercise.numCorrect);
    
    const [questionEditing,setQuestionEditing]=useState(exercise.questionData[0]);
    const [actualyIdiom,setActualyIdiom]=useState(idiomas.castellano);
    const [positionAnswer,setPositionAnswer]=useState();
    const [iconPrevAnswer,setIconPrevAnswer]=useState();
    const [typeQuestion,setTypeQuestion]=useState();
    const [fontStyle,setFontStyle]=useState();

    let dispatch=useDispatch();

    useEffect(() => {
        setQuestionEditing(exercise.questionData[0]);
       
       
        allQuestion=exercise.questionData;
        setPositionAnswer(exercise.positionAnswer);
        setIconPrevAnswer(exercise.iconPrevAnswer);
        if(exercise.typeQuestion!==undefined){
            setTypeQuestion(exercise.typeQuestion);
        }else{
            setTypeQuestion(exercise.typeQuestion);
        }
        
        if(exercise.fontStyle!==undefined){
            
            setFontStyle(exercise.fontStyle);
        }else{
            
            setFontStyle({
                fontFamily:"Open Sans",
                fontSize:"20px",
                color:"#000000"
              });
        }
        
        
    }, []);

    

    const onChangeChangeIdiom=(newIdioma)=>{

        saveQuestionData();

        let newQuestionEditing=allQuestion.filter((elem)=>{
            return elem.language==newIdioma;
        });

        let questionCastellano=allQuestion.filter((elem)=>{
            return elem.language==idiomas.castellano;
        });
        
        if(newQuestionEditing.length==0){
            
            newQuestionEditing=[
                {
                    numCorrect:0,
                    language: newIdioma,
                    question: "",
                  }
            ]
        }
        if(newQuestionEditing[0].answers==undefined){
          
            newQuestionEditing=[{
                answers: questionCastellano[0].answers,
                ...newQuestionEditing[0]
            }]
        }

        if(newQuestionEditing[0].question==null){
           
            newQuestionEditing=[{
                ...newQuestionEditing[0],
                question: "",
            }]

        }
        
        setActualyIdiom(newIdioma);
        setQuestionEditing(newQuestionEditing[0]);
    }

    const saveQuestionData=()=>{
        
        let newIncorporated=false;
        let newAllQuestion=allQuestion.map((elem)=>{
            if(elem.language==actualyIdiom){
                
                newIncorporated=true;
                return questionEditing;
            }else{
                
                return elem;
            }
        });
        if(!newIncorporated){
            newAllQuestion=[...newAllQuestion,questionEditing];
        }
        
       
        allQuestion=newAllQuestion;
        
    }
    

    const onChangeRightWrong=(id,newValue)=>{

        let newAnswers=questionEditing.answers.map((elem)=>{
            if(id==elem.id){
                if(newValue=="false"){
                    return {...elem,correct:false};
                }
                if(newValue=="true"){
                    return {...elem,correct:true};
                }
                
            }else{
                return elem;
            }
        });

        setQuestionEditing({...questionEditing,answers:newAnswers});
    }  

    const onClickAddQuestion=()=>{
       
        let newAnswers=[...questionEditing.answers,{id:questionEditing.answers.length,answer:"nueva respuesta",correct:false}];

        setQuestionEditing({...questionEditing,answers:newAnswers});
    }  

    const onClickSaveQuestion=()=>{
        saveQuestionData();
        let newExercise={
            ...exercise,
            questionData:allQuestion,
            positionAnswer:positionAnswer,
            iconPrevAnswer:iconPrevAnswer,
            typeQuestion:typeQuestion,
            fontStyle:fontStyle
        }
     
        dispatch(editExerciseIT(element.ID,element.caracteristicas.exercise.idLinkExercise,newExercise));
    }  

    const onChangePositionAnswer=(newPosition)=>{
        setPositionAnswer(newPosition);
    }

    const onChangeImgPrevAnswer=(newIcon)=>{
        setIconPrevAnswer(newIcon);
    }
    const onChangeTypeQuestion=(newIcon)=>{
        setTypeQuestion(newIcon);
    }
    
    const onclickSetImgQuestion=(elem)=>{
      
        if(typeQuestionOfIT.image==typeQuestion){
            setQuestionEditing({...questionEditing,question:elem.items[0].url});
        }
        if(typeQuestionOfIT.text==typeQuestion){
            setQuestionEditing({...questionEditing,question:elem});
        }
        
    }

    const onClickSetImgAnswer=(id,newAnswer)=>{

        let newAnswers=questionEditing.answers.map((elem)=>{
            if(id==elem.id){
                return {...elem,answer:newAnswer.items[0].url}
            }else{
                return elem;
            }
        });

        setQuestionEditing({...questionEditing,answers:newAnswers});
    }


    const onClickCopyCastellanoToActualyIdiom=()=>{

        let questionCastellano=allQuestion.filter((elem)=>{
            return elem.language==idiomas.castellano;
        });
        
        setQuestionEditing({...questionEditing,answers:questionCastellano[0].answers});
    }

    
    if(questionEditing!==undefined){
        
    return (
        <div>
            <AtSelect values={idioms} onChange={event=>onChangeChangeIdiom(event.target.value)}></AtSelect>
            <div className="test-select-create__question">
                <div className="question__text">
                    <div>
                        <div>
                            <AtSelect 
                                defaultValue={exercise.positionAnswer} 
                                values={PositionAnswerSelect} 
                                onChange={event=>onChangePositionAnswer(event.target.value)}
                                >Posicion respuesta</AtSelect>
                        </div>
                        <div>
                            <AtSelect 
                                defaultValue={exercise.iconPrevAnswer} 
                                values={imagePrevAnswerSelect} 
                                onChange={event=>onChangeImgPrevAnswer(event.target.value)}
                            >Icono respuesta</AtSelect>
                        </div>
                        <div>
                            <AtSelect 
                                defaultValue={exercise.typeQuestion} 
                                values={typeQuestionOfITSelect} 
                                onChange={event=>onChangeTypeQuestion(event.target.value)}
                            >Tipo pregunta</AtSelect>
                        </div>
                    </div>
                    
                    
                </div>
                
            </div>
            <div>
                {typeQuestion==typeQuestionOfIT.image ?
                <div>
                    <img 
                        height="60px"
                        className="image-question" 
                        alt="Seleccione imagen" 
                        src={questionEditing.question}></img>
                    <AtPopup trigger={
                        <AtButton type={typeCss.secondary}>Examinar...</AtButton>
                    }    
                        occupyAll 
                        noCloseInClickOut>
                        <SelectImage onClick={onclickSetImgQuestion}></SelectImage>
                    </AtPopup>
                    
                </div> 
                :
                ""}
                {typeQuestion==typeQuestionOfIT.text ? 
                    <div>
                        <MolEnrichedTextFontOptions
                            key={"questionts-"+actualyIdiom}
                            toolbar={toolbarTypes.boldItalic}
                            text={questionEditing.question}
                            onchangeText={onclickSetImgQuestion}
                            fontStyle={fontStyle}
                            onChangeFontStyle={setFontStyle}
                        ></MolEnrichedTextFontOptions>
                    </div>
                :
                ""}
                
            </div>
            {questionEditing.answers.map((elem)=>{
                
                return <div  className="content-answers">
                            <div>
                                <img
                                    height="60px"
                                    className="image-answer" 
                                    alt="Seleccione imagen" 
                                    src={elem.answer}></img>
                                <AtPopup trigger={
                                    <AtButton type={typeCss.secondary}>Examinar...</AtButton>
                                }    
                                    occupyAll 
                                    noCloseInClickOut>
                                    <SelectImage onClick={onClickSetImgAnswer} idAnswer={elem.id}></SelectImage>
                                </AtPopup>
                                
                            </div>
                            <div className="content-answers__value">
                                <AtSelect values={RightWrong} defaultValue={elem.correct} onChange={event=>onChangeRightWrong(elem.id,event.target.value)}></AtSelect>
                            </div>
                       </div>

                
            })}
            <div className="test-select-create__buttons">
                <div className="buttons__add-answer">
                    <AtButton onClick={()=>onClickAddQuestion()}>+</AtButton>
                    <AtButton onClick={()=>onClickCopyCastellanoToActualyIdiom()}>Respuestas castellano</AtButton>
                </div>
                <div className="buttons__save">
                    <AtButton onClick={()=>onClickSaveQuestion()}>Guardar</AtButton>
                </div>
            </div>
            
        </div>
    )
    }else{
        return("")
    }
}



export default ImageTestCreate;
export const estilosElementos={
    Circulo:{          
      width: "50px",
      height: "50px",
      position:"absolute",
      backgroundColor: "#345345",       
      borderRadius: "50%", 
      transform: `rotateZ( `+0+`deg )`,
      border: "1px none #000000",
      zIndex:1,

    },
    Cuadrado:{        
      
      width: "50px",
      height: "50px",
      position:"absolute",
      backgroundColor: "#345345",
      transform: `rotateZ( `+0+`deg )`,
      border: "1px none #000000",    
      zIndex:1,   
      
        
    },
    Triangulo:{
      width: "50px",
      height: "50px",
      borderRight: "100px solid transparent",
      borderTop: "100px solid transparent",
      borderLeft: "100px solid transparent",
      borderBottom: "100px solid #f0ad4e",
      position:"absolute",
      backgroundColor: "#666666",
      transform: `rotateZ( `+0+`deg )`,
      border: "1px none #000000",    
      zIndex:1,  

    },
    Image:{        
      
      width: "auto",
      height: "auto",
      position:"absolute",
      transform: `rotateZ( `+0+`deg )`,
      border: "1px none #000000",    
      zIndex:1,   
      
        
    },
    
    TextoEstatico:{       
      width: "200px",
      height: "50px", 
      fontSize: 20+'px',
      color:"#000000",
      position:"absolute",
      transform: `rotateZ( `+0+`deg )`,
      border: "1px none #000000",    
      zIndex:1,       
      fontFamily: "Open Sans",   
    },
    Text2:{       
      width: "auto",
      height: "auto",
      fontSize: 20+'px',
      color:"#000000",
      transform: `rotateZ( `+0+`deg )`,
      border: "1px none #000000",    
      zIndex:1,     
      fontFamily: "Open Sans",      
    },
    Recurso:{       
      width: "50px",
      height: "50px", 
      fontSize: 20+'px',
     
      position:"absolute",
      transform: `rotateZ( `+0+`deg )`,
      border: "1px none #000000",    
      zIndex:1,          
    },
    ExerciseQuestion:{
      width: "auto",
      height: "auto", 
      fontSize: 20+'px',
      
      transform: `rotateZ( `+0+`deg )`,
      border: "1px none #000000",    
      zIndex:1,   
    },
    ExerciseAnswer:{
      border: "2px dotted #091832",
      width: "100px",
      height: "50px",
      fontSize: 20+'px',
      position:"absolute",
      transform: `rotateZ( `+0+`deg )`,
      zIndex:1,    
    },
    Exercise:{
      width: "100px",
      height: "100px", 
      fontSize: 20+'px',
      position:"absolute",
      transform: `rotateZ( `+0+`deg )`,
      border: "1px none #000000",    
      zIndex:1,   
    },
    DndExercise:{
      width: "150px",
      height: "150px", 
      fontSize: 20+'px',
      position:"absolute",
      transform: `rotateZ( `+0+`deg )`,
      border: "1px none #000000",    
      zIndex:1,   
    },
    BasicStaff:{
      width: "50px",
      height: "50px", 
      fontSize: 20+'px',
      position:"absolute",
      transform: `rotateZ( `+0+`deg )`,
      border: "1px none #000000",    
      zIndex:1,   
    },
  };
  
  export const tipoElemento={
    Circulo:1,
    Cuadrado:2,
    Triangulo:7,
    TextoEstatico:3,
    Multimedia:4,
    Image:5,
    Exercise:6,
    EnrichedText:8,
  };

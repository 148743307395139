import { combineReducers } from 'redux';
import FichasReducer from './FichasReducer';
import FichasEditorElementos from './FichasEditorElementosReducer';
import EditorElementosReducer from './EditorElementosReducer';
import ElemSeleccionadoReducer from './ElemSeleccionadoReducer';
import RecursosReducer from './RecursosReducer';
import RecursosItemsReducer from './RecursosItemsReducer';
import EstaFichasReducer from './EstaFichasReducer';
import TemaryItemsReducer from './TemaryItemsReducer';
import IdiomReducer from './IdiomReducer';
import TokenLogingReducer from './TokenLogingReducer';
import FiguresInViewerReducer from './FiguresInViewerReducer';
import ExerciseResultsReducer from './ExerciseResultsReducer';
const storeNodes = {};


storeNodes["fichas"]=FichasReducer;
storeNodes["estaFicha"]=EstaFichasReducer;
storeNodes["fichaselementos"]=FichasEditorElementos;
storeNodes["elementosEditor"]=EditorElementosReducer;
storeNodes["idElemSeleccionado"]=ElemSeleccionadoReducer;
storeNodes["recursos"]=RecursosReducer;
storeNodes["recursosItems"]=RecursosItemsReducer;
storeNodes["temary"]=TemaryItemsReducer;
storeNodes["idiom"]=IdiomReducer;
storeNodes["tokenLoging"]=TokenLogingReducer;
storeNodes["figuresInViewer"]=FiguresInViewerReducer;
storeNodes["exerciseResults"]=ExerciseResultsReducer;
export default combineReducers(storeNodes);
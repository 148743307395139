import React from 'react';

import './At_SoftText.scss';

const At_SoftText =(props)=>{
    let {
        children
    }=props

    return (
        <label className="at_softText"> 
            {children}
        </label>
    )
}
    

export default At_SoftText;
import React from 'react';

import {idiomas} from '../../../../../Constantes/idiomas';
import {typeQuestionOfIT} from '../../../../../Constantes/ExercisesVars';
import './QuestionIT.scss';

const QuestionTS=(props)=>{
    let {
        exercise,
        language,
        viewer,
        styleElement
    }=props;
    
    let questionByLanguage;
  
    questionByLanguage=exercise.questionData.filter((elem)=>{
        return elem.language==language
    });

    if(questionByLanguage[0]===undefined){
        questionByLanguage=exercise.questionData.filter((elem)=>{
            return elem.language==idiomas.castellano;
        });
    }
    questionByLanguage=questionByLanguage[0];

    if(exercise.typeQuestion==typeQuestionOfIT.image){
        return(
            <img 
                width={styleElement.width} 
                height={styleElement.height}
                className="p-question" 
                alt="Imagen no disponible"
                src={questionByLanguage.question} />
        )
    }

    if(exercise.typeQuestion==typeQuestionOfIT.text){
        return(
            <div className="p-question" style={exercise.fontStyle} dangerouslySetInnerHTML={{__html: questionByLanguage.question}}/>
    
        )
    }
    
    
}

export default QuestionTS;
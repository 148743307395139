import React, {useState, forwardRef, useImperativeHandle } from 'react';

const DT_Select =forwardRef(({datos, indice, onChange}, ref)=>{
    let seleccionables;
    const [value, setValue] = useState(datos.texto);

    if(datos.seleccionables!=null){
        seleccionables= datos.seleccionables;
    }else{
        seleccionables=[];
    }

    useImperativeHandle(ref, () => ({

        newChanges(valor) {
            setValue(valor);
        }
    
    }));

    const handlerOnChange = (event) => {
        setValue(event.target.value);
        onChange(event.target.value);
    }

    return(
        
        <td className="table-body__td">
            <select onChange={handlerOnChange} value={value}>
                { seleccionables.map((elem,i)=>{
                    return <option key={`${indice}-${elem.valor}`} value={elem.valor} >{elem.nombre}</option>
                })}
            </select>
        </td>
        
    );
});

export default DT_Select;
import React,{useState,useEffect} from 'react';

import AtEnrichedText,{toolbarTypes} from '../../Atoms/At_EnrichedText/At_EnrichedText';
import AtSelect from '../../Atoms/At_Select/At_Select';
import MolTextAndInputText from '../Mol_TextAndInputText/Mol_TextAndInputText';

import {quitarPxFinal} from '../../../Services/CSSTratamiento';
import {textFonts} from '../../../Constantes/TextFonts';

import './Mol_EnrichedTextFontOptions.scss';


const Mol_EnrichedTextFontOptions=(props)=>{
    let {
        text,
        onchangeText,
        fontStyle,
        onChangeFontStyle
    }=props;

    
    
    let thisFontFamily;
    let thisFontSize;
    let thisFontColor;

    thisFontFamily=fontStyle.fontFamily;
    thisFontSize=quitarPxFinal(fontStyle.fontSize);
    thisFontColor=fontStyle.color;
    

    const onChangeFontFamily=(newValue)=>{
        onChangeFontStyle({
            fontFamily: newValue,
            fontSize:thisFontSize+"px",
            color:thisFontColor,
        });
    }

    const onChangeFontSize=(newValue)=>{
        onChangeFontStyle({
            fontFamily: thisFontFamily,
            fontSize:newValue+"px",
            color:thisFontColor,
        });
    }

    const onChangeFontColor=(newValue)=>{
        onChangeFontStyle({
            fontFamily: thisFontFamily,
            fontSize:thisFontSize+"px",
            color:newValue,
        });
    }

    let style={
        fontFamily: thisFontFamily,
        fontSize:thisFontSize+"px",
        color:thisFontColor,
    }
   
    return(
        <div >
            <div className="content-font-options">
                <div className="content-font-options__item">
                    <AtSelect defaultValue={fontStyle.fontFamily} fontSize={11} fontColor={"#2E4467"} widthSelect={184} values={textFonts} onChange={event=>onChangeFontFamily(event.target.value)}>Fuente</AtSelect>
                </div>
                <div className="content-font-options__item">
                    <MolTextAndInputText fontSize={11} widthInput={68} type="number" value={thisFontSize} onChange={event=>onChangeFontSize(event.target.value)}  >Tamaño:</MolTextAndInputText>
                </div>
                <div className="content-font-options__item">
                    <MolTextAndInputText fontSize={11} widthInput={40} type="color" value={thisFontColor} onChange={event=>onChangeFontColor(event.target.value)}>Color:</MolTextAndInputText>
                </div>
            </div>
            <div style={{...fontStyle,fontSize:"20px"}}>
                <AtEnrichedText
                    toolbar={toolbarTypes.boldItalic}
                    value={text}
                    onChange={onchangeText}
                ></AtEnrichedText>
            </div>
            
        </div>
    )
}

export default Mol_EnrichedTextFontOptions;
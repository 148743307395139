import React from 'react';

import InsertMultimediaIntoElement from '../../PanelEditor/InsertMultimediaIntoElement';
import {tipoRecurso} from '../../../../Constantes/TiposRecursos';
const ColoringSvgCreate =(props)=>{
    let {
        onClickElem,
        finished
    }=props
    
    return(
        <div>
            <InsertMultimediaIntoElement finished={finished}  filterBy={tipoRecurso.svg} onClick={onClickElem}></InsertMultimediaIntoElement>
        </div>
    )
}


export default ColoringSvgCreate;
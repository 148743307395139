import { types } from '../Reducers/ExerciseResultsReducer';


export const addExerciseResult = (exercise) => ({
  type: types.ADD_EXERCISE,
  exercise: exercise,
});

export const resetExercisesResult = () => ({
  type: types.RESET,
});




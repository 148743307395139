import React, { useState } from 'react';

const DT_Texto =(celda)=>{
    let texto;
    
    if (celda['datos']!=null) {
        texto=celda['datos'].texto;
    }else{
        texto="";
    }
        
    
    return(
        
        <td className="table-body__td">
            {texto}
        </td>
        
    );
};

export default DT_Texto;
import {idiomas} from '../Constantes/idiomas';
export const seleccionarIdiomaTextoEstatico=(idiomaFicha,arrayIdiomas)=>{
    let textoDevuelto;
    switch (idiomaFicha) {
        case idiomas.castellano:
            textoDevuelto=arrayIdiomas.castellano;
        break;

        case idiomas.valenciano:
            textoDevuelto=arrayIdiomas.valenciano;
        break;

        case idiomas.ingles:
            textoDevuelto=arrayIdiomas.ingles;
        break;
      
        default:
            textoDevuelto=arrayIdiomas.castellano;
          break;
      }

      return textoDevuelto;

};

export const selectEnrichedText=(idiomaFicha,arrayIdiomas)=>{
    let textoDevuelto;
    switch (idiomaFicha) {
        case idiomas.castellano:
            textoDevuelto=arrayIdiomas.castellano;
        break;

        case idiomas.valenciano:
            textoDevuelto=arrayIdiomas.valenciano;
        break;

        case idiomas.ingles:
            textoDevuelto=arrayIdiomas.ingles;
        break;
      
        default:
            textoDevuelto=arrayIdiomas.castellano;
          break;
      }

      return textoDevuelto;

};


export const editarJsonTextoEstatico=(idiomaFicha,jsonTexto,newTexto)=>{
    let newJsonTexto=jsonTexto;
    
    switch (idiomaFicha) {
        case idiomas.castellano:
            newJsonTexto.castellano=newTexto;
          break;

        case idiomas.valenciano:
            newJsonTexto.valenciano=newTexto;
          break;

        case idiomas.ingles:
            newJsonTexto.ingles=newTexto;
          break;
      
        default:
            newJsonTexto.castellano=newTexto;
          break;
      }

      return newJsonTexto;

};
import React,{useState,useEffect} from 'react';

import './NoLinksMultimedia.scss';

import {idioms} from '../../../Constantes/idiomas';
import {typeCss} from '../../../Constantes/TypeCss';
import {tipoRecurso} from '../../../Constantes/TiposRecursos';

import AtSelect from '../../Atoms/At_Select/At_Select';
import AtTitle from '../../Atoms/At_Title/At_Title';
import AtButton from '../../Atoms/At_Button/At_Button';


import {ImageIcon} from '../../Atoms/At_Image/At_Image';
const NoLinksMultimedia=(props)=>{
    let {
        onChangeLanguage,
        files,
        onClickDelete,
        typeMultimedia
    } =props

    const [thisImages,setThisImages]=useState([]);
    const [thisFiles,setThisFiles]=useState([]);
    useEffect(() => {
        
        files.map((elem,i=0)=>{
            const reader = new FileReader();  
            reader.readAsDataURL(elem.archivo.data);
     
            reader.onload=(e)=>{
                const result = reader.result;
                setThisImages([...thisImages,{id:i,src:e.target.result}]);
            }
        });

    }, [files]);

    useEffect(() => {
        
        let newfiles=files.map((elem,j)=>{
            j++;
            
            return {...elem,src:thisImages[j-1].src};
        });
        setThisFiles(newfiles);
        
    }, [thisImages])


    return (

        <div className="no-links-multimedia">
            
            <div className="no-links-multimedia__at-title">
                <AtTitle fontSize="13"  fonstColor="#2E4467" >Recursos subidos</AtTitle>
            </div>
            
            <div className="no-links-multimedia__items-container">
                {thisFiles.map((elem)=>{
                    
                    return <div className="no-links-multimedia__items">
                                {
                                tipoRecurso.imagen ===typeMultimedia ||
                                tipoRecurso.svg === typeMultimedia ?
                                    <img className="no-links-multimedia__items__img" src={elem.src}></img> :
                                    ""
                                }
                                

                                <label className="no-links-multimedia__item no-links-multimedia__item__label">{elem.nombre}</label>

                                <div className="no-links-multimedia__item no-links-multimedia__item__select">
                                    <AtSelect onChange={event=>onChangeLanguage(elem.nombre,event.target.value)} defaultValue={elem.language} values={idioms} widthSelect="195"></AtSelect>
                                </div>

                                <div className="no-links-multimedia__item">
                                    <AtButton widthImg="13" heightImg="16" image={ImageIcon.delete} type={typeCss.tertiary} 
                                    onClick={()=>{onClickDelete(elem.id)}}></AtButton>
                                </div>

                            </div>
                            
                })}
            </div>
        </div>
    )
}


export default NoLinksMultimedia;
import React, { useState } from 'react';

import At_BoldText from '../../Atoms/At_BoldText/At_BoldText';

const DTTextBold =(props)=>{
    const {
        datos
    }=props
    let texto;
    
    if (datos!=null) {
        texto=datos.texto.castellano;
        
    }else{
        texto="";
    }
        
    

    return(
        
        <td className="table-body__td dt-text-bold">
            <At_BoldText>
                        {texto}
            </At_BoldText>
        </td>
        
    );
};

export default DTTextBold;
import React from 'react';

import './Mol_EditorElementIcon.scss';

import At_SoftText from './../../Atoms/At_SoftText/At_SoftText';
import At_Image from './../../Atoms/At_Image/At_Image';
const Mol_EditorElementIcon=(props)=>{
    let {
        onClick,
        text,
        image,
        widgth,
        height
    }=props

    return(
        <div className="mol-editore-lement-icon" onClick={onClick}>

            <div className="mol-editore-lement-icon__at-image">
                <At_Image image={image} widgth={widgth} height={height}></At_Image>
            </div>

            <div className="mol-editore-lement-icon__at-softText">
                <At_SoftText>{text}</At_SoftText>
            </div>

        </div>
    )
}

export default Mol_EditorElementIcon;
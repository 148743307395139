import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';

import Paginador from './Paginador';
import CuerpoTabla from './CuerpoTabla';
import CabezaTabla from './CabezaTabla';

import './Table.scss';

const Tabla = forwardRef((props, ref)=>{
    let {paginacion,datos,estructuraColumnas,onChange, updateMultimediaInTable}=props;
    const refCuerpoDeTable = useRef();
    useImperativeHandle(ref, () => ({

        newChanges(fila, idFicha, atributo, valor) {
            refCuerpoDeTable.current.newChanges(fila, idFicha, atributo, valor);
        }
    
      }));

    let pintamosCuerpo=false;
     if(datos!=undefined){
        pintamosCuerpo=true;
    }
    
    return(
        <div>
            <table className="table">
                <thead className="table__thead" >
                    <CabezaTabla estructuraColumnas={estructuraColumnas}></CabezaTabla>
                </thead>

                <tbody className="table__tbody">
                    {pintamosCuerpo ? 
                    <CuerpoTabla 
                        ref={refCuerpoDeTable}
                        datos={datos} 
                        estructuraColumnas={estructuraColumnas} 
                        onChange={(fila, idFile,atributo,valor) => {onChange(fila, idFile,atributo,valor)}} 
                        updateMultimediaInTable={updateMultimediaInTable}
                    />:null}
                </tbody>

            </table>
                <Paginador paginacion={paginacion}></Paginador>
        </div>
    );
});

export default Tabla;
import React from 'react';

import AtInputText from '../../Atoms/At_InputText/At_InputText';
import AtSelect from '../../Atoms/At_Select/At_Select';
import AtButton from '../../Atoms/At_Button/At_Button';

import "./EditLinksMultimedia.scss";

import {idioms} from '../../../Constantes/idiomas';
import {typeCss} from '../../../Constantes/TypeCss';

import {ImageIcon} from '../../Atoms/At_Image/At_Image';

const LinksMultimedia=(props)=>{
    let {
        onChangeLink,
        onChangeLanguage,
        onClickDelete,
        valueLink
    }=props

    

    return (
        <div className="links-multimedia">
            <div className="links-multimedia__items">
                <AtInputText  placeholder="Link" onChange={onChangeLink} value={valueLink} width="442" type="text"></AtInputText>
            </div>

            <div className="links-multimedia__items">
                <AtSelect onChange={onChangeLanguage}  values={idioms} widthSelect="195"></AtSelect>
            </div>

            <div className="links-multimedia__items">
                <AtButton image={ImageIcon.delete} widthImg="13px" heightImg="16px" onClick={onClickDelete} type={typeCss.tertiary}></AtButton>
            </div>
        </div>
    )
}


export default  LinksMultimedia;
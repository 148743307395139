import { types } from '../Reducers/TemaryItemsReducer';




export const reloadTemary = (items) => ({
  type: types.RELOAD,
  payload: items,
});


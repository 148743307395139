import React, {useEffect, useRef} from 'react';
import { createPortal } from 'react-dom';
import {useDispatch} from 'react-redux';
import {addExerciseResult} from '../../../../Store/Actions/ExerciseResultsActions';

import {Droppable, Draggable } from 'react-beautiful-dnd';
import {getTranslatetoTransform} from '../../../../Services/CSSTratamiento';
import {exercises} from '../../../../Constantes/Exercises';
import '../ElementoBase.scss';

const useDraggableInPortal = (scaleFile) => {
    

    return (render) => (provided, ...args) => {
        let newProvider
        if(provided.draggableProps.style.transform!==null){
            let newTranslate=getTranslatetoTransform(provided.draggableProps.style.transform);
            newTranslate=[newTranslate[0]/scaleFile,newTranslate[1]/scaleFile]

            newProvider={
                ...provided,
                draggableProps:{
                    
                    ...provided.draggableProps,
                    style:{
                        ...provided.draggableProps.style,
                        position:'absolute',
                        top:"0px",
                        left:"0px",
                        transform: "translate(" +newTranslate[0] +"px," +newTranslate[1]+ "px ) scale(1.5)"
                    }
                }
            }
        }else{
            newProvider=provided;
        }

        const element = render(newProvider, ...args);
        return element;
    };
};

const DraggableElement=(props)=>{
    let {
        property,
        paint,
        ID,
        onclickgeneral,
        styleZIndex,
        styleAnimation,
        onClickAnimation,
        onMouseEnter,
        onMouseLeave,
        estiloHijo,
        position,
        children,
        scaleFile,
        correctExercises
    }=props;

    const dispatch=useDispatch();

    const renderDraggable = useDraggableInPortal(scaleFile);
   
    
    let positionStyle={
        position:"absolute",
        left:position.x+"px",
        top:position.y+"px",
        
        
    }

    let sizeStyle={
        width:estiloHijo.width,
        height:estiloHijo.height
    }

    useEffect(()=>{
        if(correctExercises==true){
            let replyToSend={dndId:ID,isCorrect:paint[0].items.length==0,typeExercise:exercises.dragAndDrop};
            //dispatch(addExerciseResult(replyToSend));
        }
    }, [correctExercises]);

    const getImageComponent=(item)=>{
        return <div style={paint[0].items[0].content} >
                     <img
                     
                     height={item.content.height}
                     width={item.content.width}
                     src={item.itemsMultimedia[0].url}
                     alt="img no disponible"
                     />    

                </div>
    }

    return(
        
            <div style={{...positionStyle,...sizeStyle}} className="exercise-draggable">
                  <Droppable droppableId={property.idDroppable.toString()}>
                    {(provided,snapshot) => (

                        <div style={sizeStyle} 
                            
                            ref={provided.innerRef}
                            >
                            {(paint[0]!==undefined && paint[0].items[0]!==undefined) ? 
                              <Draggable
                              
                              key={property.idDroppable.toString()}
                              draggableId={property.idDroppable.toString()}
                              index={0}>
                              {renderDraggable((provided) => (
                                  <div className="draggable-element__size"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      
                                      >
                                      <div id={paint[0].items[0].id}   className="divPadre" onClick={onclickgeneral}  style={styleZIndex}>
                                        <div style={styleAnimation} 
                                          onClick={onClickAnimation} 
                                          onMouseEnter={onMouseEnter} 
                                          onMouseLeave={onMouseLeave}>
                                          <div id="contenido" style={paint[0].items[0].content} >
                                            {
                                                paint[0].items[0].itemsMultimedia!==undefined ? 
                                                getImageComponent(paint[0].items[0]) 
                                                :
                                                <div style={paint[0].items[0].content} >
                                                    
                                                </div>
                                                }
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                              ))}
                              </Draggable> 
                            : ""}
                                
                            
                            {provided.placeholder}
                        </div>
                    )}
            
                </Droppable>
            </div>
            
    )
};

export default DraggableElement;
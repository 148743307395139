import React, { useState } from 'react';
import Popup from "reactjs-popup";

import AtButton from '../../Atoms/At_Button/At_Button';
import NewFile from '../../FichasControlador/NuevaFicha';
import AtPopup from '../../Atoms/At_Popup/At_Popup';

const DTPopupEditFile =(props)=>{
     let {datos}=props;
   
    return(
        
        <td className="table-body__td">
            <AtPopup open={datos.openPupup} className={datos.className} trigger={<AtButton onClick={datos.setOpenPupup}>Editar</AtButton>} >
               <NewFile closePopup={datos.onClickClosePopup} editFile file={datos.file}></NewFile>
            </AtPopup>
        </td>
        
    );
};

export default DTPopupEditFile;
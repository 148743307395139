import React from 'react'

import AtTitle from '../../Atoms/At_Title/At_Title';

import "./Mol_AnimationSelected";

const Mol_AnimationSelected=(props)=>{

    let {
        element
    }=props
    let animation,eventAnimation;
    if(element[0]!==undefined && element[0].caracteristicas!==undefined && element[0].caracteristicas.animation!==undefined){
        
        animation=element[0].caracteristicas.animation.animation;
        eventAnimation=element[0].caracteristicas.animation.event;
    }
    

    return(
        <div className="mol-animation-selected">
            <div>
                <AtTitle className="mol-animation-selected__animation" fontSize={11}>Animacion: {animation}</AtTitle> 
            </div>
            <div>
                <AtTitle className="mol-animation-selected__event" fontSize={11}>Evento de animacion: {eventAnimation}</AtTitle>
            </div>
        </div>
    )
}

export default Mol_AnimationSelected;
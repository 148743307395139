import React,{useState,useEffect} from 'react';
import {useDispatch} from 'react-redux';

import AtInputText from '../../../Atoms/At_InputText/At_InputText';
import AtButton from '../../../Atoms/At_Button/At_Button';
import AtTextArea from '../../../Atoms/At_TextArea/At_TextArea';
import AtTitle from '../../../Atoms/At_Title/At_Title';
import AtSelect from '../../../Atoms/At_Select/At_Select';
import AtEnrichedText,{toolbarTypes} from '../../../Atoms/At_EnrichedText/At_EnrichedText';
import EnrichedTextFillableField from './EnrichedTextFillableField';
import MolEnrichedTextFontOptions from '../../../Molecules/Mol_EnrichedTextFontOptions/Mol_EnrichedTextFontOptions';


import {PositionAnswerSelect,imagePrevAnswerSelect} from '../../../../Constantes/ExercisesVars';
import {editExerciseFFOrTest} from '../../../../Store/Actions/EditorElementosActions';

import {FillableFieldType} from '../../../../Constantes/ExercisesVars';
import {idiomas,idioms} from '../../../../Constantes/idiomas';

import "./FillableFieldCreate.scss";


let allIdiomsTexts;

const FillableFieldCreate=(props)=>{
    let {
        element,
        exercise,
    }=props;
    
    let [thisLanguage,setThisLanguage]=useState(idiomas.castellano);
    const [fontStyle,setFontStyle]=useState();
    let dispatch=useDispatch();
    const [textsEditing,setTextsEditing]=useState();
    const [positionAnswer,setPositionAnswer]=useState();
    const [iconPrevAnswer,setIconPrevAnswer]=useState();
    
    useEffect(() => {

            setFontStyle(exercise.fontStyle);
            allIdiomsTexts=exercise.questionData;
           
            setTextsEditing(exercise.questionData[0]);
            setPositionAnswer(exercise.positionAnswer);
            setIconPrevAnswer(exercise.iconPrevAnswer);
    }, []);
    

    
    

    const onChangeSetQuestion=(text)=>{
        setTextsEditing({
            ...textsEditing,
            question:text
        });
    }

    const onChangeSetAnswer=(text)=>{
        setTextsEditing({
            ...textsEditing,
            answer:text
        });
    }

    const saveText=()=>{

        let newAllIdiomsTexts=allIdiomsTexts.map((elem)=>{
            
            if(elem.language===textsEditing.language){
                return {...textsEditing}
            }else{
                return elem
            }


        });
        
        allIdiomsTexts=newAllIdiomsTexts;
        
    }

    const onChangeChangeIdiom=(newIdiom)=>{
        
       
        saveText();
        

        let newTextEditing=allIdiomsTexts.filter((elem)=>{
            return elem.language===newIdiom
        });

        
        newTextEditing=newTextEditing[0];
        
        
        if(newTextEditing===undefined){
            
            newTextEditing={language:newIdiom,question:"pregunta",answer:"newAnswer"};

            allIdiomsTexts=[...allIdiomsTexts,newTextEditing];

        }else{
            
           newTextEditing=allIdiomsTexts.find(elem=>elem.language===newIdiom);

        }
        setThisLanguage(newIdiom);
        
        setTextsEditing(newTextEditing);
        

        

    }


    const onClickSaveExercise=()=>{
        
        saveText();
        
        let newExercise={
            ...exercise,
            questionData:allIdiomsTexts,
            fontStyle:fontStyle,
            positionAnswer:positionAnswer,
            iconPrevAnswer:iconPrevAnswer
        }
     
        dispatch(editExerciseFFOrTest(element.ID,element.caracteristicas.exercise.idLinkExercise,newExercise));
    }

    const onChangePositionAnswer=(newPosition)=>{
        setPositionAnswer(newPosition);
    }

    const onChangeImgPrevAnswer=(newIcon)=>{
        setIconPrevAnswer(newIcon);
    }

    
    return(
        
        <div>
            {(textsEditing!==undefined) ? 
            <>
            
            <div className="button-top-content">
                <div className="button-top-content__idiom-select">
                    <AtSelect values={idioms} onChange={event=>onChangeChangeIdiom(event.target.value)}></AtSelect>
                </div>
                
            </div>
            
            <div className="fillable-field-create-content">
                <div className="content-item">
                    <div>
                        <div>
                            <AtSelect 
                                defaultValue={exercise.positionAnswer} 
                                values={PositionAnswerSelect} 
                                onChange={event=>onChangePositionAnswer(event.target.value)}
                                >Posicion respuesta</AtSelect>
                        </div>
                        <div>
                        <AtSelect 
                                defaultValue={exercise.iconPrevAnswer} 
                                values={imagePrevAnswerSelect} 
                                onChange={event=>onChangeImgPrevAnswer(event.target.value)}
                            >Icono respuesta</AtSelect>
                        </div>
                    </div>
                    <MolEnrichedTextFontOptions
                        key={"questionff-"+thisLanguage}
                        toolbar={toolbarTypes.boldItalic}
                        text={textsEditing.question}
                        onchangeText={onChangeSetQuestion}
                        fontStyle={fontStyle}
                        onChangeFontStyle={setFontStyle}
                    ></MolEnrichedTextFontOptions>
                </div>
                <div key={"answer-"+thisLanguage} style={{...fontStyle,fontSize:"20px"}} className="content-item">
                    <AtEnrichedText 
                        className="fillable-field-create-content__static-text"
                        onChange={onChangeSetAnswer} 
                        key={"EX-FF-ANSWER"} 
                        value={textsEditing.answer}
                        toolbar={toolbarTypes.none}
                        >
                    </AtEnrichedText>
                </div>          

                        


                 
            </div>
            <div className="button-bottom-content">
                <AtButton className="button-bottom-content__save" onClick={onClickSaveExercise}>Guardar</AtButton>
            </div>
            </> 
            : 
            ""}
            
        </div>
    );

}


export default FillableFieldCreate;
import React from 'react';

import {tipoRecurso} from '../../../Constantes/TiposRecursos';
import ImageMultimedia from './ElementsMultimedia/ImageMultimedia';
import SpotifyMultimedia from './ElementsMultimedia/SpotifyMultimedia';
import YoutubeMultimedia from './ElementsMultimedia/YoutubeMultimedia';
import PdfMultimedia from './ElementsMultimedia/PdfMultimedia';
import Vimeo from './ElementsMultimedia/VimeoMultimedia';
import Audio from './ElementsMultimedia/AudioMultimedia';
import Bandcamp from './ElementsMultimedia/BandcampMultimedia';


import AtPopup from '../../Atoms/At_Popup/At_Popup';


const ElementMultimedia=(props)=>{
    let {
        language, 
        multimedia,
        textStatic,
        openDelay
    }=props

    let style={width:100+"%",height:100+"%"}

    let thisComponent=<div style={style}>{textStatic}</div>;

    const tipo = multimedia.tipo === undefined ? multimedia[0].tipo : multimedia.tipo;
    
    switch (tipo) {
        case tipoRecurso.imagen:

            return <AtPopup trigger={thisComponent} openDelay={openDelay} transparent>
                        <ImageMultimedia language={language} multimedia={multimedia}></ImageMultimedia>
                    </AtPopup>
                    
        case tipoRecurso.svg:

            return <AtPopup trigger={thisComponent} openDelay={openDelay} transparent contentFit>
                        <ImageMultimedia language={language} multimedia={multimedia}></ImageMultimedia>
                    </AtPopup>

        case tipoRecurso.audios:
            return <Audio textStatic={textStatic} thisComponent={thisComponent} language={language} openDelay={openDelay} multimedia={multimedia}></Audio>
                    
            

        case tipoRecurso.spotify:
            return  <AtPopup trigger={thisComponent} openDelay={openDelay} transparent activeMinimize>
                        <SpotifyMultimedia language={language} multimedia={multimedia}></SpotifyMultimedia>
                    </AtPopup>
            

        case tipoRecurso.youtube:
            return  <AtPopup trigger={thisComponent} openDelay={openDelay} transparent activeMinimize>
                        <YoutubeMultimedia language={language} multimedia={multimedia}></YoutubeMultimedia>
                    </AtPopup>

        case tipoRecurso.pdf:
    
            return <AtPopup trigger={thisComponent} openDelay={openDelay}>
                        <PdfMultimedia language={language} multimedia={multimedia} textStatic={textStatic}></PdfMultimedia>
                    </AtPopup>
                                        
            
            
        case tipoRecurso.ivoox:
            
            break;

        case tipoRecurso.vimeo:
            return  <AtPopup trigger={thisComponent} openDelay={openDelay} transparent activeMinimize>
                        <Vimeo language={language} multimedia={multimedia}></Vimeo>
                    </AtPopup>

        case tipoRecurso.bandcamp:
            return <AtPopup trigger={thisComponent} openDelay={openDelay} transparent activeMinimize>
                        <Bandcamp language={language} multimedia={multimedia}></Bandcamp>
                    </AtPopup>
                        

        default:
            return <div>Error: tipo elemento no encontrado</div>
            
    }
    

}


export default ElementMultimedia;
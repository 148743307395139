export const types = {
    ADD_EXERCISE: 'ADD_EXERCISE',
    RESET: 'RESET',
    
  };

  const ExerciseResultsReducer = (state = [], action) => {
    
    switch (action.type) {
      case types.ADD_EXERCISE:
        
        return [...state,action.exercise];

      case types.RESET:
        
        return [];
      
      default:
        return state;
    }
  };

  export default ExerciseResultsReducer;
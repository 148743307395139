import React,{useEffect,useState, useLayoutEffect}from 'react';
import {useDispatch,useSelector,shallowEqual} from 'react-redux';
import { useParams } from "react-router-dom";
import {useLocation} from "react-router-dom";
import screenfull from "screenfull";

import AtCanvas from '../Atoms/At_Canvas/At_Canvas';
import ExercisesCorrectController from './ExercisesCorrectController';

import {regargarElementosDelServidor} from '../ComponentesEditor/EditorControlador/EditorConexionesServidor';
import {reloadFichasElementos} from '../../Store/Actions/FichasEditorElementosActions';
import {httpGet} from '../../Store/HttpCalls';
import {getEstaFicha} from '../../Store/Actions/EstaFichasActions';
import {getExercises,getStaticsFiles} from './SendExercisesToBbdd';
import {splitUrlSearch} from '../../Services/OperacionesStrings';

import {ruta} from '../../Constantes/Rutas';
import { idiomas } from '../../Constantes/idiomas.js';
import Painter from '../Organisms/Org_Painter/painter';
import AtButton from '../Atoms/At_Button/At_Button';
import {typeCss} from '../../Constantes/TypeCss';
import IconFullScreamOn from '../../Images/BotonAbrirPincel.png';
import IconFullScreamOff from '../../Images/BotonCerrarPincel.png';
import AtPopup from '../Atoms/At_Popup/At_Popup';
import BasicStaffViewerPanel from '../../Componentes/ComponentesEditor/Exercises/BasicStaff/BasicStaffViewerPanel';

import BackgroundMenuViewer from '../../Images/BackgroundMenuViewer.png';
import Check from '../../Images/icono-corregir.png';
import FullScreen from '../../Images/icono-pantalla-completa.png';
import GoOut from '../../Images/icono-salir.png';
import Paint from '../../Images/Paint.png';
import iconoBocadillo from '../../Images/icono_bocadillo.png';
import NotPaint from '../../Images/NotPaint.png';
import IconBasicStaff from '../../Images/IconoPentagrama.png';
import OpenFullScreen from '../../Images/icono-abrir.png';
import OpenFullScreenVA from '../../Images/icono-obrir.png';

import {
  isMobile,
  isTablet,
  isAndroid,
  isWinPhone,
  isIOS,
  ConsoleView
} from "react-device-detect";


import './ViewerController.scss';
import Mol_Comments from '../Molecules/Mol_comments/Mol_Comments.jsx';

let elementosEditor;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const Viewer =()=>{
    
    let url=useLocation();
    let urlVars=splitUrlSearch(url.search);

    const {ficha_id}=useParams();
    const idStudent=0;
    const [windowWidht, windowHeight] = useWindowSize();
    document.addEventListener('contextmenu', event => event.preventDefault());


    const [estiloMarco,setEstiloMarco]=useState();
    const [estiloFicha,setEstiloFicha]=useState();
    const [dndExercise,setDndExercise]=useState();
    const [toggleComments,setToggleComments] = useState(false);
    const [toggleCommentsStudent,setToggleCommentsStudent] = useState(true);
    const [dndExerciseFromBackend,setDndExerciseFromBackend]=useState();
    const [scaleFile,setScaleFile]=useState();
    const [figureSize,setFigureSize]=useState();

    const [answerExercises,setAnswerExercises]=useState();
    const [staticsFiles,setStaticsFiles]=useState();

    const [initialTime,setInitialTime]=useState(Date.now());

    const [idiom,setIdiom]=useState();
    const [alumnoId,setAlumnoId]=useState();
    const [profesorId,setProfesorId]=useState();

    let useTime;
    useEffect(() => {
      if(alumnoId!==undefined){
        getExercises(alumnoId,ficha_id,setAnswerExercises);
        getStaticsFiles(alumnoId,ficha_id,setStaticsFiles,setDndExerciseFromBackend);
      }
    }, [alumnoId]);

    

    
    
    const dispatch=useDispatch();

    const fichasElementos=useSelector((state)=>{
        return state.fichaselementos;
      },shallowEqual);

    

    elementosEditor=useSelector((state)=>{
    return state.elementosEditor;
    },shallowEqual);

    const eventRecargaServidor=()=>{

        regargarElementosDelServidor(fichasElementos,dispatch);

    };
    const [fullScream,setFullScream]=useState(true);
    const [cancelPopupFullScream,setCancelPopupFullScream]=useState(true);

    const onClickFullScream=()=>{
      
      if(fullScream){
        
        if (screenfull.isEnabled) {
          screenfull.exit();
        }
        setFullScream(false);
      }else{
        
        if (screenfull.isEnabled) {
          screenfull.request(document.getElementById("root"));
        }
        setFullScream(true);
      }
      
    }
    

    useEffect(()=>{
        eventRecargaServidor();
      },[fichasElementos]);
    //IDIOMA------------------------------------------
   
    

    useEffect(() => {
      

      httpGet(ruta.editor+ficha_id).then((fichaselementos)=>{
         
          dispatch(reloadFichasElementos(fichaselementos));
          
      },(error)=>{
          console.error("nuestro error " + error)
      });

      let newidiom=urlVars.filter((elem)=>{
       
        return elem[0]==="idioma";
      });
      
      setIdiom(newidiom[0][1]);

      let newalumnoId=urlVars.filter((elem)=>{
      
        return elem[0]==="alumnoId";
      });
      
      if(newalumnoId[0]!==undefined){
        setAlumnoId(newalumnoId[0][1]);
      }

      let newprofesorId=urlVars.filter((elem)=>{
   
        return elem[0]==="profesorId";
      });
      
      if(newprofesorId[0]!==undefined){
        setProfesorId(newprofesorId[0][1]);
      }
      
      
      

    }, []);
    
  
    const [idiomaFicha,setIdiomaFicha]=useState(idiom);
    //DATOS DE LA FICHA---------------------------------------------------

  let urlPathname=url.pathname.split("/");
  
  const recursosItems=useSelector((state)=>{
    
    return state.recursosItems;
  },shallowEqual);

  const estaFicha=useSelector((state)=>{
          
    return state.estaFicha;
  },shallowEqual);

  const getScaleFile=(estiloInicial)=>{
    const fichaWidth = estiloInicial ? parseInt(estiloInicial.width) : parseInt(estiloFicha.width);
    const fichaHeight = estiloInicial ? parseInt(estiloInicial.height) : parseInt(estiloFicha.height);
    const scale = Math.min(
      windowWidht / fichaWidth,    
      windowHeight / fichaHeight
    );

    return scale
  }

  const resizeFicha = (estiloInicial) => {
    
    const scale = getScaleFile(estiloInicial);
    const newEstilo = estiloInicial ?  estiloInicial : {...estiloFicha};
    newEstilo.transform = `translate(-50%, -50%) scale(${scale})`;

    setEstiloFicha(newEstilo);
  }

  useEffect(()=>{

    httpGet(ruta.getFicha+urlPathname[urlPathname.length-1]).then((thisFicha)=>{
        
        dispatch(getEstaFicha(thisFicha));

    },(error)=>{
        console.error("nuestro error " + error)
    })

  },[shallowEqual]);
  
 
  //let newEstiloMarco,newEstiloFicha;

  
  useEffect(() => {
    
    if(estaFicha[0]!==undefined){

      let newEstiloFicha={
        position: 'relative',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        transformOrigin: "center center",
        width: estaFicha[0].caracteristicas.lienzo[0] +"px",
        height: estaFicha[0].caracteristicas.lienzo[1] +"px",
      };



      
      //FONDO FICHA
      if(estaFicha[0].caracteristicas.fondoFicha[0]=="#"){
        newEstiloFicha={...newEstiloFicha,backgroundColor:estaFicha[0].caracteristicas.fondoFicha};
        }else{

          let recursoFondo=estaFicha[0].caracteristicas.fondoFicha.items.filter((elem)=>{
              return elem.language==idiom;
          });

         
          
          if(recursoFondo[0]!=null){
            newEstiloFicha={...newEstiloFicha,backgroundImage:"url("+recursoFondo[0].url+")"};
            
          }else{
            
            let altFondo=estaFicha[0].caracteristicas.fondoFicha.items.filter((elem)=>{
              return elem.language==idiomas.castellano;
            });

            if(altFondo[0]!==undefined){
              newEstiloFicha={...newEstiloFicha,backgroundImage:"url("+altFondo[0].url+")"};
            }else{
              newEstiloFicha={...newEstiloFicha,backgroundImage:"#ffffff"};
            }

          }
        }


        //MARCO FICHA
        let heightWindows=window.innerHeight;
        let newEstiloMarco={
                        height:(heightWindows-55) + "px"
                      };
        

        if(estaFicha[0].caracteristicas.marcoFicha[0]==="#"){
        newEstiloMarco={...newEstiloMarco,backgroundColor:estaFicha[0].caracteristicas.marcoFicha};
          
        }else{
          let recursoMarco=estaFicha[0].caracteristicas.marcoFicha.items.filter((elem)=>{
              return elem.language==idiom;
          });
          
          if(recursoMarco[0]!=null){
            newEstiloMarco={...newEstiloMarco,backgroundImage:"url("+recursoMarco[0].url+")"};
          }else{
              
            let altFondo=estaFicha[0].caracteristicas.marcoFicha.items.filter((elem)=>{
              return elem.language==idiomas.castellano;
            });
            
            if(altFondo[0]!==undefined){
              newEstiloMarco={...newEstiloMarco,backgroundImage:"url("+altFondo[0].url+")"};
            }else{
              newEstiloMarco={...newEstiloMarco,backgroundImage:"#F8F8F8"};
            }

          }

        }
        
      
      setScaleFile(getScaleFile(newEstiloFicha));
      setEstiloMarco(newEstiloMarco);
      setFigureSize(estaFicha[0].caracteristicas.figureSize)
      setDndExercise(estaFicha[0].caracteristicas.dataExercise);
      resizeFicha(newEstiloFicha);
    }
  }, [estaFicha]);

  useEffect(() => {
   // if( !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ){
      if(estiloFicha){
        resizeFicha();
        setScaleFile(getScaleFile(estiloFicha));
      } 
    //}
    
  }, [windowWidht, windowHeight]);
  useEffect(() => {
    // if( !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ){
       if(estiloFicha){
         resizeFicha();
         setScaleFile(getScaleFile(estiloFicha));
       } 
     //}
     
   }, [fullScream]);
    
    const [primerCanvas, setPrimerCanvas] = useState(true);
    const [enable, setEnable] = useState(false);
    const [figureMenuEnable,setFigureMenuEnable]=useState(false);

    const switchPainter = () => {
        setEnable(!enable);
        if(primerCanvas){
            setPrimerCanvas(false);
        }
    }; 

    const switchFigureMenu=()=>{
      setFigureMenuEnable(!figureMenuEnable);
    }
    const onClickCancelPopupFullScream=()=>{
      setCancelPopupFullScream(!cancelPopupFullScream)

    }
    const [correctExercises,isCorrectExercises]=useState(false);
    const onClickCheckAnswers=()=>{
      useTime=Date.now()-initialTime;
      let newNumberTries;
      if(staticsFiles!==undefined && staticsFiles.numberTries!==undefined){
        newNumberTries= staticsFiles.numberTries+1
      }else{
        newNumberTries=1;
      }

      let newStaticsFile={
        idFile:ficha_id,
        idStudent:alumnoId,
        numberTries:newNumberTries,
        workingTime:(staticsFiles!==undefined && staticsFiles.workingTime!==undefined) ? staticsFiles.workingTime+useTime : useTime,
        dndExercise:(dndExerciseFromBackend!==undefined) ? dndExerciseFromBackend : dndExercise,
      };
      
      setStaticsFiles(newStaticsFile);
      setInitialTime(Date.now());
      isCorrectExercises(true);
    }


    //Gestión de los comentarios
    const onClickOpenComments = () => setToggleComments(!toggleComments);
    const onClickOpenCommentsStudent = () => setToggleCommentsStudent(!toggleCommentsStudent);
    
    const [hasComments, setHasComments] = useState(false);
    const [messageList, setMessageList] = useState([]);

    useEffect(() => {
        httpGet(ruta.getComentariosFichasAlumno + alumnoId + "/" + ficha_id).then((values) => { 
            values.length > 0 ? setHasComments(!hasComments) : setHasComments(hasComments);
            setMessageList(values.map(value => {
                return value.comentario;
            })
        )
        });

     }, [alumnoId]);

   
    return(
        <div id="viewer-id-file"  className="viewerController">
            {fullScream==false && cancelPopupFullScream==true ? 
            <div className="viewer-controller_popup-fullscream">
              <div className="viewer-controller_popup-fullscream-body">
                {idiom == "valenciano" ?
                  <img src={OpenFullScreenVA} alt={idiom}/>
                :
                  <img src={OpenFullScreen} alt={idiom}/>
                }
                  
                  <button className="viewer-controller_popup-fullscream-body__btns__btn-full-scream" onClick={onClickFullScream}>
                    <img width="50px" height="50px" alt="FullScreen" src={FullScreen}></img>
                  </button>
              </div>
            </div>
            : ""

            }
          
          {(estiloFicha!==undefined && elementosEditor!==undefined && dndExercise!==undefined) ? 
              <AtCanvas 
              style={estiloFicha} 
              editorElements={elementosEditor} 
              fileIdiom={idiom} 
              exerciseData={dndExerciseFromBackend!==undefined ? dndExerciseFromBackend : dndExercise}
              viewer={true}
              scaleCanvas={scaleFile}
              figureSize={figureSize}
              correctExercises={correctExercises}
              answerExercises={alumnoId!==undefined ?  answerExercises : undefined}
              
              ></AtCanvas>
          :
            ""
          }
          {estiloFicha!==undefined &&
            <Painter 
              lienzo={estaFicha[0].caracteristicas.lienzo}
              styleFile={estiloFicha}
              primerCanvas={primerCanvas}
              enable={enable}
            />
          }

          {estiloFicha!==undefined && figureMenuEnable==true &&
            <BasicStaffViewerPanel/>
          }

          <ExercisesCorrectController 
            fichaId={ficha_id} 
            correctExercises={correctExercises} 
            isCorrectExercises={isCorrectExercises}
            idiomFile={idiom}
            idStudent={alumnoId}
            staticsFiles={staticsFiles}
            >
          </ExercisesCorrectController>
          
          { toggleComments == true &&
            <Mol_Comments
            fichaId={ficha_id} 
            profesorId={profesorId}
            idStudent={alumnoId}></Mol_Comments>
          }

          <div className="floating-viewer-menu">
            <AtButton onClick={switchPainter} className="floating-viewer-menu__at-button" image={Paint} type={typeCss.tertiary} widthImg="50px" heightImg="50px"></AtButton>
            {profesorId===undefined ? 
            <AtButton onClick={onClickCheckAnswers} className="floating-viewer-menu__at-button" image={Check} type={typeCss.tertiary} widthImg="50px" heightImg="50px"></AtButton>
            : ""}
            
            <AtButton onClick={switchFigureMenu} className="floating-viewer-menu__at-button" image={IconBasicStaff} type={typeCss.tertiary} widthImg="50px" heightImg="50px"></AtButton>
            
            { (alumnoId!==undefined && profesorId!==undefined) ? //Este es para el profesorado, para añadir comentarios.
            <AtButton onClick={onClickOpenComments} className="floating-viewer-menu__at-button" image={iconoBocadillo} type={typeCss.tertiary} widthImg="50px" heightImg="50px"></AtButton>
            : ""
            }

            { (alumnoId!==undefined && profesorId==undefined && hasComments == true) ? //Solo para los alumnos y solo si tienen comentario
            <AtButton onClick={onClickOpenCommentsStudent} className="floating-viewer-menu__at-button" image={iconoBocadillo} type={typeCss.tertiary} widthImg="50px" heightImg="50px"></AtButton>
            : ""
            }

            { (alumnoId!==undefined && profesorId==undefined && hasComments == true && toggleCommentsStudent == true) ? //Al mismo tiempo que el anterior pero se puede ocultar si haces clic
            <div className='mol_comments'>{ messageList.map((message, index) =>{return <div key={`message-${index}`}className='mol_comments_comment'>{message}</div>})}</div>
            : ""
            }

            <AtButton onClick={onClickFullScream} className="floating-viewer-menu__at-button" image={fullScream ? GoOut : FullScreen} type={typeCss.tertiary} widthImg="50px" heightImg="50px"></AtButton>
            
          </div>

        </div>
    )
}


export default Viewer;
import React,{useState} from "react";
import BasicStaffViewerPanel from './BasicStaffViewerPanel';
import IconBasicStaff from '../../../../Images/IconoPentagrama.png';
import './BasicStaffViewer.scss';

const BasicStaffViewer =(props)=>{
    let {
        styleElement,
        scaleFile
    }=props;
    const [enable,setEnable]=useState(false);

    const onClickVisibilityFigureMenu=()=>{
        setEnable(!enable);
    }
    
    return <div>
            <div onClick={onClickVisibilityFigureMenu}>
                <img style={styleElement} src={IconBasicStaff} alt="basic-staff"/>
            </div>
            
            <div className={enable ? "" : "deactivate"}>
                <BasicStaffViewerPanel scaleFile={scaleFile}></BasicStaffViewerPanel>
            </div>
        </div>;
}

export default BasicStaffViewer;
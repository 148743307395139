/* eslint-disable no-case-declarations */
/* eslint-disable eqeqeq */
export const types = {
    POST: 'POST_FICHAS',
    PUT: 'UPDATE_FICHAS',
    DELETE: 'DELETE_FICHAS',
    RESET: 'RESET_FICHAS',
    RELOAD: 'RELOAD_FICHAS',
  };
  
 
  
 const FichasReducer = (state = [], action) => {
    switch (action.type) {
      case types.POST:
        
        return [
          ...state.data,
          { ...action.payload },
        ];

      case types.PUT:
        return state.map((child) => (child.id == action.payload.id ? action.payload : child));

      case types.DELETE:
        
        const newValues = state;
        
        const index = state.data.findIndex(
          (elem)=>{
            
            return elem.id==action.id;
          }
          );
        
        newValues.data.splice(index, 1);

        

        return newValues;
        

      case types.RELOAD:
        return action.payload;

      case types.RESET:
        return [];

      default:
        return state;
    }
  };

  export default FichasReducer;
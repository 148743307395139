import React, { useState,useEffect } from 'react';
import {useDispatch,useSelector,shallowEqual} from 'react-redux';
import Popup from "reactjs-popup";
import {httpGet,httpDelete} from '../../Store/HttpCalls.jsx';
import {ruta} from '../../Constantes/Rutas.js'
import {reloadRecursos} from '../../Store/Actions/RecusosActions.js';
import UpLoadMultimedia from './UpLoadMultimedia';

import Tabla from '../TablaComponentes/Tabla';
import {obtenerJSonPaginador} from '../TablaComponentes/FuncionesTabla';
import {tipoDatoTabla} from '../../Constantes/TiposDatosTabla';
import {multimediaOrder,descAsc } from '../../Constantes/SelectFiltersAndOrder';

import AtButton from '../Atoms/At_Button/At_Button';
import AtPopup from '../Atoms/At_Popup/At_Popup';


import './MultimediaController.scss';
import AtSelect from '../Atoms/At_Select/At_Select.jsx';
import FormUploadMultimedia from './FormUploadMultimedia.jsx';





const RecursosMultimediaControlador =()=>{

    const dispatch=useDispatch();
    
    const [orderDescAsc,setOrderDescAsc]=useState(descAsc[0][0]);
    const [orderByColum,setOrderByColum]=useState(multimediaOrder[0][0]);
    

    const onChangeSelectDescAsc=(newValue)=>{
        setOrderDescAsc(newValue);
    }

    const onChangeSelectByColum=(newValue)=>{
        setOrderByColum(newValue);
    }

    const recursos=useSelector((state)=>{
        
        return state.recursos;
    },shallowEqual);

    useEffect(()=>{
       
        httpGet(ruta.getOrderedPagedMultimedia+"?colum="+orderByColum+"&descOrAsc="+orderDescAsc).then((recursos)=>{
            
            dispatch(reloadRecursos(recursos));
            
        },(error)=>{
            console.error("nuestro error " + error)
        })

    },[shallowEqual,orderDescAsc,orderByColum]);

    const updateMultimediaInTable=()=>{
        
        httpGet(ruta.getPagedMultimedia).then((recursos)=>{
            
            dispatch(reloadRecursos(recursos));

        },(error)=>{
            console.error("nuestro error " + error)
        })
    }

    const borrarRecurso=(id)=>{

        
        httpDelete(ruta.borrarRecurso+id).then(()=>{
            updateMultimediaInTable();
        },(error)=>{
            console.error("nuestro error " + error)
        })

    }

    const onClickEditMultimedia=(id)=>{
        alert(id);
    }



    const onClickCambioPagina=(enlaceBackEnd)=>{
        
        httpGet(ruta.getOrderedPagedMultimedia + enlaceBackEnd +"&colum="+orderByColum+"&descOrAsc="+orderDescAsc).then((recursos)=>{
        //httpGet(ruta.getPagedMultimedia +"?colum="+orderByColum+"&descOrAsc="+orderDescAsc).then((recursos)=>{            
            
            dispatch(reloadRecursos(recursos));
            
        },(error)=>{
            console.error("nuestro error " + error)
        })
    };

    
    let paginacionVar=obtenerJSonPaginador(recursos,onClickCambioPagina);

    const celdas=[
        
        {
            tipoDato:tipoDatoTabla.texto,
            nombreCelda:"Nombre recurso",
            texto:'nombre',
        },
        {
            tipoDato:tipoDatoTabla.texto,
            nombreCelda:"Fecha creacion",
            texto:'created_at',
        },
        {
            tipoDato:tipoDatoTabla.imageMultimedia,
            nombreCelda:"Miniatura",
            width:"84px",
            height:"56.6px",
        },
        {
            tipoDato:tipoDatoTabla.boton,
            nombreCelda:"Eliminar recurso",
            nombreBoton:"Eliminar",
            fctBoton:borrarRecurso,
            argumentos:['id']
        },
        {
            tipoDato:tipoDatoTabla.popupEditMultimedia,
            nombreCelda:"Editar recurso",
            nombreBoton:"Editar",
            className:""
        },
        {
            tipoDato:tipoDatoTabla.popupEditMultimediaItems,
            nombreCelda:"Editar recurso2",
            nombreBoton:"Editar2",
            className:""
        },
        
        
    ];
    
    
    let datos;
    if(recursos!=null){
        
        datos=recursos.data;
    }

    let stylePopupContent= {
         
        "width": "788px",
        "position": "relative",
        "background": "rgb(255, 255, 255)",
        "margin": "auto",
        "padding":0,
        "border":"none",
  }
    
    return(
        <div className="multimedia-controller">

            <div className="multimedia-controller__content">
               
                <div className="panel-administrate">

                    <div className="panel-administrate__select">
                        <AtSelect values={multimediaOrder} onChange={event=>onChangeSelectByColum(event.target.value)}></AtSelect>
                    </div>

                    <div className="panel-administrate__select">
                        <AtSelect values={descAsc} onChange={event=>onChangeSelectDescAsc(event.target.value)}></AtSelect>
                    </div>

                    <AtPopup noCloseInClickOut trigger={<div className="panel-administrate__button"><AtButton>Añadir nuevo</AtButton></div>} className="up-load-multimedia">
                        <FormUploadMultimedia updateMultimediaInTable={updateMultimediaInTable}></FormUploadMultimedia>
                    </AtPopup>

                </div>

                <Tabla paginacion={paginacionVar} datos={datos} estructuraColumnas={celdas} updateMultimediaInTable={updateMultimediaInTable}></Tabla>
            </div>
        </div>
    );
};

export default RecursosMultimediaControlador;
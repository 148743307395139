import React from 'react';
import {useDispatch} from 'react-redux';

import MolLanguageSelector from './../../Molecules/Mol_LanguageSelector/Mol_LanguageSelector';
import AtButton from '../../Atoms/At_Button/At_Button';
import {onClickGuardasElementosEnServidor} from '../../ComponentesEditor/EditorControlador/EditorControlador';
import './Org_RightMainPanel.scss';
import { ImageIcon } from '../../Atoms/At_Image/At_Image';

const Org_RightMainPanel=(props)=>{
    let {
        buttonSave,
    }=props;

    const dispatch=useDispatch();

    return(
        <div className="org-right-main-panel">
            <div className="org-right-main-panel__mol-language-selector org-right-main-panel__item" >
                <MolLanguageSelector widgth={20} height={20}></MolLanguageSelector>
            </div>
            {buttonSave ? 
            <div className="org-right-main-panel__button org-right-main-panel__item">
                <AtButton  image={ImageIcon.disquette}  widthImg={19} heightImg={19} onClick={()=>{onClickGuardasElementosEnServidor(dispatch)}}>Guardar</AtButton>
            </div> 
            : null}
            
        </div>
    )

}


export default Org_RightMainPanel;





import React, { useState } from 'react';

const DT_CheckBox =(celda)=>{
    /**tipoDato:tipoDatoTabla.checkBox,
                        fctOnChange:pruebaBoton,
                        FlagChecked:true */
    let funcionOnChange=celda['datos'].fctOnChange;
    let flagChecked=celda['datos'].flagChecked;

    return(
        
        <td className="table-body__td">
            <input type="checkbox" onChange={funcionOnChange}  defaultChecked={flagChecked}></input>
        </td>
        
    );
};

export default DT_CheckBox;
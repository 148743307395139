import React, { useEffect,useState } from 'react';


import { useParams } from "react-router-dom";
import {useDispatch,useSelector,shallowEqual} from 'react-redux';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import PanelHerramientas from '../PanelHerramientas/PanelHerramientas.jsx';
import PanelModificadorControlador from '../PanelEditor/PanelModificadorControlador.jsx';
import AtEnrichedText from '../../Atoms/At_EnrichedText/At_EnrichedText';


import {httpGet} from '../../../Store/HttpCalls.jsx';
import {reloadFichasElementos} from '../../../Store/Actions/FichasEditorElementosActions';
import {reloadRecursos} from '../../../Store/Actions/RecusosActions';
import {reloadRecursosItems} from '../../../Store/Actions/RecursosItemsActions';
import {getEstaFicha} from '../../../Store/Actions/EstaFichasActions';

import {regargarElementosDelServidor,guardasElementosEnServidor} from './EditorConexionesServidor.jsx';
import {setEditFile} from '../../FichasControlador/FichasConexionesServidor';
import {ruta} from '../../../Constantes/Rutas';
import { Surface } from '@progress/kendo-drawing';
import { drawScene } from '../Reticula/ReticulaControlador';

//componentes estilos
//atoms
import AtCanvas from './../../Atoms/At_Canvas/At_Canvas';
import MolTextAndInputText from '../../Molecules/Mol_TextAndInputText/Mol_TextAndInputText';

import "./EditorController.scss";
import { idiomas } from '../../../Constantes/idiomas.js';

let elementosEditor;
let idFicha;
let estaFicha;

export const onClickGuardasElementosEnServidor=(dispatch)=>{
  

  setEditFile(idFicha,estaFicha[0],dispatch);
  guardasElementosEnServidor(elementosEditor,idFicha);
  alert("La ficha ha sido guardadas");

};

const ControladorEditor=()=> {
  
  const dispatch=useDispatch();
  

  const {ficha_id}=useParams();
  idFicha=ficha_id;
  
  const idiom=useSelector((state)=>{
          
    return state.idiom;
  },shallowEqual);

  

  const [idiomaFicha,setIdiomaFicha]=useState(idiom);

  

  
  //CONEXION CON SERVIDOR--------------------------------------------------------------


  useEffect(()=>{

    httpGet(ruta.recuperarRecursos).then((recursos)=>{
        
        dispatch(reloadRecursos(recursos));

    },(error)=>{
        console.error("nuestro error " + error)
    })

  },[]);

  
  
  useEffect(()=>{
    
    httpGet(ruta.recuperarRecursosItems).then((recursos)=>{
        
        dispatch(reloadRecursosItems(recursos));

    },(error)=>{
        console.error("nuestro error " + error)
    })

  },[]);

  const recursosItems=useSelector((state)=>{
    
    return state.recursosItems;
  },shallowEqual);
  
  const fichasElementos=useSelector((state)=>{
    return state.fichaselementos;
  },shallowEqual);

  
  elementosEditor=useSelector((state)=>{
    return state.elementosEditor;
  },shallowEqual);
  
  const eventRecargaServidor=()=>{
    
    regargarElementosDelServidor(fichasElementos,dispatch);

  };

  

  useEffect(()=>{
    httpGet(ruta.editor+ficha_id).then((fichaselementos)=>{
        dispatch(reloadFichasElementos(fichaselementos));
        
    },(error)=>{
        console.error("nuestro error " + error)
    });
    
  },[]);

  useEffect(()=>{
    eventRecargaServidor();
  },[fichasElementos]);

//DATOS DE LA FICHA---------------------------------------------------

  let url=window.location.pathname.split("/");
  
  
  estaFicha=useSelector((state)=>{
          
    return state.estaFicha;
  },shallowEqual);

 
  
  useEffect(()=>{

    httpGet(ruta.getFicha+url[url.length-1]).then((thisFicha)=>{
        
        dispatch(getEstaFicha(thisFicha));

    },(error)=>{
        console.error("nuestro error " + error)
    })

  },[]);
  
 
  
  const [estiloMarco,setEstiloMarco]=useState();
  const [estiloFicha,setEstiloFicha]=useState();
  const [dndExercise,setDndExercise]=useState();
  const [figureSize,setFigureSize]=useState();
  //let newEstiloMarco,newEstiloFicha;

  
  useEffect(() => {
    
    if(estaFicha[0]!==undefined){

      let newEstiloFicha={
        
        width: estaFicha[0].caracteristicas.lienzo[0] +"px",
        height: estaFicha[0].caracteristicas.lienzo[1] +"px",
        
      };



      
      //FONDO FICHA
      if(estaFicha[0].caracteristicas.fondoFicha[0]=="#"){
        newEstiloFicha={...newEstiloFicha,backgroundColor:estaFicha[0].caracteristicas.fondoFicha};
        }else{

          let recursoFondo=estaFicha[0].caracteristicas.fondoFicha.items.filter((elem)=>{
              return elem.language==idiom;
          });

         
          
          if(recursoFondo[0]!=null){
            newEstiloFicha={...newEstiloFicha,backgroundImage:"url("+recursoFondo[0].url+")"};
            
          }else{
            
            let altFondo=estaFicha[0].caracteristicas.fondoFicha.items.filter((elem)=>{
              return elem.language==idiomas.castellano;
            });

            if(altFondo[0]!==undefined){
              newEstiloFicha={...newEstiloFicha,backgroundImage:"url("+altFondo[0].url+")"};
            }else{
              newEstiloFicha={...newEstiloFicha,backgroundImage:"#ffffff"};
            }

          }
        }


        //MARCO FICHA
        let heightWindows=window.innerHeight;
        let newEstiloMarco={
                        height:(heightWindows-55) + "px"
                      };
        

        if(estaFicha[0].caracteristicas.marcoFicha[0]==="#"){
        newEstiloMarco={...newEstiloMarco,backgroundColor:estaFicha[0].caracteristicas.marcoFicha};
          
        }else{
          let recursoMarco=estaFicha[0].caracteristicas.marcoFicha.items.filter((elem)=>{
              return elem.language==idiom;
          });
          
          if(recursoMarco[0]!=null){
            newEstiloMarco={...newEstiloMarco,backgroundImage:"url("+recursoMarco[0].url+")"};
          }else{
              
            let altFondo=estaFicha[0].caracteristicas.marcoFicha.items.filter((elem)=>{
              return elem.language==idiomas.castellano;
            });
            
            if(altFondo[0]!==undefined){
              newEstiloMarco={...newEstiloMarco,backgroundImage:"url("+altFondo[0].url+")"};
            }else{
              newEstiloMarco={...newEstiloMarco,backgroundImage:"#F8F8F8"};
            }

          }

        }
        
      

    setEstiloFicha(newEstiloFicha);
    setEstiloMarco(newEstiloMarco);
    setFigureSize(estaFicha[0].caracteristicas.figureSize)
    setDndExercise(estaFicha[0].caracteristicas.dataExercise);

    }
  }, [estaFicha,idiomaFicha]);
    
  

  useEffect(()=>{
    
    setIdiomaFicha(idiom);

  },[idiom]);

  
  //const estiloFicha={backgroundColor:estaFicha.caracteristicas.fondoFicha};

//RETICULA------------------------------------------------------------
  let flagReticula=false;
  let [gridSeparation,setgridSeparation]=useState(20);
  const reticula=()=>{
    let surface;
    let createSurface = () => {
        const element = document.getElementById("lienzo_reticula")
        surface = Surface.create(element);
        return surface;
    }
    if(flagReticula){
      drawScene(createSurface(),500,0,0);
      flagReticula=false;
    }else{
      let reticulaSeparacion=gridSeparation;

       if(reticulaSeparacion<501 && reticulaSeparacion>0){
         drawScene(createSurface(),Number(reticulaSeparacion),estaFicha[0].caracteristicas.lienzo[0],estaFicha[0].caracteristicas.lienzo[1]);
         flagReticula=true;
        } else{
          alert("El valor de la reticula debe de ser entre 1 y 500");
        }
    }
    
  };
  

  const [enableZoom,setEnableZoom]=useState(true);
       
  const onKeyPressEnableZoom=(key)=>{

    if(key==="q"){
      setEnableZoom(true);
    }
    if(key==="w"){
      setEnableZoom(false);
    }

  }
  const [defaultPositionXCanvas,setDefaultPositionXCanvas]=useState();
  const [defaultPositionYCanvas,setDefaultPositionYCanvas]=useState();
  
  let heightWorkSpace=window.innerHeight-55;
  let widthWorkSpace=window.innerWidth;
  
  useEffect(() => {
    if(estaFicha[0]!==undefined){
      
      let newDefaultPositionXCanvas=(widthWorkSpace-estaFicha[0].caracteristicas.lienzo[0])/2;
     
      let newDefaultPositionYCanvas=(heightWorkSpace-estaFicha[0].caracteristicas.lienzo[1])/2;

      
      if(newDefaultPositionXCanvas<0){
        newDefaultPositionXCanvas=0;
      }
      if(newDefaultPositionYCanvas<0){
        newDefaultPositionYCanvas=0;
      }
      
      setDefaultPositionXCanvas(newDefaultPositionXCanvas);
      setDefaultPositionYCanvas(newDefaultPositionYCanvas);
    }
  }, [estaFicha[0]]);

  
  
  

  let styleprueba={
    width: widthWorkSpace+"px",
    height: heightWorkSpace+"px",
  }




  return (
    
    <div className={enableZoom ? "" : "editor-controller__zoom " } style={estiloMarco} tabIndex="0" onKeyPress={KeyboardEvent=>onKeyPressEnableZoom(KeyboardEvent.key)}>
          
          
         {(estiloFicha!==undefined && elementosEditor!==undefined && dndExercise!==undefined) ? 
            
            <TransformWrapper 
            
            wheel={{ 
              step:200,

            }}
            options={{
            limitToBounds:false,
              //centerContent: true,
              disabled:enableZoom,
              
            }}
            defaultPositionX={defaultPositionXCanvas}
            defaultPositionY={defaultPositionYCanvas}
            positionX={defaultPositionXCanvas}
            positionY={defaultPositionYCanvas}
            pan={{velocity:false}}
            >
              <TransformComponent >
                  <div style={styleprueba}>
                  
                  <AtCanvas   key={"canvas"}
                              style={estiloFicha} 
                              editorElements={elementosEditor} 
                              fileIdiom={idiomaFicha} 
                              exerciseData={dndExercise}
                              figureSize={figureSize}
                              >
                  </AtCanvas>  
                  </div>
              </TransformComponent>  
            </TransformWrapper>
          
            :
            ""
            
        }

        
        <PanelHerramientas thisFile={estaFicha} ></PanelHerramientas>
        
        <PanelModificadorControlador 
          figureSize={figureSize}  
          idiomaFicha={idiomaFicha} 
          canvasSize={estiloFicha} 
          thisFile={estaFicha}></PanelModificadorControlador>
      
        
        <MolTextAndInputText className="editor-controller__grid-handler" type="checkbox" onChange={reticula} value="20">Activar / desactivar retícula</MolTextAndInputText>
        
      </div>
  );
}

export default ControladorEditor;

import React,{useEffect} from 'react';
import {useDispatch,useSelector,shallowEqual} from 'react-redux';


import {httpGet} from '../../Store/HttpCalls.jsx';
import {ruta} from '../../Constantes/Rutas';
import {reloadRecursos} from '../../Store/Actions/RecusosActions.js';

import Mol_Rack from '../Molecules/Mol_Rack/Mol_Rack';

import {tipoRecurso} from '../../Constantes/TiposRecursos';

const SelectBackgroundAndMiniature =(props)=>{
    let {
        onClickInsertMultimediaIntoElement

    }=props
    const dispatch=useDispatch();
    const recursos=useSelector((state)=>{
        
        return state.recursos;
    },shallowEqual);
    
    useEffect(()=>{

        httpGet(ruta.getPagedMultimedia+"?type="+tipoRecurso.imagen).then((recursos)=>{
            
            dispatch(reloadRecursos(recursos));

        },(error)=>{
            console.error("nuestro error " + error)
        })

    },shallowEqual);



    const onClickChangePage=(urlEnd)=>{
            
        httpGet(ruta.getPagedMultimedia + urlEnd).then((multimedia)=>{
            dispatch(reloadRecursos(multimedia));
        },(error)=>{
            console.error("nuestro error " + error)
        })

    };
    
    const onChangeMultimediaFilter=()=>{}


    let datos=[],paginate=[];
    if(recursos.data!=null){
  
        datos=recursos.data;
        paginate=recursos;
        //delete paginate.data
        
    }
    return(
        <Mol_Rack
                onClickDivRack={onClickInsertMultimediaIntoElement} 
                onClickChangePage={onClickChangePage} 
                onChangeFilter={onChangeMultimediaFilter} 
                paginate={paginate} 
                data={datos}>

        </Mol_Rack>
    )

}


export default SelectBackgroundAndMiniature;
import React from 'react';

import './YoutubeMultimedia.scss';

import YouTube from 'react-youtube';
import {idiomas} from '../../../../Constantes/idiomas';
import BackgroundMultimedia from './BackgroundMultimedia';

const YoutubeMultimedia=(props)=>{
    let {
        language, 
        multimedia
    }=props
    
       
    let imageUrl=multimedia.items.filter((elem)=>{
        return elem.language===language;

    });
    if(imageUrl.length===0){
        imageUrl=multimedia.items.filter((elem)=>{
          return elem.language===idiomas.castellano;
        });
    }

    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
        },
      };

      let idVideo=imageUrl[0].link.split("?v=");
    
    return (
      <BackgroundMultimedia>
        <div className="youtube-player__iframe">
          <YouTube videoId={idVideo[idVideo.length-1]} opts={opts}  />
        </div>
      </BackgroundMultimedia>
    );
}

export default YoutubeMultimedia;
import { types } from '../Reducers/FichasReducer';


export const createFichas = (values) => ({
  type: types.POST,
  payload: values,
});

export const updateFichas = (values) => ({
  type: types.PUT,
  payload: values,
});

export const removeFichas = (id) => ({
  type: types.DELETE,
  id,
});

export const reloadFichas = (items) => ({
  type: types.RELOAD,
  payload: items,
});

export const resetFichas = () => ({
  type: types.RESET,
});



import { types } from '../Reducers/EditorElementosReducer';

export const crearElementoEditor = (newElemento) => ({
  type: types.CREAR_ELEMENTO,
  payload:newElemento,
});

export const cargarNuevosElementoEditor = (newElemento) => ({
  type: types.RECARGAR_PAGINA,
  payload:newElemento,
});

export const editarPosicionAc = (value,canvasSize,elementStyle) => ({
  type: types.EDITAR_POSICION,
  value,
  canvasSize,
  elementStyle
});

export const editarEscaladoAc = (id,css,canvasSize) => ({
  type: types.EDITAR_ESCALADO,
  id,
  css,
  canvasSize,
});

export const editText = (id,text) => ({
  type: types.EDIT_TEXT,
  id,
  text 
});


export const editarCssAc = (id,css) => ({
  type: types.EDITAR_CSS,
  id,
  css
});

export const eliminarElementoAc = (id) => ({
  type: types.ELIMINAR_ELEMETO,
  id
});

export const insertMultiedia = (id,multimedia) => ({
  type: types.INSERT_MULTIMEDIA,
  id,
  multimedia
});

export const insertAnimation = (id,animation) => ({
  type: types.INSERT_ANIMATION,
  id,
  animation
});

export const deleteMultimedia = (id) => ({
  type: types.DELETE_MULTIMEDIA,
  id,
  
});

export const deleteAnimation = (id) => ({
  type: types.DELETE_ANIMATION,
  id,
  
});


export const editExercise=(id,exercise)=>({
  type: types.EDIT_EXERCISE,
  id,
  exercise
});

export const editExerciseFFOrTest=(id,idLink,exercise)=>({
  type: types.EDIT_EXERCISE_FF_TEST,
  id,
  idLink,
  exercise
});

export const editExerciseIT=(id,idLink,exercise)=>({
  type: types.EDIT_EXERCISE_IT,
  id,
  idLink,
  exercise
});

export const doDroppable=(id,idDroppable)=>({
  type:types.DO_DROPPABLE,
  id,
  idDroppable
});

export const deleteDroppable=(id,idDroppable)=>({
  type:types.DELETE_DROPPABLE,
  id,
  idDroppable
});

export const setColorSvg=(id,color)=>({
  type:types.SET_COLOR_SVG,
  id,
  color
});


export const emptyItems=()=>({
  type:types.EMPTY_ITEMS,
});

export const setAudioViewerType=(id,newType)=>({
  type:types.SET_AUDIO_VIEWER_TYPE,
  id,
  newType
});


export const doDndFree=(id,isDnd)=>({
  type:types.DO_DND_FREE,
  id,
  isDnd
});
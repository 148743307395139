
export const eventsAnimation={
    onClickEvent:"onClickEvent",
    alwaisEvent:"alwaisEvent",
    hoverEvent:"hoverEvent",
 

}

export const eventsAnimationArray=[
    ["onClickEvent","onClickEvent"],
    ["alwaisEvent","alwaisEvent"],
    ["hoverEvent","hoverEvent"],
   
]

export const animation={
    shake:"shake",
    doubleScaling:"doubleScaling",
    appears:"appears",
    disappear:"disappear",
    rotation:"rotation"

}

export const animationArray=[
    ["shake","shake"],
    ["doubleScaling","doubleScaling"],
    ["appears","appears"],
    ["disappear","disappear"],
    ["rotation","rotation"]
]





import React, { forwardRef, useImperativeHandle, useState, useEffect, createRef } from 'react';
import {tipoDatoTabla} from '../../Constantes/TiposDatosTabla';
import {crearDatosTabla} from './FuncionesTabla'
import Fila from './Fila';

const CuerpoTabla = forwardRef((props, ref)=>{
    let {datos,estructuraColumnas,onChange, updateMultimediaInTable}=props;
    let filas=crearDatosTabla(estructuraColumnas,datos);
    let id=0;
    const [elRefs, setElRefs] = useState([]);

    useImperativeHandle(ref, () => ({

        newChanges(fila, idFicha, atributo, valor) {
            elRefs[fila].current.newChanges(atributo, valor);
        }
    
      }));

    useEffect(() => {
    // add or remove refs
    setElRefs(elRefs => (
        Array(filas.length).fill().map((_, i) => elRefs[i] || createRef())
    ));
    }, [filas.length]);
    


    return(
        
       <>
            
            {
                
                filas.map((fila,i)=>{
                        id++;
                        return <Fila 
                            ref={elRefs[i]}
                            key={`table-${id}`} 
                            id={datos[i].id} 
                            celdasEnFila={fila} 
                            onChange={(atributo, event) => {onChange(i, datos[i].id, atributo,event)}}
                            updateMultimediaInTable={updateMultimediaInTable}
                        />; 
                    }) 

            }
                
            
           
       </>
        
    );
});

export default CuerpoTabla;
import React,{useEffect,useState} from 'react';
import ReactDOM from 'react-dom';

import DraggableEdit from 'react-draggable';

import AtPopup from '../../../Atoms/At_Popup/At_Popup';
import AtAudioPlayer from '../../../Atoms/At_AudioPlayer/At_AudioPlayer';

import './AudioMultimedia.scss';

import {idiomas} from '../../../../Constantes/idiomas';
import {AudioViewerTypeJson} from '../../../../Constantes/TiposRecursos';
const AudioMultimedia=(props)=>{
    let {
        language, 
        multimedia,
        textStatic,
        openDelay,
        thisComponent
    }=props;
    
    let styleOnClick={width:100+"%",height:100+"%"};
    const [audio,setAudio]= useState();
    
    

      useEffect(() => {


        let imageUrl=multimedia.items.filter((elem)=>{
          return elem.language===language;
  
      });
      if(imageUrl.length===0){
          imageUrl=multimedia.items.filter((elem)=>{
            return elem.language===idiomas.castellano;
          });
        }

        
        if(multimedia.audioViewerType==AudioViewerTypeJson.flotante || 
          multimedia.audioViewerType===undefined || 
          multimedia.audioViewerType==AudioViewerTypeJson.popup){
           
            setAudio(imageUrl[0].url);
          }

        if(multimedia.audioViewerType==AudioViewerTypeJson.click){
            setAudio( new Audio(imageUrl[0].url));
        }
        
      }, [multimedia]);
      let lastLaunch=0;
      const onClickPlayAudio=()=>{
   
        if(Date.now()-lastLaunch>300){
          if(audio.paused){
            audio.play();
          }else{
            audio.pause();
          }
          lastLaunch=Date.now();
        }
        
        
      }


      const [classNameFloatingAudio,setClassNameFloatingAudio]=useState(false);
      const onClickHideUnhide=()=>{
        
        if(classNameFloatingAudio){
          
          setClassNameFloatingAudio(false);
        }else{
          
          setClassNameFloatingAudio(true);
        }
      }
      
      
      if(multimedia.audioViewerType==AudioViewerTypeJson.flotante){
        return(
          <div>
          <div className={"content-floating-audio"} onClick={onClickHideUnhide}>
            {thisComponent}
          </div>
          {ReactDOM.createPortal(
            <DraggableEdit  bounds='.viewerController' handle=".drag-here">
              <div className={"audio div-floating-audio at-audio-player" + (classNameFloatingAudio ? "":" div-floating-audio__hide")}>
                <div className="drag-here">
                  <div className="drag-here__icon">
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                  </div>
                </div>
                <AtAudioPlayer audio={audio} type="audio/mpeg" onClickX={onClickHideUnhide}></AtAudioPlayer>
              </div>
            </DraggableEdit>
          ,
          document.getElementById('root'))}
      </div>
        )
      }

      if(multimedia.audioViewerType==AudioViewerTypeJson.click){
        return (
          <div style={styleOnClick} onTouchStart={onClickPlayAudio} onClick={onClickPlayAudio}>
            {thisComponent}
          </div>
        )
      }

      if(multimedia.audioViewerType===undefined || multimedia.audioViewerType==AudioViewerTypeJson.popup){
          return (
            <AtPopup trigger={thisComponent} openDelay={openDelay}>
                        <div className="div-audio" >
                          <audio className="audio" controls>
                            <source src={audio} type="audio/mpeg"/>

                          </audio>
                        </div>
            </AtPopup>
            
          )
      }
      
    

    
}

export default AudioMultimedia;
import React from 'react';
import { ReactComponent as HomeLogo } from '../../../Images/House.svg';
import { ReactComponent as Disquette} from '../../../Images/Disquette.svg';
import { ReactComponent as LanguageEN} from '../../../Images/LanguageEN.svg';
import { ReactComponent as LanguageES} from '../../../Images/LanguageES.svg';
import { ReactComponent as LanguageVA} from '../../../Images/LanguageVA.svg';
import { ReactComponent as Trash} from '../../../Images/Trash.svg';
import { ReactComponent as FigureCircle} from '../../../Images/Figure Circle.svg';
import { ReactComponent as FigureSquare} from '../../../Images/Figure Square.svg';
import { ReactComponent as FigureTriangle} from '../../../Images/Figure Triangle.svg';
import { ReactComponent as ToolFigureSquare} from '../../../Images/Tool Figure Square.svg';
import { ReactComponent as ToolText} from '../../../Images/Tool Text.svg';
import { ReactComponent as ToolPhoto} from '../../../Images/Tool Photo.svg';
import { ReactComponent as ChevronLeftDouble} from '../../../Images/Chevron Left Double.svg';
import { ReactComponent as ChevronRightDouble} from '../../../Images/Chevron Right Double.svg';
import { ReactComponent as ChevronLeft} from '../../../Images/Chevron Left.svg';
import { ReactComponent as ChevronRight} from '../../../Images/Chevron Right.svg';

import { ReactComponent as TestSelect} from '../../../Images/Tool QuestionTest.svg';
import { ReactComponent as TestSelectImage} from '../../../Images/Tool QuestionTest.svg';
import { ReactComponent as FillableField} from '../../../Images/Tool Desarrollo.svg';
import { ReactComponent as DraggAndDrop} from '../../../Images/Tool Arrastre.svg';
import { ReactComponent as ColoringSvg} from '../../../Images/Tool Dibujar-1.svg';
import { ReactComponent as ToolStaff} from '../../../Images/Tool Pentagrama.svg';

import './At_Image.scss';

export const ImageIcon = {
    home: HomeLogo,
    disquette: Disquette,
    languageEN: LanguageEN,
    languageES: LanguageES,
    languageVA: LanguageVA,
    delete: Trash,
    figureCircle: FigureCircle,
    figureSquare: FigureSquare,
    figureTriangle: FigureTriangle,
    figureToolFigureSquare: ToolFigureSquare,
    toolText: ToolText,
    toolPhoto: ToolPhoto,
    chevronLeftDouble:ChevronLeftDouble,
    chevronRightDouble:ChevronRightDouble,
    chevronLeft:ChevronLeft,
    chevronRight:ChevronRight,
    TestSelect:TestSelect,
    TestSelectImage:TestSelectImage,
    FillableField:FillableField,
    DraggAndDrop:DraggAndDrop,
    ColoringSvg:ColoringSvg,
    ToolStaff:ToolStaff
};

const At_Image =(props)=>{
    let {
        image,
        widgth,
        height,
        className
    }=props;

    const Icon = image === undefined ? ImageIcon.HomeLogo : image;

    return (
        <>
            { image !== undefined &&(
                <>
                    { typeof image === "string" ? 
                    <img className={className} src={image} alt="" width={widgth} height={height}/> :
                    <Icon className={className} width={widgth} height={height} />  
                    }
                </>
            )}
            
        </>
    )
}

export default At_Image;
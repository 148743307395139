import React from 'react';
import './At_Title.scss';

const At_Title =(props)=>{
    let {
        
        fontSize,
        fonstColor,
        children,
        
    } = props
    let style;

    if(fontSize!=null){
        style={...style,
            fontSize:fontSize+"px"
        }
    }

    if(fonstColor!=null){
        style={...style,
            color:fonstColor
        }
    }

    return (

                <label style={style} className="at-title">
                    {children}
                </label>
            
    )
}

export default At_Title;
  
import React from "react";
import "./painter.scss";

export const BrushPreview = ({
  currentColor,
  currentWidth,
}) => {
  
  const styles = {
    backgroundColor: currentColor,
    width: `${currentWidth}px`,
    height: `${currentWidth}px`,
  };
  return (
    <div className="painter__toolbar-preview">
      <div style={styles} className="painter__toolbar-preview__brush"></div>
    </div>
  );
};
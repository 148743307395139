import React, { forwardRef, useImperativeHandle, useState, useEffect, createRef } from 'react';

import DTTexto from './TipoDatosTabla/DTTexto';
import DTBoton from './TipoDatosTabla/DTBoton';
import DTSelect from './TipoDatosTabla/DTSelect';
import DTCheckBox from './TipoDatosTabla/DTCheckBox';
import DTEnlace from './TipoDatosTabla/DTEnlace';
import DTTextBold from './TipoDatosTabla/DTTextBold';
import DTImage from './TipoDatosTabla/DTImage';
import DTImageMultimedia from './TipoDatosTabla/DTImageMultimedia';
import DTContent from './TipoDatosTabla/DTContent';
import DTPopup from './TipoDatosTabla/DTPopup';
import DTPopupEditFile from './TipoDatosTabla/DTPopupEditFile';
import DTPopupEditMultimedia from './TipoDatosTabla/DTPopupEditMultimedia';
import DTPopupEditMultimediaItems from './TipoDatosTabla/DTPopupEditMultimediaItems';

import {tipoDatoTabla} from '../../Constantes/TiposDatosTabla';

const Fila = forwardRef((props, ref)=>{
    const [elRefs, setElRefs] = useState([]);
    let {
        celdasEnFila,
        id,
        onChange,
        updateMultimediaInTable
    }=props;

    useImperativeHandle(ref, () => ({

        newChanges(atributo, valor) {
            const index = celdasEnFila.findIndex((celda) => celda.atributo == atributo);
            elRefs[index].current.newChanges(valor);
        }
    
    }));

    useEffect(() => {
        // add or remove refs
        setElRefs(elRefs => (
            Array(celdasEnFila.length).fill().map((_, i) => elRefs[i] || createRef())
        ));
    }, [celdasEnFila.length]);
    
    return(
        <tr className="table-body__tr">
            {celdasEnFila.map((celda,i)=>{
                switch (celda.tipoDato) {
                    case tipoDatoTabla.texto:
                        
                        return (<DTTexto key={id+`fila`+i} datos={celda}/>);
                        
            
                    case tipoDatoTabla.boton:
                        return (<DTBoton key={id+`fila`+i} datos={celda}/>);
                        
            
                    case tipoDatoTabla.select:
                        return (<DTSelect
                            ref={elRefs[i]} 
                            key={id+`fila`+i} 
                            indice={id+`fila`+i} 
                            datos={celda} 
                            onChange={(value) => {onChange(celda.atributo, value)}}
                        />);
                        
            
                    case tipoDatoTabla.checkBox:
                        return (<DTCheckBox key={id+`fila`+i} datos={celda}/>);
                        
            
                    case tipoDatoTabla.enlace:
                        return <DTEnlace key={id+`fila`+i} datos={celda}></DTEnlace>

                    case tipoDatoTabla.textBold:
                        return <DTTextBold key={id+`fila`+i} datos={celda}></DTTextBold>
                        
                    case tipoDatoTabla.image:
                        return <DTImage key={id+`fila`+i} datos={celda}></DTImage>

                    case tipoDatoTabla.svg:
                        return <DTImage key={id+`fila`+i} datos={celda}></DTImage>

                    case tipoDatoTabla.imageMultimedia:
                        return <DTImageMultimedia key={id+`fila`+i} datos={celda}></DTImageMultimedia>

                    case tipoDatoTabla.content:
                        return <DTContent key={id+`fila`+i} datos={celda}></DTContent>

                    case tipoDatoTabla.popup:
                        return <DTPopup key={id+`fila`+i} datos={celda}></DTPopup>
                      
                    case tipoDatoTabla.popupEditFile:
                        return <DTPopupEditFile key={id+`fila`+i} datos={celda}></DTPopupEditFile>


                    case tipoDatoTabla.popupEditMultimedia:
                        return <DTPopupEditMultimedia key={id+`fila`+i} datos={celda}></DTPopupEditMultimedia>
                    
                    case tipoDatoTabla.popupEditMultimediaItems:
                        return <DTPopupEditMultimediaItems key={id+`fila`+i} datos={celda}></DTPopupEditMultimediaItems>
                        
                    default:
                        return (<DTTexto key={id+`fila`+i} datos={celda}/>);
                        
                    
                }
                
            })}
        </tr>
    );
});

export default Fila;
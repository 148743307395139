import React from 'react';

import {idiomas} from '../../../../Constantes/idiomas';

const ImageElement=(props)=>{
    let {
        style,
        image,
        language
    }=props;

    let imageUrl=image.itemsMultimedia.filter((elem)=>{
        return elem.language === language;

    });

    if(imageUrl.length === 0){
        imageUrl=image.itemsMultimedia.filter((elem)=>{
          return elem.language === idiomas.castellano;
        });
      }

    let styleImg={
        //width:100+"%",height:100+"%",
        
    }
    let newStyle;
    if(style.width!="auto"){
        newStyle={
            ...styleImg,
            width:style.width
        };

        styleImg=newStyle;
    }

    if(style.height!="auto"){
        newStyle={
            ...styleImg,
            height:style.height
        };

        styleImg=newStyle;
    }

    if(style!==undefined){

         newStyle={
            ...styleImg,
            borderRadius:style.borderRadius
            
        }
        styleImg=newStyle;
    }
        
    return (
        <>
            <img style={{...styleImg,pointerEvents: "none"}} src={imageUrl[0].url} alt="imagen no disponoble"></img>
        </>
    )

}

export default ImageElement;
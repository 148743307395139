import React from 'react';

import DraggableEdit from 'react-draggable';

import './MusicalNoteInViewer.scss';

import BS2por4 from '../../../../Images/BS-2por4.png';
import BS3por4 from '../../../../Images/BS-3por4.png';
import BS3por8 from '../../../../Images/BS-3por8.png';
import BS4por4 from '../../../../Images/BS-4por4.png';
import BSbecuadro from '../../../../Images/BS-becuadro.png';
import BSbemol  from '../../../../Images/BS-bemol.png';
import BSblancaAlta  from '../../../../Images/BS-blancaAlta.png';
import BSblancaBaja  from '../../../../Images/BS-blancaBaja.png';
import BScalderon from '../../../../Images/BS-calderon.png';
import BScalderonInverso  from '../../../../Images/BS-calderonInverso.png';
import BSclaveFa  from '../../../../Images/BS-claveFa.png';
import BSclaveSol  from '../../../../Images/BS-claveSol.png';
import BScorcheaAlta  from '../../../../Images/BS-corcheaAlta.png';
import BScorcheaBaja  from '../../../../Images/BS-corcheaBaja.png';
import BSnegraAlta  from '../../../../Images/BS-negraAlta.png';
import BSnegraBaja  from '../../../../Images/BS-negraBaja.png';
import BSpuntillo  from '../../../../Images/BS-puntillo.png';
import BSredonda  from '../../../../Images/BS-redonda.png';
import BSrepetidorFinal  from '../../../../Images/BS-repetidorFinal.png';
import BSrepetidorInicio  from '../../../../Images/BS-repetidorInicio.png';
import BSsemicorcheaAlta  from '../../../../Images/BS-semicorcheaAlta.png';
import BSsemicorcheaBaja from '../../../../Images/BS-semicorcheaBaja.png';
import BSsemicorcheaCuaAlta from '../../../../Images/BS-semicorcheaCuaAlta.png';
import BSsemicorcheaCuaBaja from '../../../../Images/BS-semicorcheaCuaBaja.png';
import BScorcheaDobleAlta from '../../../../Images/BS-corcheaDobleAlta.png';
import BScorcheaDobleBaja from  '../../../../Images/BS-corcheaDobleBaja.png';
import BSsilencio  from '../../../../Images/BS-silencio.png';
import BSsilencioCorchea  from '../../../../Images/BS-silencioCorchea.png';
import BSsilencioDoble  from '../../../../Images/BS-silencioDoble.png';
import BSsilencioSemicorchea from '../../../../Images/BS-silencioSemicorchea.png';
import BSsostenido  from '../../../../Images/BS-sostenido.png';

import BSnegraAltaNotaAbajo from '../../../../Images/BS-negraAltaNotaAbajo.png';
import BSnegraAltaNotaMedia from '../../../../Images/BS-negraAltaNotaMedia.png';
import BSnegraBajaNotaArriba from '../../../../Images/BS-negraBajaNotaArriba.png';
import BSnegraBajaNotaMedia from '../../../../Images/BS-negraBajaNotaMedia.png';
import BSsemicorcheaAltaNotaMedia from '../../../../Images/BS-semicorcheaAltaNotaMedia.png';
import BSsemicorcheaBajaNotaAbajo from '../../../../Images/BS-semicorcheaBajaNotaAbajo.png';
import BSsemicorcheaBajaNotaArriba from '../../../../Images/BS-semicorcheaBajaNotaArriba.png';
import BSsemicorcheaBajaNotaMedia from '../../../../Images/BS-semicorcheaBajaNotaMedia.png';


export const ImageFigure = {
    BS2por4: BS2por4,
    BS3por4: BS3por4,
    BS3por8:BS3por8,
    BS4por4:BS4por4,
    BSbecuadro:BSbecuadro,
    BSbemol:BSbemol,
    BSblancaAlta:BSblancaAlta,
    BSblancaBaja:BSblancaBaja,
    BScalderon:BScalderon,
    BScalderonInverso:BScalderonInverso,
    BSclaveFa:BSclaveFa,
    BSclaveSol:BSclaveSol,
    BScorcheaAlta:BScorcheaAlta,
    BScorcheaBaja:BScorcheaBaja,
    BSnegraAlta:BSnegraAlta,
    BSnegraBaja:BSnegraBaja,
    BSpuntillo:BSpuntillo,
    BSredonda:BSredonda,
    BSrepetidorFinal:BSrepetidorFinal,
    BSrepetidorInicio:BSrepetidorInicio,
    BSsemicorcheaAlta:BSsemicorcheaAlta,
    BSsemicorcheaBaja:BSsemicorcheaBaja,
    BSsemicorcheaCuaAlta:BSsemicorcheaCuaAlta,
    BSsemicorcheaCuaBaja:BSsemicorcheaCuaBaja,
    BSsilencio:BSsilencio,
    BSsilencioCorchea:BSsilencioCorchea,
    BSsilencioDoble:BSsilencioDoble,
    BSsilencioSemicorchea:BSsilencioSemicorchea,
    BSsostenido:BSsostenido,
    BScorcheaDobleAlta:BScorcheaDobleAlta,
    BScorcheaDobleBaja:BScorcheaDobleBaja,
    BSnegraAltaNotaAbajo:BSnegraAltaNotaAbajo,
    BSnegraAltaNotaMedia:BSnegraAltaNotaMedia,
    BSnegraBajaNotaArriba:BSnegraBajaNotaArriba,
    BSnegraBajaNotaMedia:BSnegraBajaNotaMedia,
    BSsemicorcheaAltaNotaMedia:BSsemicorcheaAltaNotaMedia,
    BSsemicorcheaBajaNotaAbajo:BSsemicorcheaBajaNotaAbajo,
    BSsemicorcheaBajaNotaArriba:BSsemicorcheaBajaNotaArriba,
    BSsemicorcheaBajaNotaMedia:BSsemicorcheaBajaNotaMedia,
};

const MusicalNoteInViewer=(props)=>{
    let {
        scaleCanvas,
        figure,
        figureSize
    }=props;
    let thisFigure;
    if(figure===undefined){
        thisFigure=ImageFigure.BSnegraAlta
    }else{
        thisFigure=figure.figure;
    }
    
    return <DraggableEdit scale={scaleCanvas}>
                <div className="musical-noteIn-viewer">
                    <img className="musical-note-in-viewer__img" width={figureSize+"px"}  alt="aaaa" src={thisFigure}></img>
                </div>
            </DraggableEdit>
}


export default MusicalNoteInViewer;

export const idiomas={
    castellano:"castellano",
    valenciano:"valenciano",
    ingles:"ingles"

}


export const idioms=[
    ["castellano","Castellano"],
    ["valenciano","Valenciano"],
    ["ingles","Ingles"]
]
import React, { useState } from 'react';
import Popup from "reactjs-popup";

import AtButton from '../../Atoms/At_Button/At_Button';
//import UpLoadMultimedia from '../../RecursosMultimedia/UpLoadMultimedia';
import EditorMultimediaItemsController from '../../RecursosMultimedia/EditorMultimediaItems/EditorMultimediaController';
import AtPopup from '../../Atoms/At_Popup/At_Popup';

const DTPopupEditFile =(props)=>{
     let {datos}=props;
     //<UpLoadMultimedia data={datos.file}></UpLoadMultimedia>


    return(
        
        <td className="table-body__td">
            <AtPopup open={datos.openPupup} className={datos.className} trigger={<AtButton onClick={datos.setOpenPupup}>Editar</AtButton>} >
            <EditorMultimediaItemsController data={datos.file}></EditorMultimediaItemsController>  
            </AtPopup>
        </td>
        
    );
};

export default DTPopupEditFile;
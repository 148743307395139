import React, { useState } from 'react';

const DTImage =(celda)=>{
    let image,width,height;
    
    if (celda['datos']!=null) {
        image=celda['datos'].url;
        width=celda['datos'].width;
        height=celda['datos'].height;
    }else{
        image="";
    }
        
      
       
    return(
        
        <td className="table-body__td">
            <img width={width} height={height} src={image}></img>
        </td>
        
    );
};

export default DTImage;
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import { createPortal } from 'react-dom';

import {addExerciseResult} from '../../../../Store/Actions/ExerciseResultsActions';

import './DragAndDropViewer.scss';

import {Droppable, Draggable } from 'react-beautiful-dnd';
import {getTranslatetoTransform} from '../../../../Services/CSSTratamiento';
import {exercises} from '../../../../Constantes/Exercises';


const DragAndDropViewer=(props)=>{

    let {
        question,
        dndExercise,
        viewer,
        scaleFile,
        correctExercises
    }=props;

    const dispatch=useDispatch();
    const [firstLoad, setFirstLoad] = useState(true);
   
        
    const useDraggableInPortal = () => {
        return (render) => (provided, ...args) => {
            let newProvider
            if(provided.draggableProps.style.transform!==null){
                
                let newTranslate=getTranslatetoTransform(provided.draggableProps.style.transform);
                newTranslate=[newTranslate[0]/scaleFile,newTranslate[1]/scaleFile]
                newProvider={
                    ...provided,
                    draggableProps:{
                        ...provided.draggableProps,
                        style:{
                            ...provided.draggableProps.style,
                            position:'absolute',
                            top:"0px",
                            left:"0px",
                            transform: "translate(" +newTranslate[0] +"px," +newTranslate[1]+ "px ) scale(1.5)"
                        }
                    }
                }
            }else{
                newProvider=provided;
            }
            const element = render(newProvider, ...args);
            return element;
        };
    };
    const renderDraggable=useDraggableInPortal();

    const [classDndCorrect,setClassDndCorrection]=useState("");

    let myItems;
    if(question===undefined){
        question={
            id:"-1",
            items:[
                {id:"item-1",content:"fffffff",corretContainer:"0"},
            ],
        }
    }
    if(dndExercise===undefined){
        myItems={items:[]}
    }else{
        myItems=dndExercise.dnds.filter((elem)=>{
            
            return elem.id==question.idDndDroppable;
        });

        
        myItems=myItems[0];
        
        if(myItems===undefined){
            myItems={items:[]};
        }
        
    }
       
    useEffect(()=>{
        if(correctExercises==true){
            let replyToSend={dndId:question.idDndDroppable,isCorrect:correctExercise(),typeExercise:exercises.dragAndDrop}
            dispatch(addExerciseResult(replyToSend));
        }    
    }, [correctExercises]);

   useEffect(() => {
    setClassDndCorrection("");
    if(firstLoad && myItems && myItems.edited == undefined && myItems.items && myItems.items.length > 0){
        setFirstLoad(false);
        correctExercise();
    }
   }, [myItems])
   

    const correctExercise=()=>{
        let correction=false; 
        myItems.items.map((elem)=>{
            if(elem.corretContainer==question.idDndDroppable){
                correction=true;
            }
             
        });
        
        if(correction==false){
            setClassDndCorrection("incorrect-dnd");
        }
        return correction;
    }

    

    const getImageComponent=(item)=>{
        return <div className={classDndCorrect} style={item.content} >
                     <img
                     
                     height={item.content.height}
                     width={item.content.width}
                     src={item.itemsMultimedia[0].url}
                     alt="img no disponible"
                     />    

                </div>
    }
    
    let style={
    
        width:"100%",
        height:"100%",
       
        
    };
    let classCss;
    if(viewer){
        classCss=" drag-and-drop-viewer__in-viewer";
    }else{
        classCss=" drag-and-drop-viewer__in-editor";
    }

    return(
        <div  className={"drag-and-drop-viewer "+classCss}>
          
                <Droppable droppableId={question.idDndDroppable.toString()}>
                    
                    {(provided,snapshot) => (
                        
                        <div
                            
                            ref={provided.innerRef}
                            style={style}
                            >
                            {myItems.items.map((item, index) => (
                                
                                <Draggable
                                   
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}>
                                    {renderDraggable((provided,snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            
                                            >
                                            
                                              <div  >
                                                {
                                                item.itemsMultimedia!==undefined ? 
                                                getImageComponent(item)
                                                :
                                                <div className={classDndCorrect} style={item.content} >
                                                    
                                                </div>
                                                }
                                                
                                              </div>
                                            
                                        </div>
                                    ))}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                
        </div>
    )
};


export default DragAndDropViewer;
import React,{useState} from 'react';

import './Mol_Rack.scss';


import AtButton from '../../Atoms/At_Button/At_Button';
import AtSelect from '../../Atoms/At_Select/At_Select';
import AtInputText from '../../Atoms/At_InputText/At_InputText';
import AtDivRack from '../../Atoms/At_DivRack/At_DivRack';

import {typeCss} from '../../../Constantes/TypeCss';
import {tipoRecurso} from '../../../Constantes/TiposRecursos';
import {quedarteConLaDiferenciaFinalString} from '../../../Services/OperacionesStrings';

import ImageTemplete from '../../../ImagenesPrueba/home.png';
import IvooxTemplete from '../../../ImagenesPrueba/ivoox.jpg';

import AudioImg from '../../../Images/Recurso-Audio.png';
import PdfImg from '../../../Images/Recurso-PDF.png';
import SpotifyImg from '../../../Images/Recurso-Spotify.png';
import VimeoImg from '../../../Images/Recurso-Vimeo.png';
import YoutubeImg from '../../../Images/Recurso-Youtube.png';
import BandcampImg from '../../../Images/Recurso-Bandcamp.png';
import { ImageIcon } from '../../Atoms/At_Image/At_Image';


const Mol_Rack =(props)=>{
    let {
        data,
        paginate,
        onClickChangePage,
        onChangeFilter,
        onClickDivRack,
        seeker,
        valuesFilterSelect,
    }=props;

    

    let first_page_url=quedarteConLaDiferenciaFinalString(paginate.path,paginate.first_page_url);
    let prev_page_url=quedarteConLaDiferenciaFinalString(paginate.path,paginate.prev_page_url);
    let next_page_url=quedarteConLaDiferenciaFinalString(paginate.path,paginate.next_page_url);
    let last_page_url=quedarteConLaDiferenciaFinalString(paginate.path,paginate.last_page_url);
    
    const getImgMultimedia=(elem)=>{

        switch (elem.tipo) {
            case tipoRecurso.audios:
               return AudioImg;
    
            case tipoRecurso.imagen:
                if(elem.items[0]!= null)
                    return elem.items[0].url;
                break;
            case tipoRecurso.svg:
           
            return elem.items[0].url;

            case tipoRecurso.ivoox:
                return IvooxTemplete;

            case tipoRecurso.pdf:
                return PdfImg

            case tipoRecurso.spotify:
                return SpotifyImg;

            case tipoRecurso.vimeo:
                return VimeoImg;
            case tipoRecurso.bandcamp:
                return BandcampImg;

            case tipoRecurso.youtube:
                return YoutubeImg;

            default:
                return ImageTemplete;
        }

    }
    const [valueSelect,setValueSelect]=useState(null);
    const [valueInputText,setValueInputText]=useState("");
    //let valueSelect=null,valueInputText="";

    const onChangeFilterSelect=(value)=>{
        setValueSelect(value);
        onChangeFilter(value,valueInputText)

    }

    const onChangeFilterInputText=(value)=>{
        setValueInputText(value);
        onChangeFilter(valueSelect,value)

    }
    

     return(
         <div className="mol-rack">
             {seeker===true ? 
                <div className="mol-rack__header">

                    <div className="mol-rack__header__items mol-rack__header__select">
                        <AtSelect onChange={event=>onChangeFilterSelect(event.target.value)}  values={valuesFilterSelect}></AtSelect>
                    </div>

                    <div className="mol-rack__header__items mol-rack__header__input-text">
                        <AtInputText onChange={event=>onChangeFilterInputText(event.target.value)}></AtInputText>
                    </div>

                    <div className="mol-rack__header__items mol-rack__header__button">
                        <AtButton >Añadir nuevo</AtButton>
                    </div>

                </div>
            :""}
             

             <div className="mol-rack__body">
                {data.map((elem)=>{
                    
                    return <AtDivRack onClick={()=>{onClickDivRack(elem)}} image={getImgMultimedia(elem)}>{elem.nombre}</AtDivRack>
                })}
             </div>

             <div  className="mol-rack__footer">

                <div className="mol-rack__footer__item">

                    <AtButton onClick={()=>{onClickChangePage(first_page_url)}} 
                        type={typeCss.tertiary} widthImg="10px" heightImg="11.5px" image={ImageIcon.chevronLeftDouble}>Primera</AtButton>

                    <AtButton onClick={()=>{onClickChangePage(prev_page_url)}} 
                        type={typeCss.tertiary} widthImg="6.5px" heightImg="11.5px" image={ImageIcon.chevronLeft}>Anterior</AtButton>

                </div>

            <label className="mol-rack__footer__item mol-rack__footer__item--pages">{paginate.current_page}</label>

                <div className="mol-rack__footer__item">

                    <AtButton onClick={()=>{onClickChangePage(next_page_url)}} 
                        type={typeCss.tertiary} imageRight={true} widthImg="6.5px" heightImg="11.5px" image={ImageIcon.chevronRight}>Siguiente</AtButton>

                    <AtButton onClick={()=>{onClickChangePage(last_page_url)}} 
                        type={typeCss.tertiary} imageRight={true} widthImg="10px" heightImg="11.5px" image={ImageIcon.chevronRightDouble}>Última</AtButton>

                </div>

             </div>
         </div>
     )
}


export default Mol_Rack;
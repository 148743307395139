import React, { useState } from 'react';
import { Link } from "react-router-dom";

const DTEnlace =(props)=>{
     let {datos}=props;
               
    let nombreEnlace=datos.nombreEnlace;
    let url=datos.url;

        

    return(
        
        <td className="table-body__td">
            <Link  to={url} >{nombreEnlace}</Link>
        </td>
        
    );
};

export default DTEnlace;
import React,{useState} from 'react';
import ReactDOM from 'react-dom';

import './At_Popup.scss';

const At_Popup=(props)=>{
    let {
        children,
        trigger,
        occupyAll,
        noCloseInClickOut,
        openDelay,
        transparent,
        contentFit,
        classNameOccupyAll,
        activeMinimize,
    } =props;

    const [opened,setOpened]=useState(false);
    const [minimize, setMinimized] = useState(false);
    
    const showPopUP=()=>{
        if(openDelay===undefined){

            setOpened(true);

        }else{

            setTimeout(function() {
                setOpened(true);
              }, openDelay);

        }
    }

    const hidePopUP=()=>{
        
        setOpened(false); 
    }

    const onClickOutPopUp=()=>{
        if(!noCloseInClickOut){
            setOpened(false); 
        }
    }

    const onClickMinimize = () => {
        setMinimized(!minimize);
    }

    return (
        <div className={{occupyAll} ? "at-popup__occupyAll " : "" }>
            <div onClick={showPopUP} className="at-popup__trigger">
                {trigger}
            </div>
            { opened === true && minimize === false &&
            ReactDOM.createPortal(
                <div id="at-popup-out" className={"at-popup__modal"} onClick={onClickOutPopUp}>
                    <div 
                        className={`at-popup__modal-content 
                            ${transparent ? 'at-popup__modal-content--transparent' : ''}
                            ${contentFit ? 'at-popup__modal-content--content-fit': ''}
                            
                        `+" "+classNameOccupyAll} 
                        onClick={event => event.stopPropagation()}
                    > 
                        {React.Children.map(children, (child, i) => {
                            return React.cloneElement(child, {
                                finished: hidePopUP
                              })
                        })}
                        {activeMinimize === true &&
                            <div className="at-popup__minimize-button at-popup__minimize-button--shadow" onClick={onClickMinimize}></div>
                        }
                    </div>
                </div>,
            document.getElementById('modal'))}
            { opened === true && minimize === true &&
            ReactDOM.createPortal(
                <div 
                    className="at-popup__minimize"
                    onClick={event => event.stopPropagation()}
                > 
                    {React.Children.map(children, (child, i) => {
                        return React.cloneElement(child, {
                            finished: hidePopUP
                            })
                    })}
                    <div className="at-popup__minimize-button" onClick={onClickMinimize}></div>
                </div>
                ,
            document.getElementById('modal'))}
        </div>
    )
}


export default At_Popup;
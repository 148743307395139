import React from 'react';
import {Link} from "react-router-dom";


import './MyLink.scss';

const MyLink =(props)=>{
    let {
        text,
        to
    }=props

    return (
    <Link className="link" to={to}>{text}</Link>
    )
}

export default MyLink;
import React,{useState} from 'react';

import './At_SearchSelector.scss';

const At_SearchSelector =(props)=>{
    let {
        values,
        onSelect
    }=props

    const [valueSelect,setValueSelect]=useState([]);
    const [showSelect,setShowSelect]=useState("deactivate");
    let canCloseSelect=true;

    const onFocusOpenSelect=()=>{

        setValueSelect(values);
        setShowSelect("");
    }

    const onLostPointerCaptureCloseSelect=()=>{
        if(canCloseSelect){
            setValueSelect([]);
            setShowSelect("deactivate");
        }
        
    }

    const onChangeSearchOnSelect=(text)=>{
       
        let newValues=values.filter((elem)=>{
            return String(elem.text).includes(text);
        });

        setValueSelect(newValues);
    }

    
    const onMouseOverCantCloseSelect=()=>{
        canCloseSelect=false;
        
    }
    const onMouseOverCanCloseSelect=()=>{
        canCloseSelect=true;
       
    }
    return (
        <div className="at-search-selector">
            <input type="text" 
            onFocus={onFocusOpenSelect}  
            onBlur={onLostPointerCaptureCloseSelect}
            onChange={event=>onChangeSearchOnSelect(event.target.value)}
            ></input>

            <div className={"at-search-selector__values-container "+ showSelect}
            onMouseOver={onMouseOverCantCloseSelect}
            onMouseLeave={onMouseOverCanCloseSelect}
            >
                {valueSelect.map((elem)=>{
                    return (
                        <div className="at-search-selector__container-item">
                            <button value={elem.value}
                            onBlur={onLostPointerCaptureCloseSelect}
                            onClick={event=>onSelect(event.target.value)}>{elem.text} </button>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}


export default At_SearchSelector;
import React,{useState} from 'react';
import {useDispatch} from 'react-redux';

import './InsertAnimationIntoElement.scss';

import {insertAnimation} from '../../../Store/Actions/EditorElementosActions';

import AtInputText from '../../Atoms/At_InputText/At_InputText'
import AtTitle from '../../Atoms/At_Title/At_Title';
import AtSelect from '../../Atoms/At_Select/At_Select';
import AtButton from '../../Atoms/At_Button/At_Button';


import {eventsAnimation,animation,animationArray} from '../../../Constantes/Animation';

const InsertAnimationIntoElement=(props)=>{

        const {
            idElement,
            closePopup

        }=props
        
    const dispatch=useDispatch();

    const [eventAnimation,setEventAnimation]=useState(eventsAnimation.onClickEvent);
    const [animationType,setAnimationType]=useState(animation.shake);

    const onClickChangeAnimationEvent=(newEventAnimation)=>{
        setEventAnimation(newEventAnimation);
    }

    const onClickChangeAnimationType=(newAnimation)=>{
        setAnimationType(newAnimation);
    }

    const onClickInsertAnimationIntoElement=()=>{
        

        let newAnimation={
            event:eventAnimation,
            animation:animationType
        }
        dispatch(insertAnimation(idElement,newAnimation));

        
        if(closePopup!==undefined){
            
            closePopup();
        }
        
    }

    
    return(

        <div className="insert-animation-into-element">

            <AtTitle>Evento</AtTitle>
            <div className="insert-animation-into-element__events">
                <div className="insert-animation-into-element__events-items">
                    
                    <AtInputText type="radio" name="eventAnimation" defaultChecked="true" value={eventsAnimation.onClickEvent} 
                    onClick={event=>onClickChangeAnimationEvent(event.target.value)} /> 
                    <AtTitle> Click</AtTitle>
                </div>
                <div className="insert-animation-into-element__events-items">
                    <AtInputText type="radio" name="eventAnimation"  value={eventsAnimation.alwaisEvent} 
                    onClick={event=>onClickChangeAnimationEvent(event.target.value)} /> 
                    <AtTitle> Siempre</AtTitle>
                </div>
                <div className="insert-animation-into-element__events-items">
                    <AtInputText type="radio" name="eventAnimation"  value={eventsAnimation.hoverEvent} 
                    onClick={event=>onClickChangeAnimationEvent(event.target.value)} /> 
                    <AtTitle> Cursor encima</AtTitle>
                </div>
            </div>

            <AtTitle>Animacion</AtTitle>

            <AtSelect className="insert-animation-into-element__events" values={animationArray} onChange={event=>onClickChangeAnimationType(event.target.value)}></AtSelect>

            <AtButton className="insert-animation-into-element__button-add" onClick={onClickInsertAnimationIntoElement}>Asignar</AtButton>
            
        </div>

    )
}


export default InsertAnimationIntoElement;
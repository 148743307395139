import React,{useState,useEffect} from 'react';
import {tipoRecurso,multimediaTypes} from '../../Constantes/TiposRecursos.js';
import AtSelect from '../Atoms/At_Select/At_Select.jsx';
import AtInputText from '../Atoms/At_InputText/At_InputText.jsx';
import AtButton from '../Atoms/At_Button/At_Button.jsx';
import { typeCss } from '../../Constantes/TypeCss.js';
import UpLoadBox from './UploadMultimediaItems/UpLoadBox.jsx';
import Uppy from '@uppy/core';
import XHRUpload  from '@uppy/xhr-upload';
import { ruta } from '../../Constantes/Rutas.js';
import { idioms, idiomas } from '../../Constantes/idiomas.js';
import { ImageIcon } from '../Atoms/At_Image/At_Image.jsx';
import AtTitle from '../Atoms/At_Title/At_Title.jsx';
import { httpPost } from '../../Store/HttpCalls.jsx';

const FormUploadMultimedia =(props)=> {

    const {finished, datos, updateMultimediaInTable} = props;
    const [selectedTipoRecurso, setSelectedTipoRecurso] = useState();
    const [modoNuevo, setModoNuevo] = useState();
    const [items, setItems] = useState();
    const [nombreRecurso, setNombreRecurso] = useState();
    let uploading = false;

    const tipoConLinks = [
        tipoRecurso.bandcamp,
        tipoRecurso.spotify,
        tipoRecurso.vimeo,
        tipoRecurso.youtube
    ];
    const tipoConSubidaDeArchivos = [
        tipoRecurso.audios,
        tipoRecurso.imagen,
        tipoRecurso.pdf,
        tipoRecurso.svg
    ];

    const idDenerator = longitud => {
        // Nota: no uses esta función para cosas criptográficamente seguras. 
        const banco = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let aleatoria = "";
        for (let i = 0; i < longitud; i++) {
            // Lee más sobre la elección del índice aleatorio en:
            // https://parzibyte.me/blog/2021/11/30/elemento-aleatorio-arreglo-javascript/
            aleatoria += banco.charAt(Math.floor(Math.random() * banco.length));
        }
        return aleatoria;
    };

    const uppy = Uppy({
        autoProceed: false,
        allowMultipleUploads: true,
        allowDuplicates: true
    });

    uppy.use(XHRUpload, {
        endpoint: process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_API_ENDPOINT + ruta.subidaArchivos,
        formData: true,
        fieldName: ['name1','name2'],
        bundle:true
    });

    uppy.on('upload', (result) => {
    //console.log("upload",result);
    });

    uppy.on('upload-error', (file, error) => {

    console.error('error with file:', file.id);
    console.error('error message:', error);

    });
    
    uppy.on('file-added', (file) => {
        if(!uploading){
            // Añadimos el archivo subido a la coleccion de items cuando este ha sido cargado en memoria para poder hacer la previa
            const reader = new FileReader();  
            reader.readAsDataURL(file.data);
            reader.onload=(e)=>{
                const itemsToModify = items;
                itemsToModify.push({
                    nombre: file.name,
                    id: idDenerator(20),
                    url: e.target.result,
                    language: idiomas.castellano,
                    file: file
                });
                setItems([...itemsToModify]);
            }
        } 
    });

    uppy.on('upload-success', (file, result) => {
        console.log("upload-success");
    });

    uppy.on('complete', (result) => {
         console.log("completado");
    });

    const onClickCancel=()=>{
        finished();
    };

    const onChangeTipoRecurso = (newTipoRecurso) => {
        setSelectedTipoRecurso(newTipoRecurso);
    }

    const onClickDeleteItem = (index) => {
        const itemsToModify = items;
        itemsToModify.splice(index,1);
        setItems([...itemsToModify]);
    };

    const onChangeItemLanguage = (index, newLenguage) => {
        const itemsToModify = items;
        itemsToModify[index].language = newLenguage;
        setItems([...itemsToModify]);
    };

    const onClickAddNewLink = () => {
        const itemsToModify = items;
        const newLink = {
            link: '',
            language: idiomas.castellano,
            id: idDenerator(20),
        }
        itemsToModify.push(newLink);
        setItems([...itemsToModify]);
    }

    const nombreItem = (item) => {
        return isNaN(item.id) ? item.nombre : item.url.substring(item.url.lastIndexOf('/')+1, item.url.lastIndexOf('/')+20 );
    };

    const setLink = (index, value) => {
        const itemsToModify = items;
        itemsToModify[index].link = value;
        setItems([...itemsToModify]);
    };

    const initModoNuevo = () => {
        setSelectedTipoRecurso(tipoRecurso.imagen);
        setModoNuevo(true);
        setItems([]);
    };

    const initModoEdicion = () => {
        setSelectedTipoRecurso(datos.file.tipo);
        setModoNuevo(false);
        setItems(datos.file.items);
        setNombreRecurso(datos.file.nombre);
    };

    const envioArchivos = () => {
        try{
            let languages = {};
            let itemsConArchivosAEnviar = [];
            items.forEach((item)=>{
                if(item.file){
                    uppy.addFile(item.file);
                    itemsConArchivosAEnviar.push(item);
                }      
            });
            let paqueteEnvio=uppy.getFiles();
            
            for(let i=0;i<paqueteEnvio.length;i++){
                uppy.setFileState(paqueteEnvio[i].id, {
                    xhrUpload: { fieldName: 'archivo'+i }
                });
                languages={...languages,["languageFile"+i]:itemsConArchivosAEnviar[i].language};  
            }

            let metadata = {
                nameMultimedia:nombreRecurso,
                typeMultimedia: selectedTipoRecurso,
                numberOfMUltimedia:paqueteEnvio.length,
                ...languages
            };

            if(datos){
                metadata.id = datos.file.id;
                items.forEach((item) =>{
                    metadata["itemId"+item.id] = item.id;
                    metadata["language"+item.id] = item.language;
                });
            }

            uppy.setMeta(metadata);
            
            paqueteEnvio = uppy.getFiles();
            if(paqueteEnvio.length == 0){
                httpPost(ruta.subidaArchivos, metadata).then((ficha)=>{
                
                },(e)=>{
                    alert(e.message);
                    console.log(e);
                });
            }else{
                uppy.upload();
            }
            
        }catch(e){
            alert(e.message);
            console.log(e);
        }
    };

    const envioLinks = () => {
        const newMultimedia = {
            nameMultimedia:nombreRecurso,
            typeMultimedia:selectedTipoRecurso,
            links: items.filter((item) => !item.url),
        };
        if(datos){
            newMultimedia.id = datos.file.id;
        }
        httpPost(ruta.upLoadLinkMultimedia,newMultimedia).then((ficha)=>{
            
        },(e)=>{
            alert(e.message);
            console.log(e);
        });
    };

    const checkFormOk = () => {
        let tipoDeItem = "";
        let itemsACheckear;

        if(!nombreRecurso){
            alert("Debes escribir un nombre de recurso");
            return false;
        }
        if(tipoConSubidaDeArchivos.includes(selectedTipoRecurso)){
            tipoDeItem = "archivo";
            itemsACheckear = items.filter((item) => item.url);
        }
        if(tipoConLinks.includes(selectedTipoRecurso)){
            tipoDeItem = "link";
            itemsACheckear = items.filter((item) => !item.url);
            if(itemsACheckear.filter((item) => {
                return item.link.trim() == ''
            }).length > 0){
                alert("Los links no pueden estar vacíos");
                return false;
            }
        }
        if(itemsACheckear.length == 0){
            alert("Debes tener al menos un "+tipoDeItem);
            return false;
        }
        const castellanoOk = itemsACheckear.filter((item) => item.language == idiomas.castellano);
        if(castellanoOk.length == 0){
            alert("Uno de los links debe estar en castellano");
            return false;
        }
        let fail = false;
        Object.keys(idiomas).forEach(idioma => {
            const itemsConIdioma = itemsACheckear.filter((item) => item.language == idiomas[idioma]);
            if(itemsConIdioma.length > 1){
                alert("No puedes tener mas de un elemento en "+ idiomas[idioma]);
                fail = true;
            }
        });
        
        return !fail;
    };

    const handlerOnClickGuardar = () => {
        let payload;
        const formOK = checkFormOk();
        if(formOK){
            uploading =true;
            if(tipoConSubidaDeArchivos.includes(selectedTipoRecurso)){
                envioArchivos();
            }else{
                envioLinks();
            }
            uploading =false;
            finished();
            updateMultimediaInTable();
        } 
    };

    useEffect(() => {
        if(datos){
            initModoEdicion();
        }else{
            initModoNuevo();
        }
    },[datos]);


    return (
        <div className="up-load-multimedia">
            <div className="up-load-multimedia__header">
                {modoNuevo == true && 
                    <AtSelect fontSize={11} fontColor={"#2E4467"}   widthSelect={210} values={multimediaTypes} onChange={event=>onChangeTipoRecurso(event.target.value)}></AtSelect>  
                }
            </div>
          
            <div>
                {tipoConSubidaDeArchivos.includes(selectedTipoRecurso) && 
                    <div className="up-load-box">
                        <UpLoadBox  uppy={uppy}></UpLoadBox>
                    </div>
                }
                
                <AtInputText width="442" type="text" value={nombreRecurso} placeholder="Nombre recurso" onChange={event=>setNombreRecurso(event.target.value)}></AtInputText>
                {tipoConLinks.includes(selectedTipoRecurso) && 
                    <AtButton type={typeCss.secondary} onClick={onClickAddNewLink}>Insertar Nueva URL</AtButton>
                }
                {items && items.length > 0 && 
                    <div className="links">   
                        <div className="no-links-multimedia__at-title">
                            <AtTitle fontSize="13"  fonstColor="#2E4467" >Recursos subidos</AtTitle>
                        </div>
                        {tipoConSubidaDeArchivos.includes(selectedTipoRecurso) && 
                            <div className="no-links-multimedia no-links-multimedia__items-container">
                                {items.map((item, index) => {
                                    if(item.url)
                                        return <div className="no-links-multimedia__items">           
                                            <img className="no-links-multimedia__items__img" src={item.url}></img> 
                                            <label className="no-links-multimedia__item no-links-multimedia__item__label">{nombreItem(item)}</label>
                                            <div className="no-links-multimedia__item no-links-multimedia__item__select">
                                                <AtSelect onChange={event=>onChangeItemLanguage(index, event.target.value)} defaultValue={item.language} values={idioms} widthSelect="195"></AtSelect>
                                            </div>
                                            <div className="no-links-multimedia__item">
                                                <AtButton widthImg="13" heightImg="16" image={ImageIcon.delete} type={typeCss.tertiary} onClick={()=>{onClickDeleteItem(index)}}></AtButton>
                                            </div>
                                        </div>
                                })}
                            </div>
                        }
                        {tipoConLinks.includes(selectedTipoRecurso) && 
                            <div className="links-multimedia">
                                {items.map((item, index) => {
                                    if(!item.url)
                                    return <div className="links-multimedia__items">
                                            <AtInputText className="links-multimedia__input"  placeholder="Añade aquí el nuevo link" onChange={event=>setLink(index, event.target.value)} value={item.link} type="text"></AtInputText>
                                            <AtSelect onChange={event=>onChangeItemLanguage(index, event.target.value)} defaultValue={item.language} values={idioms} ></AtSelect>
                                            <AtButton image={ImageIcon.delete} widthImg="13px" heightImg="16px" onClick={() => onClickDeleteItem(index)} type={typeCss.tertiary}></AtButton>
                                        </div>
                                })}
                            </div>
                        }
                    </div>
                }
            </div>
  
          <div className="up-load-multimedia__footer">
              <div className="up-load-multimedia__footer--first">
                  <AtButton onClick={onClickCancel} type={typeCss.secondary}  >Cancelar</AtButton>
              </div>
              <div>
                  <AtButton onClick={() => handlerOnClickGuardar()} >Guardar</AtButton>
              </div>
          </div>
  
        </div>
    );
};


export default FormUploadMultimedia;
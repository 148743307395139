import React from 'react';

import {idiomas} from '../../../../../Constantes/idiomas';


const QuestionFF =(props)=>{
    let {
        exercise,
        language,
        viewer
    }=props;


    let questionDataByIdiom;
   
    
    questionDataByIdiom=exercise.questionData.filter((elem)=>{
            return elem.language===language
        });
    
     
        if(questionDataByIdiom[0]===undefined){
            questionDataByIdiom=exercise.questionData.filter((elem)=>{
                return elem.language===idiomas.castellano;
            });
        }
    
        questionDataByIdiom=questionDataByIdiom[0];

        


    return(
        <p className="p-question" style={exercise.fontStyle} dangerouslySetInnerHTML={{__html: questionDataByIdiom.question}} />
    
    )
}


export default QuestionFF;
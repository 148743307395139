import React, { useState } from 'react';

import {quedarteConLaDiferenciaFinalString} from '../../Services/OperacionesStrings';

import TemplateImg from '../../ImagenesPrueba/home.png';

const Paginador =(props)=>{
    let {
        paginacion
    }=props
        
        //total paginas items por pagina
        
        let totalItems;
        let itemPorPagina;

        let paginaActual;
        let ultimaPagina;

        //paginas url
        let urlPrimeraPagina;
        let urlAnteriorPagina
        let urlSiguientePagina;
        let urlUltimaPagina;

       

        let from;
        let path
        let to

        //funciones
        let onClickCambioPagina;

        if(paginacion!=null){
            paginaActual=paginacion.current_page;
            path=paginacion.path;
            
            urlPrimeraPagina=quedarteConLaDiferenciaFinalString(path,paginacion.first_page_url);
            urlAnteriorPagina=quedarteConLaDiferenciaFinalString(path,paginacion.prev_page_url);
            urlSiguientePagina=quedarteConLaDiferenciaFinalString(path,paginacion.next_page_url);
            urlUltimaPagina=quedarteConLaDiferenciaFinalString(path,paginacion.last_page_url);

            ultimaPagina=paginacion.last_page;
            from=paginacion.from;
            
            itemPorPagina=paginacion.per_page;
            to=paginacion.to;
            totalItems=paginacion.total;

            onClickCambioPagina=paginacion.onClickCambioPagina;


            
        }

        const onClickPrevPage=()=>{
            
            if(paginaActual!=1){
                
                onClickCambioPagina(urlAnteriorPagina);
            }
        }

        const onClickNextPage=()=>{
            
            if(paginaActual!=ultimaPagina){
                onClickCambioPagina(urlSiguientePagina);
            }
        }
        
    return(
        
        <div className="footer-paginator">
            <div>
                <img width="11px" height="11px" src={TemplateImg}></img>
                <button className="footer-button footer-button__left" onClick={()=>{onClickCambioPagina(urlPrimeraPagina)}}>Primera</button>
            </div>
            <div>
                <img width="11px" height="11px" src={TemplateImg}></img>
                <button className="footer-button footer-button__left" onClick={onClickPrevPage}>Anterior</button>
            </div>
            <div>
                <label>{paginaActual}</label>
            </div>
            <div>
                <button className="footer-button footer-button__right" onClick={onClickNextPage}>Siguiente</button>
                <img width="11px" height="11px" src={TemplateImg}></img>
            </div>
            <div>
                <button className="footer-button footer-button__right" onClick={()=>{onClickCambioPagina(urlUltimaPagina)}}>Ultima</button>
                <img width="11px" height="11px" src={TemplateImg}></img>
            </div>
        </div>
        
    );
};

export default Paginador;
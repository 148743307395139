import { types } from '../Reducers/RecursosReducer';


export const createRecursos = (values) => ({
  type: types.POST,
  payload: values,
});

export const updateRecursos = (values) => ({
  type: types.PUT,
  payload: values,
});

export const removeRecursos = (id) => ({
  type: types.DELETE,
  id,
});

export const reloadRecursos = (items) => ({
  type: types.RELOAD,
  payload: items,
});

export const resetRecursos = () => ({
  type: types.RESET,
});
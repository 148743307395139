import React from 'react';

import DragAndDropViewer from '../Exercises/DragAndDrop/DragAndDropViewer';
import ColoringSvgViewer from '../Exercises/ColoringSvg/ColoringSvgViewer';

import QuestionFF from '../Exercises/FillableField/ElementsViewer/QuestionFF';
import AnswerFF from '../Exercises/FillableField/ElementsViewer/AnswerFF';

import QuestionTS from '../Exercises/TestSelect/ElementsViewer/QuestionTS';
import AnswerTS from '../Exercises/TestSelect/ElementsViewer/AnswerTS';

import QuestionIT from '../Exercises/ImageTest/ElementsViewer/QuestionIT';
import AnswerIT from '../Exercises/ImageTest/ElementsViewer/AnswerIT';

import BasicStaffViewer from '../Exercises/BasicStaff/BasicStaffViewer';


import {exercises,typePartQorA} from '../../../Constantes/Exercises';

const ElementExercise=(props)=>{
    let {
        exercise,
        language,
        viewer,
        idElement,
        dndExercise,
        styleElement,
        scaleFile,
        correctExercises,
        answerExercises
        
    }=props;
    
    
  
    if(viewer){
        switch (exercise.typeExercise) {
            case exercises.textSelect:
                if(typePartQorA.question==exercise.part){
                    
                    return <QuestionTS exercise={exercise} language={language} viewer={viewer}></QuestionTS>;
                    
                }
                if(typePartQorA.answer==exercise.part){
                    return <AnswerTS answerExercises={answerExercises} correctExercises={correctExercises} styleElement={styleElement} exercise={exercise} language={language} viewer={viewer}></AnswerTS>;
                }

                return "Ejercicio no disponible";

            case exercises.imagesTest:
                
                if(typePartQorA.question==exercise.part){
                    
                    return <QuestionIT styleElement={styleElement} exercise={exercise} language={language} viewer={viewer}></QuestionIT>;
                }
                if(typePartQorA.answer==exercise.part){
                    return <AnswerIT answerExercises={answerExercises} correctExercises={correctExercises} styleElement={styleElement} exercise={exercise} language={language} viewer={viewer}></AnswerIT>;
                }

                return "Ejercicio no disponible";
                        

            case exercises.fillableField:

                if(typePartQorA.question==exercise.part){
                    return <QuestionFF exercise={exercise} language={language} viewer={viewer}></QuestionFF>;
                }
                if(typePartQorA.answer==exercise.part){
                    
                    return <AnswerFF answerExercises={answerExercises} correctExercises={correctExercises} styleElement={styleElement} exercise={exercise} language={language} viewer={viewer}></AnswerFF>;
                }

                return "Ejercicio no disponible";
                      
            case exercises.dragAndDrop:
              
                return <DragAndDropViewer correctExercises={correctExercises} scaleFile={scaleFile} viewer={viewer} question={exercise} dndExercise={dndExercise}></DragAndDropViewer>
                
            case exercises.colorItem:

                return <ColoringSvgViewer inViewer question={exercise} language={language} idElement={idElement}></ColoringSvgViewer>


            case exercises.basicStaff:

                return <BasicStaffViewer styleElement={styleElement} scaleFile={scaleFile}></BasicStaffViewer>

            default:
                break;

            
        }
    }else{
        switch (exercise.typeExercise) {
            case exercises.textSelect:

                if(typePartQorA.question==exercise.part){
                    return <QuestionTS exercise={exercise} language={language} viewer={viewer}></QuestionTS>;
                }
                if(typePartQorA.answer==exercise.part){
                    return <AnswerTS styleElement={styleElement} exercise={exercise} language={language} viewer={viewer}></AnswerTS>;
                }

                return "Ejercicio no disponible";

            case exercises.imagesTest:
                
                if(typePartQorA.question==exercise.part){
                    
                    return <QuestionIT styleElement={styleElement} exercise={exercise} language={language} viewer={viewer}></QuestionIT>;
                }
                if(typePartQorA.answer==exercise.part){
                    
                    return <AnswerIT styleElement={styleElement} exercise={exercise} language={language} viewer={viewer}></AnswerIT>;
                }

                return "Ejercicio no disponible";

            case exercises.fillableField:

                if(typePartQorA.question==exercise.part){
                    return <QuestionFF exercise={exercise} language={language} viewer={viewer}></QuestionFF>;
                }
                if(typePartQorA.answer==exercise.part){
                    return <AnswerFF styleElement={styleElement} exercise={exercise} language={language} viewer={viewer}></AnswerFF>;
                }

                return "Ejercicio no disponible";

            case exercises.dragAndDrop:
                
                return <DragAndDropViewer viewer={viewer} question={exercise} dndExercise={dndExercise} ></DragAndDropViewer>
            
            case exercises.colorItem:
                
                return <ColoringSvgViewer question={exercise} language={language} idElement={idElement}></ColoringSvgViewer>
        
            case exercises.basicStaff:

                return <BasicStaffViewer styleElement={styleElement} scaleFile={scaleFile}></BasicStaffViewer>

            
            default:
                break;
        }
    }

    
}

export default ElementExercise;
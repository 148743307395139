export const types = {
    ADD_FIGURE: 'ADD_FIGURE',
    UNDO: 'UNDO',
    DELETE_ALL_FIGURE:"DELETE_ALL_FIGURE"
  };

  const FichasEditorElementosReducer = (state = [], action) => {
    
    switch (action.type) {
      case types.ADD_FIGURE:
        
        return [...state,action.figure];

      case types.UNDO:
        let contador=state.length;
        let newState=state.filter((elem)=>{
          contador--;
          return contador!=0;
        });
        
        return newState;
      
      case types.DELETE_ALL_FIGURE:
        return [];

      default:
        return state;
    }
  };

  export default FichasEditorElementosReducer;
import React, { useState, useCallback, useEffect }  from "react";
import { ReactPainter } from 'react-painter';
import {getScaleToTransform} from '../../../Services/CSSTratamiento';
import Toolbar from './toolbar';
import PaintButton from '../../../Images/Paint.png';
import NotPaintButton from '../../../Images/NotPaint.png';

import './painter.scss';

const Painter=(props)=>{
    let {
        lienzo,
        styleFile,
        primerCanvas,
        enable
    }=props;
    document.addEventListener('contextmenu', event => event.preventDefault());

    let scaleCanvas=getScaleToTransform(styleFile.transform);

    const [widthScreen,setWidthScreen]=useState(lienzo[0]*scaleCanvas);
    const [heightScreen,setHeightScreen]=useState(lienzo[1]*scaleCanvas);
    const currentWidth=5;
    const currentColor="#000";

    useEffect(() => {

    }, []);

   useEffect(() => {
       
        setWidthScreen(lienzo[0]*scaleCanvas);
        setHeightScreen(lienzo[1]*scaleCanvas);
        cleanCanvas();
   }, [scaleCanvas]);
    
   
    const [borrador,setBorrador]=useState([1]);
    const cleanCanvas=()=>{
        setBorrador([borrador[0]+1]);
    }
    

    return(
        <div>
            
            { !primerCanvas ? 
            borrador.map((elem)=>{
               return <ReactPainter
                key={"painter-cambas-"+elem}
                width={widthScreen}
                height={heightScreen}
                initialLineWidth={currentWidth}
                initialColor={currentColor}
                onSave={blob => console.log(blob)}
                render={({canvas,setColor,setLineWidth }) => (
                    <div className={enable ? "" : "deactivate"}>
                        <Toolbar
                            currentWidth={currentWidth}
                            currentColor={currentColor}
                            handleWidth={setLineWidth}
                            handleColor={setColor}
                            handleClear={cleanCanvas}
                            />
                        <div className="painter-canvas">{canvas}</div>
                    </div>
                )}
            />
            })
            
             : ""}
            
        </div>
        
    )
    
}

export default Painter;

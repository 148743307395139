import React, { useEffect, useState } from 'react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './At_EnrichedText.scss';

export const toolbarTypes={
  boldItalic:['bold', 'italic'],
  none:[],
}

export const deleteHtml=(text)=>{
  if(text!=null){
    text=text.replaceAll('<p>','');
    text=text.replaceAll('</p>','');
    text=text.replaceAll('<strong>','');
    text=text.replaceAll('</strong>','');
    text=text.replaceAll('<i>','');
    text=text.replaceAll('</i>','');
  }else{
    text="";
  }
  

  
  return text;
}

const At_EnrichedText = (props) => {
  const {
    value,
    onChange,
    toolbar,
    autofocus,
    height,
  } = props;
 
  
  let thisToolbar=toolbar;
  if(toolbar===undefined){
    thisToolbar=toolbarTypes.none;
  }
  
  
  const onChangeText=(event, editor) => {
    
    const data = editor.getData();
    onChange(data);
  }

  return (
    <div className="enriched-text">
    <CKEditor
      editor={ClassicEditor}
      data={value}
      
      config={
        {
          toolbar: thisToolbar,
        }
      }
      onChange={onChangeText}
      onReady={(editor) =>{
        if(autofocus){
          editor.focus();
        }

      }}
    />
    </div>
  );
};



export default At_EnrichedText;
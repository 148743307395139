export const obtenerGradosRotacion=(cadena)=>{
    let cadenaFinal;
    cadena=cadena.split(" ");
    cadenaFinal=Number(cadena[1].substring(0,cadena[1].length-3));
    return cadenaFinal;
  };
  
export const quitarPxFinal=(cadena)=>{
  
    if(cadena.length<=2){
      return 0;
    }
    cadena=cadena.substring(0,cadena.length-2);
    return Number(cadena);
  
  };

export const desglosarBorder=(border)=>{
  
  border=border.split(" ");
  border[0]=quitarPxFinal(border[0]);
  
  return border;
  
};

export const getWidthAndHeightWhitOutPx=(style)=>{
  if(style!=null){
    return [quitarPxFinal(style.width),quitarPxFinal(style.height)]
  }
    return [0,0]
}

export const getScaleToTransform=(transform)=>{
  transform=transform.replaceAll(" ","");
  

  transform=transform.split(")");
  

  transform=transform.map((elem)=>{
    return elem.split("(");
  });
  

  let scale=transform.filter((elem)=>{
    
      return elem[0]=="scale"
    
  });

 
  return scale[0][1];
}


export const getTranslatetoTransform=(transform)=>{
  transform=transform.replaceAll(" ","");
  

  transform=transform.split(")");
  

  transform=transform.map((elem)=>{
    return elem.split("(");
  });
  

  let translate=transform.filter((elem)=>{
    
      return elem[0]=="translate"
    
  });

  translate=translate[0][1].replaceAll("px","");
  translate=translate.split(",");
  return translate;
}
//rgb(247, 147, 30)
export const rgbToHex=(rgb)=>{
  rgb=rgb.replaceAll(" ","");
  rgb=rgb.replaceAll("(","");
  rgb=rgb.replaceAll(")","");
  rgb=rgb.replaceAll("rgb","");
  rgb=rgb.split(",");
  
  let R=  (Number(rgb[0]).toString(16)).length==1 ? "0"+Number(rgb[0]).toString(16) : Number(rgb[0]).toString(16) ;
  let G=  (Number(rgb[1]).toString(16)).length==1 ? "0"+Number(rgb[1]).toString(16) : Number(rgb[1]).toString(16) ;
  let B=  (Number(rgb[2]).toString(16)).length==1 ? "0"+Number(rgb[2]).toString(16) : Number(rgb[2]).toString(16) ;
  
  return "#"+R+G+B
}
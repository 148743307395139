import React from 'react';



import './Mol_TextAndInputText.scss';

import AtText from '../../Atoms/At_Text/At_Text';
import AtInputText from '../../Atoms/At_InputText/At_InputText';

const Mol_TextAndInputText =(props)=>{
    let {
        children,
        widthInput, 
        onChange, 
        type, 
        value, 
        min, 
        max,
        fontSize,
        className,
        name,
        invested
    }=props

    let stylea;
     if(fontSize!=null){
         stylea={
             ...stylea,
             fontSize:fontSize+"px",
         }
     }

    let styleSpan;
  

     if(invested){
        return (
            <div style={stylea} className={"mol-text-and-input-text "+className}>
                
                
    
                <div className="mol-text-and-input-text__at-input-text">
                    <AtInputText name={name} width={widthInput} onChange={onChange} type={type} value={value} min={min} max={max}></AtInputText>
                </div> 
    
               <label style={styleSpan} className="mol-text-and-input-text__span--checkbox"> {children} </label> 
                
            </div>
        )
     }else{
        return (
            <div style={stylea} className={"mol-text-and-input-text "+className}>
                
                {type!="checkbox"  ? <label style={styleSpan} className="mol-text-and-input-text__span"> {children} </label> : ""}
    
                <div className="mol-text-and-input-text__at-input-text">
                    <AtInputText name={name} width={widthInput} onChange={onChange} type={type} value={value} min={min} max={max}></AtInputText>
                </div> 
    
                {type=="checkbox"  ? <label style={styleSpan} className="mol-text-and-input-text__span--checkbox"> {children} </label> : ""}
                
            </div>
        )
    }
     }
    

export default Mol_TextAndInputText;
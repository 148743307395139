export const types = {
    POST: 'POST_RECURSOS',
    PUT: 'UPDATE_RECURSOS',
    DELETE: 'DELETE_RECURSOS',
    RESET: 'RESET_RECURSOS',
    RELOAD: 'RELOAD_RECURSOS',
  };
  
 
  
 const RecursosReducer = (state = [], action) => {
    switch (action.type) {
      case types.POST:
        
        return [
          ...state,
          { ...action.payload },
        ];

      case types.PUT:
        return state.map((child) => (child.id == action.payload.id ? action.payload : child));

      case types.DELETE:
        const newValues = [...state];
        
        const index = newValues.findIndex(
          (elem)=>{
            return elem.id==action.id;
          }
          );
        
        newValues.splice(index, 1);
        return newValues;

      case types.RELOAD:
          
        return action.payload;

      case types.RESET:
        return [];

      default:
        return state;
    }
  };

  export default RecursosReducer;
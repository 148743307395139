import React,{useState,useEffect} from 'react';
import {useDispatch} from 'react-redux';
import './TestSelectCreate.scss';

import AtInputText from '../../../Atoms/At_InputText/At_InputText';
import AtTitle from '../../../Atoms/At_Title/At_Title';
import AtButton from '../../../Atoms/At_Button/At_Button';
import AtSelect from '../../../Atoms/At_Select/At_Select';
import AtEnrichedText,{toolbarTypes} from '../../../Atoms/At_EnrichedText/At_EnrichedText';
import MolEnrichedTextFontOptions from '../../../Molecules/Mol_EnrichedTextFontOptions/Mol_EnrichedTextFontOptions';
import CreationEnrichedTextOptions from './TestSelectSupport/CreationEnrichedTextOptions';

import {editExerciseFFOrTest} from '../../../../Store/Actions/EditorElementosActions';
import {PositionAnswerSelect,imagePrevAnswerSelect} from '../../../../Constantes/ExercisesVars';

import {idiomas,idioms} from '../../../../Constantes/idiomas';
import { exercises } from '../../../../Constantes/Exercises';


let allQuestion;
const TestSelectCreate=(props)=>{
    let {
        element,
        exercise
    }=props;
   
    const RightWrong =[
        [true,"Correcto"],
        [false,"Incorrecto"]
    ];
    
    
    
    const [fontStyle,setFontStyle]=useState();
    const [questionEditing,setQuestionEditing]=useState(exercise.questionData[0]);
    const [actualyIdiom,setActualyIdiom]=useState(idiomas.castellano);
    const [positionAnswer,setPositionAnswer]=useState();
    const [iconPrevAnswer,setIconPrevAnswer]=useState();

    let dispatch=useDispatch();

    useEffect(() => {
        setQuestionEditing(exercise.questionData[0]);
       
        setFontStyle(exercise.fontStyle);
        allQuestion=exercise.questionData;
        setPositionAnswer(exercise.positionAnswer);
        setIconPrevAnswer(exercise.iconPrevAnswer);
    }, []);

    

    const onChangeChangeIdiom=(newIdioma)=>{
        
        saveQuestionData();

        let newQuestionEditing=allQuestion.filter((elem)=>{
            return elem.language==newIdioma;
        });

        
        setActualyIdiom(newIdioma);
        setQuestionEditing(newQuestionEditing[0]);
    }

    const saveQuestionData=()=>{
        
        let newAllQuestion=allQuestion.map((elem)=>{
            if(elem.language==actualyIdiom){
                
                return questionEditing;
            }else{
                return elem;
            }
        });
       
        allQuestion=newAllQuestion;

    }
    const onChangeQuestion=(newQuestion)=>{
       
        setQuestionEditing({...questionEditing,question:newQuestion});
    }  

    const onChangeNumCorrect=(newNumCorrect)=>{
        //numCorrect
        setQuestionEditing({...questionEditing,numCorrect:newNumCorrect});
    } 

    const onChangeAnswer=(id,newAnswer)=>{
            
            
            let newAnswers=questionEditing.answers.map((elem)=>{
                
                if(id==elem.id){
                    
                    return {...elem,answer:newAnswer}
                }else{
                    return elem;
                }
            });
            
            setQuestionEditing({...questionEditing,answers:newAnswers});
        
        
    }  

    const onChangeRightWrong=(id,newValue)=>{

        let newAnswers=questionEditing.answers.map((elem)=>{
            if(id==elem.id){
                if(newValue=="false"){
                    return {...elem,correct:false};
                }
                if(newValue=="true"){
                    return {...elem,correct:true};
                }
                
            }else{
                return elem;
            }
        });

        setQuestionEditing({...questionEditing,answers:newAnswers});
    }  

    const onClickAddQuestion=()=>{
       
        let newAnswers=[...questionEditing.answers,{id:questionEditing.answers.length,answer:"nueva respuesta",correct:false}];

        setQuestionEditing({...questionEditing,answers:newAnswers});
    }  

    const onClickSaveQuestion=()=>{
        saveQuestionData();
        let newExercise={
            ...exercise,
            questionData:allQuestion,
            fontStyle:fontStyle,
            positionAnswer:positionAnswer,
            iconPrevAnswer:iconPrevAnswer
        }
     
        dispatch(editExerciseFFOrTest(element.ID,element.caracteristicas.exercise.idLinkExercise,newExercise));
    }  

    const onChangePositionAnswer=(newPosition)=>{
        setPositionAnswer(newPosition);
    }

    const onChangeImgPrevAnswer=(newIcon)=>{
        setIconPrevAnswer(newIcon);
    }
    
    if(questionEditing!==undefined && fontStyle!==undefined){
      
    return (
        <div>
            <AtSelect values={idioms} onChange={event=>onChangeChangeIdiom(event.target.value)}></AtSelect>
            <div className="test-select-create__question">
                <div className="question__text">
                    <div>
                        <div>
                            <AtSelect 
                                defaultValue={exercise.positionAnswer} 
                                values={PositionAnswerSelect} 
                                onChange={event=>onChangePositionAnswer(event.target.value)}
                                >Posicion respuesta</AtSelect>
                        </div>
                        <div>
                            <AtSelect 
                                defaultValue={exercise.iconPrevAnswer} 
                                values={imagePrevAnswerSelect} 
                                onChange={event=>onChangeImgPrevAnswer(event.target.value)}
                            >Icono respuesta</AtSelect>
                        </div>
                    </div>
                    <MolEnrichedTextFontOptions
                        key={"questionts-"+actualyIdiom}
                        toolbar={toolbarTypes.boldItalic}
                        text={questionEditing.question}
                        onchangeText={onChangeQuestion}
                        fontStyle={fontStyle}
                        onChangeFontStyle={setFontStyle}
                    ></MolEnrichedTextFontOptions>
                    
                </div>
                <div className="question__correct">
                    <AtInputText width="50" type="number" defaultValue={questionEditing.numCorrect} value={questionEditing.numCorrect} onChange={event=>onChangeNumCorrect(event.target.value)}></AtInputText>
                </div>
            </div>
            {questionEditing.answers.map((elem)=>{
                
                return <div  className="content-answers">
                            <div key={"answer-"+elem.id+"-"+actualyIdiom}  className="content-answers__text">
                            <CreationEnrichedTextOptions
                                value={elem.answer}
                                idAnswer={elem.id}
                                onChange={onChangeAnswer}
                                style={{...fontStyle,fontSize:"20px"}}
                                ></CreationEnrichedTextOptions>
                            </div>
                            <div className="content-answers__value">
                                <AtSelect values={RightWrong} defaultValue={elem.correct} onChange={event=>onChangeRightWrong(elem.id,event.target.value)}></AtSelect>
                            </div>
                       </div>

                
            })}
            <div className="test-select-create__buttons">
                <div className="buttons__add-answer">
                    <AtButton onClick={()=>onClickAddQuestion()}>+</AtButton>
                </div>
                <div className="buttons__save">
                    <AtButton onClick={()=>onClickSaveQuestion()}>Guardar</AtButton>
                </div>
            </div>
            
        </div>
    )
    }else{
        return("")
    }
}



export default TestSelectCreate;
import React from 'react';

import {idiomas} from '../../../../../Constantes/idiomas';
import './QuestionTS.scss';

const QuestionTS=(props)=>{
    let {
        exercise,
        language,
        viewer
    }=props;

    let questionByLanguage;
  
    questionByLanguage=exercise.questionData.filter((elem)=>{
        return elem.language==language
    });

    if(questionByLanguage[0]===undefined){
        questionByLanguage=exercise.questionData.filter((elem)=>{
            return elem.language==idiomas.castellano;
        });
    }
    questionByLanguage=questionByLanguage[0];

    

    return(
        <div className="p-question" style={exercise.fontStyle} dangerouslySetInnerHTML={{__html: questionByLanguage.question}}/>
    )
}

export default QuestionTS;
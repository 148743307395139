import React from 'react';
import {useDispatch,useSelector,shallowEqual} from 'react-redux';

import DndItemCreate from './DndItemCreate';
const DndListCreate=(props)=>{
    
    let {
        onClickOnElement,
        filterBy,
        thisIdDndDroppable,
        dndList,
        deleteElement
    }=props



    const elementosEditor=useSelector((state)=>{
        return state.elementosEditor;
      },shallowEqual);

    let dispatch=useDispatch();



    return (   
          
        <>
            
            {elementosEditor.map((elem)=>{
                if(filterBy===undefined){
                    return <DndItemCreate onClickOnElement={onClickOnElement} 
                                thisIdDndDroppable={thisIdDndDroppable}
                                dndList={dndList}
                                elemento={elem} 
                                dispatch={dispatch}
                                deleteElement={deleteElement}/>
                }else{
                    
                    if(filterBy.includes(Number(elem.tipoSeleccionado))){
                        return <DndItemCreate 
                                    deleteElement={deleteElement}
                                    onClickOnElement={onClickOnElement} 
                                    thisIdDndDroppable={thisIdDndDroppable}
                                    dndList={dndList} 
                                    elemento={elem} 
                                    dispatch={dispatch}/>
                    }
                }
                      
            })}
        </>
    )
  
};

export default DndListCreate;
import React, { useEffect, useRef } from 'react';
import {useDispatch,useSelector,shallowEqual} from 'react-redux';
import { useHistory } from "react-router-dom";


import {ruta} from '../../Constantes/Rutas.js'
import {httpGet} from '../../Store/HttpCalls.jsx';
import {reloadFichas} from '../../Store/Actions/FichasActions.js';
import NuevaFicha from './NuevaFicha';
import {eliminarFicha,publishFille,unpublishFille} from './FichasConexionesServidor.jsx';

import {emptyItems} from '../../Store/Actions/EditorElementosActions';
import {resetFichasElementos} from '../../Store/Actions/FichasEditorElementosActions';

import Tabla from '../TablaComponentes/Tabla';
import {tipoDatoTabla} from '../../Constantes/TiposDatosTabla';
import {obtenerJSonPaginador} from '../TablaComponentes/FuncionesTabla';

import AtButton from '../Atoms/At_Button/At_Button';
import AtPopup from '../Atoms/At_Popup/At_Popup';

import './FileController.scss'
import { ImageIcon } from '../Atoms/At_Image/At_Image.jsx';

const FichasControlador =()=>{

    const dispatch=useDispatch();
    const tableRef=useRef();
    let history=useHistory();
    const fichas=useSelector((state)=>{
        
        return state.fichas;
    },shallowEqual);

    
   
    useEffect(()=>{

        dispatch(emptyItems());
        dispatch(resetFichasElementos());

        httpGet(ruta.fichas).then((fichasP)=>{
            dispatch(reloadFichas(fichasP));
        },(error)=>{
            console.error("nuestro error " + error)
        });

    },[]);

    const onClickCambioPagina=(enlaceBackEnd)=>{
        
        httpGet(ruta.fichas + enlaceBackEnd).then((fichasP)=>{
            dispatch(reloadFichas(fichasP));
        },(error)=>{
            console.error("nuestro error " + error)
        })
    };

    const onClickEliminarElemento=(id)=>{
        let deleteConfirm =window.confirm("¿Seguro que quieres borrar la ficha?");
        if(deleteConfirm==true){
            eliminarFicha(id,dispatch);
        }
    };
    

    //created_at
    const pruebaCelda=[
        {
            tipoDato:tipoDatoTabla.imageFiles,
            nombreCelda:null,
            url:'caracteristicas',
            width:"50px",
            height:"50px",
        },
        {
            tipoDato:tipoDatoTabla.textBold,
            nombreCelda:"Nombre ficha",
            texto:'nombre',
        },
        {
            tipoDato:tipoDatoTabla.texto,
            nombreCelda:"Fecha creacion",
            texto:'created_at',
        },
        {
            tipoDato:tipoDatoTabla.boton,
            nombreCelda:null,
            icon: ImageIcon.delete,
            nombreBoton:"Eliminar",
            fctBoton:onClickEliminarElemento,
            argumentos:['id']
        },
        {
            tipoDato:tipoDatoTabla.popupEditFile,
            nombreCelda:null,
            nombreBoton:"Editar",
            className:"file-controller__popup"
            
        },
        {
            tipoDato:tipoDatoTabla.enlace,
            nombreCelda:null,
            nombreEnlace:"Abrir",
            url:[[false,true],[ruta.editor,'id']],
            
        },
        {
            tipoDato:tipoDatoTabla.select,
            nombreCelda:"Estado Publicación",
            atributo:'public',
            texto:'Estado Publicación',
            seleccionables:[
                {
                    valor:'1',
                    nombre:'Publicada',
                },
                {
                    valor:'0',
                    nombre:'No publicada',
                },
            ]
        },
        {
            tipoDato:tipoDatoTabla.enlace,
            nombreCelda:null,
            nombreEnlace:"Ver publicación",
            url:[[false,true,false],[ruta.viewer,'id',"?idioma=castellano"]],
        }
    ];
    
    let paginacionVar=obtenerJSonPaginador(fichas,onClickCambioPagina);

    let style={backgound:"none",border:"none"}

    const handlerOnChangeTable = (fila, idFicha, atributo, valor) => {
        if(atributo == 'public'){
            if(valor == 1){
                publishFille(idFicha).then(() => {}, () => {tableRef.current.newChanges(fila, idFicha, atributo, 0)});
            }else{
                unpublishFille(idFicha).then(() => {}, () => {tableRef.current.newChanges(fila, idFicha, atributo, 1)});
            }
        }
    }

    return(
        <div className="file-controller">
            
            <AtPopup className="file-controller__popup"  trigger={<div className="button-new-file"><AtButton  >Nueva ficha</AtButton></div>}>
               <NuevaFicha></NuevaFicha>
            </AtPopup>
            <Tabla 
                ref={tableRef}
                paginacion={paginacionVar} 
                datos={fichas.data} 
                estructuraColumnas={pruebaCelda} 
                onChange={handlerOnChangeTable}
            />
            
            
        </div>
    );


};

export default FichasControlador;
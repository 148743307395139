import { types } from '../Reducers/FichasEditorElementosReducer';


export const createFichasElementos = (values) => ({
  type: types.POST,
  payload: values,
});

export const updateFichasElementos = (values) => ({
  type: types.PUT,
  payload: values,
});

export const removeFichasElementos = (id) => ({
  type: types.DELETE,
  id,
});

export const reloadFichasElementos = (items) => ({
  type: types.RELOAD,
  payload: items,
});

export const resetFichasElementos = () => ({
  type: types.RESET,
});


import React from 'react';

import {seleccionarElemento} from '../../../../../Store/Actions/ElemSeleccionadoAction.js'
import {editarCssAc} from '../../../../../Store/Actions/EditorElementosActions.js';

import AtButton from '../../../../Atoms/At_Button/At_Button';

import {tipoElemento} from '../../../../../Constantes/Elementos';
import {typeCss} from '../../../../../Constantes/TypeCss';



import './DndItemCreate.scss';

const DndItemCreate=(props)=>{
    let {
        elemento,
        dispatch,
        onClickOnElement,
        thisIdDndDroppable,
        dndList,
        deleteElement
        }=props;
     
        

    const onClickSeleccionarElemento=(value)=>{
        
            onClickOnElement(value);
             
      };

    const onClickDeleteElement=(value)=>{
        deleteElement(value)
       
    };
    
    let styleelementor={
       // backgroundColor:elemento.estiloHijo.backgroundColor,
        ...elemento.estiloHijo,
        width:"35px",
        height:"35px",
        
    }
    delete styleelementor.position;
    delete styleelementor.zIndex;

    let elementor;
   
    if(elemento.tipoSeleccionado==5){
        elementor=<img alt="" style={styleelementor} src={elemento.caracteristicas.image.items[0].url}/>
    }else{
        
        elementor=<div style={styleelementor}></div>
    }


    let isSelected=false;
    
        for(let i=0;i<dndList.length;i++){
            
            for(let j=0;j<dndList[i].items.length;j++){
                
               if(elemento.caracteristicas!==undefined){
                if( dndList[i].items[j].corretContainer==thisIdDndDroppable && 
                    dndList[i].items[j].id=="item-"+elemento.caracteristicas.idDroppable){
                    
                    isSelected=true;
                }
               }
                
            }
        }
        
    

    let nameButton,onClickAtButton;

    if(isSelected){
        nameButton="Deseleccionar";
        onClickAtButton=onClickDeleteElement;
        

    }else{
        nameButton="Seleccionar";
        onClickAtButton=onClickSeleccionarElemento;
    }

    return (   
          
        <div className="elements-list">

            <div className="elements-list__first-button">
                {elementor}
                <AtButton  onClick={()=>{onClickAtButton(elemento)}}>{nameButton}</AtButton>
            </div>
            

        </div>
    )
  
};

export default DndItemCreate;
export const exercises={
    textSelect:"textSelect",
    fillableField:"fillableField",
    dragAndDrop:"dragAndDrop",
    colorItem:"colorItem",
    imagesTest:"imagesTest",
    basicStaff:"basicStaff"

}

export const typePartQorA={
    answer:"answer",
    question:"question"
}

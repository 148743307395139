import React, { useState } from 'react';

import {tipoDatoTabla} from '../../Constantes/TiposDatosTabla';
import {crearDatosTabla} from './FuncionesTabla'
import Fila from './Fila';

import At_SoftText from '../Atoms/At_SoftText/At_SoftText';

const CabezaTabla =(props)=>{
        let {estructuraColumnas}=props;


    
    
    return(
        
       <tr>
            
            {
                
                estructuraColumnas.map((fila,i)=>{
                return <td key={fila.nombreCelda+i}><At_SoftText>{fila.nombreCelda}</At_SoftText></td>;
                    }) 

            }
                
            
           
       </tr>
        
    );
};

export default CabezaTabla;
import React from 'react';

import {tipoDatoTabla} from '../../Constantes/TiposDatosTabla';

export const obtenerJSonPaginador=(fichas,onClickCambioPagina)=>{

    let paginacionVar={...fichas,onClickCambioPagina:onClickCambioPagina};
    if(paginacionVar!=null){
        delete paginacionVar.data;
    }

    return paginacionVar;
};


export const crearFilaTabla=(celdasTipo,datos)=>{
    let fila;

    fila=celdasTipo.map((celda)=>{
        
        switch (celda.tipoDato) {
            case tipoDatoTabla.texto:
                
                return {
                        tipoDato:tipoDatoTabla.texto,
                        texto:datos[celda.texto]
                    }

            case tipoDatoTabla.textBold:
                
                return {
                        tipoDato:tipoDatoTabla.textBold,
                        texto:datos[celda.texto]
                    }

            case tipoDatoTabla.select:
                return {
                    atributo: celda.atributo,
                    tipoDato:tipoDatoTabla.select,
                    seleccionables: celda.seleccionables,
                    texto:datos[celda.atributo]
                }
        
            case tipoDatoTabla.boton:
                let argumentos;
                if(celda!=null){
                    argumentos=celda.argumentos.map((elem)=>{
                        return datos[elem]
                    });
                }
                
                return {
                    tipoDato:tipoDatoTabla.boton,
                    nombreBoton:celda.nombreBoton,
                    icon: celda.icon,
                    fctBoton:()=>{celda.fctBoton(argumentos)}
                }
            

            case tipoDatoTabla.checkBox:
                return {
                    tipoDato:tipoDatoTabla.checkBox,
                    fctOnChange:celda.fctOnChange,
                    flagChecked:celda.flagChecked
                }

            case tipoDatoTabla.enlace:
                let url="";
                let i;
                
                for(i=0;celda.url[0].length>i;i++){
                   
                   if(celda.url[0][i]){
                    
                    url=url.concat(datos[celda.url[1][i]]);
                    //url=url.concat("/");
                   }else{
                    
                    url=url.concat(celda.url[1][i]);
                   }
                    
                }
                
                
                return {
                        tipoDato:tipoDatoTabla.enlace,
                        nombreEnlace:celda.nombreEnlace,
                        url:url,
                        
                }

            case tipoDatoTabla.content:
                
                return {
                    tipoDato:tipoDatoTabla.content,
                    content:celda.content,
                }

            case tipoDatoTabla.popup:
                
                return {
                    tipoDato:tipoDatoTabla.popup,
                    content:celda.content,
                    nameButton:celda.nombreBoton,
                }

            case tipoDatoTabla.popupEditFile:
               
                return {
                    tipoDato:tipoDatoTabla.popupEditFile,
                    nameButton:celda.nombreBoton,
                    file:datos,
                    className:celda.className
                }

            case tipoDatoTabla.popupEditMultimedia:
            
                return {
                    tipoDato:tipoDatoTabla.popupEditMultimedia,
                    nameButton:celda.nombreBoton,
                    file:datos,
                    className:celda.className
                }
            case tipoDatoTabla.popupEditMultimediaItems:
            
                return {
                    tipoDato:tipoDatoTabla.popupEditMultimediaItems,
                    nameButton:celda.nombreBoton,
                    file:datos,
                    className:celda.className
                }
            case tipoDatoTabla.imageFiles:
                
                return {
                    tipoDato:tipoDatoTabla.image,
                    url:datos[celda.url]["urlImgMinuature"],
                    width:celda.width,
                    height:celda.height,

                }

            case tipoDatoTabla.imageMultimedia :
                
                return {
                        tipoDato:tipoDatoTabla.imageMultimedia,
                        url:celda.url,
                        width:celda.width,
                        height:celda.height,
                        multimedia:datos,
                    }
            
            case tipoDatoTabla.svg :
            
                return {
                        tipoDato:tipoDatoTabla.svg,
                        url:celda.url,
                        width:celda.width,
                        height:celda.height,
                        multimedia:datos,
                    }

            default:
                return "Error en estructura del json";
                    
        }
    });

    return fila;
};


export const crearDatosTabla=(celdasTipo,datos)=>{
    let datosReturn;
    datosReturn=datos.map((elem)=>{
        return crearFilaTabla(celdasTipo,elem);
    });

    return datosReturn;
};
import React from 'react';

import AtSvg from '../../../Atoms/At_Svg/At_Svg';
import ColoringSvgCreate from './ColoringSvgCreate';

import AtPopup from '../../../Atoms/At_Popup/At_Popup';

const ColoringSvgViewer=(props)=>{
    let {
        question,
        language,
        idElement,
        color,
        inViewer
    }=props
    
    
    let thisSvg=question.svg.items.filter((elem)=>{
        return elem.language===language;
    });
    thisSvg=thisSvg[0];
    
    if(thisSvg===undefined){
        thisSvg=question.svg.items[0];
    }
   
    return (
        <div >
            {inViewer===true ? 
            <AtPopup trigger={<AtSvg svg={thisSvg.url} idElement={idElement} color={question.colorSvg}></AtSvg>} >
                <ColoringSvgCreate idElement={idElement}  color={color}></ColoringSvgCreate>
            </AtPopup>:
            <AtSvg svg={thisSvg.url} idElement={idElement} color={question.colorSvg}></AtSvg>}
            
        </div>
    )
}


export default ColoringSvgViewer;
const removeFromList=(index,list)=>{
    let removed=list.splice(index,1);
    return {removed:removed,newList:list};
}

const addToList=(index,list,item)=>{
    list.splice(index,0,item);
    
    return list;
}

const move=(source,destination,droppableSource,droppableDestination,lista)=>{
    let itemMoving;
    let newList=lista.map((elem)=>{
        
            if(elem.id==source){
                
                let result=removeFromList(droppableSource.index,elem.items);
                itemMoving=result.removed[0];
                
                return {...elem,items:result.newList};
            }
            return elem;
    });

    newList=newList.map((elem)=>{
            if(elem.id==destination){
                return {...elem,items:addToList(droppableDestination.index,elem.items,itemMoving),edited:true};
            }
            return elem;
    });
   
    return newList;
};



export const onDragEnd = (result,lista) => {
    const { source, destination } = result;
    

    if (!destination) {
        if(result.draggableId[4]=="-"){
            
            const resultNoDestination = move(
                source.droppableId,
                Number(result.draggableId.split(["-"])[1]),
                source,
                {index: 0, droppableId:result.draggableId.split(["-"])[1]},
                lista
            );
            
            return resultNoDestination;

        }else{
            
            return lista;
        }
    }
    
        const resultMove = move(
            source.droppableId,
            destination.droppableId,
            source,
            destination,
            lista
        );

        return resultMove;
};
import React,{useEffect,useState} from 'react';
import {useDispatch} from 'react-redux';
import './Mol_LanguageSelector.scss';

import AtSelect from './../../Atoms/At_Select/At_Select';
import AtImage from '../../Atoms/At_Image/At_Image';
import {idioms,idiomas} from '../../../Constantes/idiomas';

import {updateIdiom} from '../../../Store/Actions/IdiomActions';

import { ImageIcon } from '../../Atoms/At_Image/At_Image';

const Mol_LanguageSelector=(props)=>{
    let {
        widgth,
        height
    }=props

    const [imageLanguage,setImageLanguage]=useState();
    const dispatch=useDispatch();

    useEffect(()=>{

            dispatch(updateIdiom(idiomas.castellano));
            setImageLanguage(ImageIcon.languageES);

    },[]);

    const onChangeUpdateIdiom=(value)=>{
            dispatch(updateIdiom(value));

            switch (value) {
                case idiomas.castellano:
                    setImageLanguage(ImageIcon.languageES);
                break;
                case idiomas.ingles:
                    setImageLanguage(ImageIcon.languageEN);
                break;
                case idiomas.valenciano:
                    setImageLanguage(ImageIcon.languageVA);
                    break;
            
                default:
                    setImageLanguage(ImageIcon.languageES);
                    break;
            }
    }

    return(
        <div className="mol-language-selector">
            <div className="mol-language-selector__at-image">
                <AtImage image={imageLanguage} widgth={widgth} height={height}></AtImage>
            </div>

            <div className="mol-language-selector__at-select">
                <AtSelect onChange={event=>onChangeUpdateIdiom(event.target.value)} values={idioms} ></AtSelect>
            </div>
        </div>
    )
}

export default Mol_LanguageSelector;
import React,{useState,useEffect} from 'react';
import {useDispatch} from 'react-redux';
import AtPopup from '../../../../Atoms/At_Popup/At_Popup';
import TestSelectViewer from '../TestSelectViewer';
import {idiomas} from '../../../../../Constantes/idiomas';

import {addExerciseResult} from '../../../../../Store/Actions/ExerciseResultsActions';

import ExerciseResponse1 from '../../../../../Images/ExerciseResponse1.png';
import ExerciseResponse2 from '../../../../../Images/ExerciseResponse2.png';
import ExerciseResponse3 from '../../../../../Images/ExerciseResponse3.png';
import ExerciseResponse5 from '../../../../../Images/IconoEscribir.png';
import ExerciseResponse6 from '../../../../../Images/IconoInstrumento.png';
import ExerciseResponse7 from '../../../../../Images/IconoInvestigar.png';
import ExerciseResponse8 from '../../../../../Images/IconoPentagrama.png';


import {PositionAnswer,imagePrevAnswer,imagePrevAnswerSelect} from '../../../../../Constantes/ExercisesVars';
import {quitarPxFinal} from '../../../../../Services/CSSTratamiento';
import './AnswerTS.scss';
import { exercises } from '../../../../../Constantes/Exercises';

const AnswerTS=(props)=>{
    let {
        exercise,
        language,
        styleElement,
        viewer,
        correctExercises,
        answerExercises

    }=props;

    const dispatch=useDispatch();
    //const [firstLoad, setFirstLoad] = useState(true);
    
    let questionDataByLanguage;

    questionDataByLanguage=exercise.questionData.filter((elem)=>{
        return elem.language==language
    });

    if(questionDataByLanguage[0]===undefined){
        questionDataByLanguage=exercise.questionData.filter((elem)=>{
            return elem.language==idiomas.castellano;
        });
    }
    questionDataByLanguage=questionDataByLanguage[0];

    const [thisResponse,setThisResponse]=useState([]);
    const [classNameIncorrectAnswer,setClassNameIncorrectAnswer]=useState("");

    useEffect(() => {
            
        if(answerExercises!==undefined && answerExercises[0]!==undefined){
            let thisAnswerExercises=answerExercises.filter((elem)=>{
                return elem.exerciseData.idLinkExercise==exercise.idLinkExercise && elem.exerciseData.typeExercise == exercises.textSelect;
            });

            if(thisAnswerExercises[0]!==undefined && 
                thisAnswerExercises[0].exerciseData!==undefined && 
                thisAnswerExercises[0].exerciseData.answer!==undefined){
                
                setThisResponse([thisAnswerExercises[0].exerciseData.answer]);
               
            }
        }
    }, [answerExercises]);

    let styleImg={
        width:styleElement.height,
        maxWidth:"50px",
        maxHeight:"50px",
    };
   
    if(quitarPxFinal(styleElement.height)<=quitarPxFinal(styleElement.width)){
        styleImg={
            width:styleElement.height,
            maxWidth:"50px",
            maxHeight:"50px",
        };
    }
    if(quitarPxFinal(styleElement.height)>quitarPxFinal(styleElement.width)){
        
        styleImg={
            width:styleElement.width,
            maxWidth:"50px",
            maxHeight:"50px",
        };
    }
    

    let classContent,classImg;
    if(exercise.positionAnswer!==undefined){
        if(PositionAnswer.center==exercise.positionAnswer){
            classContent=" div-content-ts__center";
            classImg=" img-ts__center";
            
        }
        if(PositionAnswer.leftTop==exercise.positionAnswer){
            classContent=" div-content-ts__leftTop";
            classImg=" img-ts__leftTop";
            styleImg={...styleImg,marginTop:exercise.fontStyle.fontSize};
        }
        if(PositionAnswer.leftBottom==exercise.positionAnswer){
            classContent=" div-content-ts__leftBottom";
            classImg=" img-ts__leftBottom";
        }
        
        
    }else{
        classContent=" div-content-ts__center";
        classImg=" img-ts__center";
    }

    let imgPrevAnswer;
    
   if(exercise.iconPrevAnswer!==undefined){
       
        if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse1){
            imgPrevAnswer=ExerciseResponse1;
        }
        if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse2){
            imgPrevAnswer=ExerciseResponse2;
        }
        if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse3){
            imgPrevAnswer=ExerciseResponse3;
        }
        if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse4){
            imgPrevAnswer=ExerciseResponse3;
            styleImg={
                width:"20px",
            }
        }
        if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse5){
            imgPrevAnswer=ExerciseResponse5;
        }
        if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse6){
            imgPrevAnswer=ExerciseResponse6;
        }
        if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse7){
            imgPrevAnswer=ExerciseResponse7;
        }
        if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse8){
            imgPrevAnswer=ExerciseResponse8;
        }
        
   }else{
      
        imgPrevAnswer=ExerciseResponse1;
        
   }

   useEffect(() => {
       if(correctExercises==true){
            let replyToSend={answer:thisResponse[0],thisExercise:exercise,typeExercise:exercises.textSelect,isCorrect:setisCorrectExercise()};
        
            dispatch(addExerciseResult(replyToSend));
       }
   }, [correctExercises]);

   useEffect(() => {
    setClassNameIncorrectAnswer("");
    if(answerExercises){
        //setFirstLoad(false); Produce un bug, procedo a eliminar esta condición de primera vez.
        setisCorrectExercise();
    }
   }, [thisResponse])

   const setisCorrectExercise=()=>{
       if(thisResponse[0]!==undefined && thisResponse[0].correct!==undefined){
           if(thisResponse[0].correct==false){
            setClassNameIncorrectAnswer("class-name-incorrect-answer-TS");
           }
            return thisResponse[0].correct;
        }else{
            return false;
        }
    }
 
if(viewer){
    styleImg={
        ...styleImg,
        border:"2px solid transparent"
    }
    return(
        <AtPopup noCloseInClickOut trigger={
            0!==thisResponse.length  ?
            <div className={"div-response-ts div-response-ts__overflow "+ classContent}>
                {thisResponse.map((elem)=>{
                    return <p className={classNameIncorrectAnswer} style={exercise.fontStyle}  dangerouslySetInnerHTML={{__html: elem.answer}}/>
                })}
            </div>
            :
            <div className={"div-response-ts "+ classContent}>
                <img className={classImg} style={styleImg} src={imgPrevAnswer} alt="imagen no disponible"/>
            </div>
        }    
        transparent
        >
            <TestSelectViewer style={exercise.fontStyle} value={thisResponse} onSaveResponse={setThisResponse} question={questionDataByLanguage}></TestSelectViewer>
        </AtPopup>
    )
}else{
    
    return(
    
        <div className={"div-response-ts "+ classContent}>
            <img className={classImg} style={styleImg} src={imgPrevAnswer} alt="imagen no disponible"/>
        </div>
    )
}
}

export default AnswerTS;
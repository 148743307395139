import React from 'react';
import AtButton from '../../../Atoms/At_Button/At_Button';



const BasicStaffCreate=(props)=>{
    let {
        idElement,
        sizeFigure
    }=props
    return <div >
                <p>Tamaño figura</p>
                <input type="number" value={sizeFigure}></input>
                <AtButton>Cancelar</AtButton>
                <AtButton>Guardar</AtButton>
            </div>
}

export default BasicStaffCreate;
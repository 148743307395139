import React from 'react';

import './Mol_MultimediaSelected.scss'

import AtImage from '../../Atoms/At_Image/At_Image';
import AtTitle from '../../Atoms/At_Title/At_Title';

import AudioImg from '../../../Images/Recurso-Audio.png';
import PdfImg from '../../../Images/Recurso-PDF.png';
import SpotifyImg from '../../../Images/Recurso-Spotify.png';
import VimeoImg from '../../../Images/Recurso-Vimeo.png';
import YoutubeImg from '../../../Images/Recurso-Youtube.png';
import BandcampImg from '../../../Images/Recurso-Bandcamp.png';
import {tipoRecurso} from '../../../Constantes/TiposRecursos';
const Mol_MultimediaSelected=(props)=>{
    let {
        element,
    }=props;

    let imageMultimedia,nameMultimedia;

    if(element[0]!=null && element[0].isMultimedia && element[0].caracteristicas.multimedia!==undefined){
        if(Array.isArray(element[0].caracteristicas.multimedia)){
            imageMultimedia = [];
            nameMultimedia = [];
            element[0].caracteristicas.multimedia.forEach((multimedia) => {
                nameMultimedia.push(multimedia.nombre);
                switch (multimedia.tipo) {
                    case tipoRecurso.imagen:
                        imageMultimedia.push(multimedia.items[0].url);
                    break;
                    default:
                    break;
                }
                
            });
        }else{
            nameMultimedia=element[0].caracteristicas.multimedia.nombre;
            switch (element[0].caracteristicas.multimedia.tipo) {

                    case tipoRecurso.audios:
                        imageMultimedia=AudioImg;
                    break;

                    case tipoRecurso.imagen:
                        imageMultimedia=element[0].caracteristicas.multimedia.items[0].url;
                    break;

                    case tipoRecurso.pdf:
                        imageMultimedia=PdfImg;
                    break;

                    case tipoRecurso.spotify:
                        imageMultimedia=SpotifyImg;
                    break;

                    case tipoRecurso.vimeo:
                        imageMultimedia=VimeoImg;
                    break;
                    case tipoRecurso.bandcamp:
                        imageMultimedia=BandcampImg;
                    break;

                    case tipoRecurso.youtube:
                        imageMultimedia=YoutubeImg;
                    break;

                    default:
                    break;
            }
        } 
    }

    return(
        <>
            {Array.isArray(nameMultimedia) && 
                nameMultimedia.map((name,index) => {
                    return <div key={`mol-multimedia-selected-${index}`} className="mol-multimedia-selected">
                        <AtImage className="mol-multimedia-selected__at-image" image={imageMultimedia[index]} widgth="30" height="30"></AtImage>
                        <AtTitle className="mol-multimedia-selected__label" fontSize={11} >{name}</AtTitle>
                    </div>
                })
            }
            {!Array.isArray(nameMultimedia) && 
                <div className="mol-multimedia-selected">
                    <AtImage className="mol-multimedia-selected__at-image" image={imageMultimedia} widgth="30" height="30"></AtImage>
                    <AtTitle className="mol-multimedia-selected__label" fontSize={11} >{nameMultimedia}</AtTitle>
                </div>
            }
        </>
    );
}

export default Mol_MultimediaSelected;
import {idiomas} from '../../Constantes/idiomas';

export const types = {
    PUT: 'UPDATE_IDIOM',
  };
  

  
 const IdiomReducer = (state = [], action) => {
    switch (action.type) {

      case types.PUT:
          
        return action.idiom;

      default:
        
        return state;
    }
  };

  export default IdiomReducer;
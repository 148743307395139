import React from 'react';

import './At_InputText.scss';


const At_InputText =(props)=>{
    let {
        
        type,
        value,
        min,
        max,
        onChange,
        onClick,
        width,
        startWrite,
        placeholder,
        disabled,
        name,
        defaultChecked,
        defaultValue,
        checked,
        fillableFieldExercise,
        className
        
    }=props;


    let style={
            width:width+"px",
        }

    let classNameAditional;
    if(type==="text"){
        classNameAditional="at-inputText--text";
    }
    let typeStyle=fillableFieldExercise?"at-inputText--fillable-field-exercise":"";
    
    return (
        <input 
        className={"at-inputText " + classNameAditional +" "+typeStyle+" "+className}
        defaultChecked={defaultChecked} 
        onClick={onClick} 
        name={name} 
        disabled={disabled} 
        placeholder={placeholder} 
        onKeyDown={startWrite}  
        style={style} 
        onChange={onChange} 
        type={type} 
        value={value}
        min={min} 
        max={max}
        defaultValue={defaultValue}
        checked={checked}
        />
    )
}

export default At_InputText;
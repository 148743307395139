import React,{useState,useEffect} from 'react';
import '../TestSelect/TestSelectViewer.scss';
import './FillableFieldViewer.scss';
import NoteBook from '../../../../Images/cuaderno.png';

import AtInputText from '../../../Atoms/At_InputText/At_InputText';
import AtButton from '../../../Atoms/At_Button/At_Button';
import AtTitle from '../../../Atoms/At_Title/At_Title';
import AtEnrichedText,{toolbarTypes} from '../../../Atoms/At_EnrichedText/At_EnrichedText';


import {deleteHtml} from '../../../Atoms/At_EnrichedText/At_EnrichedText';
import {FillableFieldType} from '../../../../Constantes/ExercisesVars';
import {idiomas} from '../../../../Constantes/idiomas';

const FillableFieldViewer=(props)=>{
    let {
        question,
        finished,
        onSaveResponse,
        value,
        style
    }=props;

   

    let questionByIdiom;

        
    const [thisQuestion,setThisQuestion]=useState(questionByIdiom);
    

    const onChangeSetAnswer=(id,newAnswer)=>{
        
        let newTexts=questionByIdiom.text.map((elem)=>{
            
            if(elem.id===id){
               
                return {...elem,answer:newAnswer};
            }
            return elem;
        });

        setThisQuestion({...questionByIdiom,text:newTexts});
      
    }

    const onClickorrectExercise=()=>{
        let correctAnswers=0;
        let totalAnswers=0;

        thisQuestion.text.map((elem)=>{
            if(elem.type===FillableFieldType.fillableField){
                totalAnswers++;
                
                correctAnswers+=compareAnswer(elem.answer,elem.answers);
            }
        });

        alert("correctas: "+correctAnswers + "/" +totalAnswers);
    }

    const compareAnswer=(response,answers)=>{
        let correctAnswers=0;

        answers=deleteHtml(answers).toUpperCase();
        answers=answers.split(":");
        
        response=deleteHtml(response).toUpperCase();


        answers.map((elem)=>{
            if(response.includes(elem)){
                
                correctAnswers++;
            }
        });

        return correctAnswers;
    }



    

    if(questionByIdiom===undefined){
        questionByIdiom={text:[{id: 2, type: "stateText", text: "pregunta"}]}
    }

    const [thisResponse,setThisResponse]=useState(value);
    const onClickSaveResponse=()=>{
        
        onSaveResponse(thisResponse);
        finished();
    }

        let newSyle={
            ...style,
            fontSize:"20px",
            color:"black"
        }

        return(
            <div className="test-select-viewer">
                <img src={NoteBook} className="test-select-viewer__background" alt="Notebook background"/>
                <div className="test-select-viewer__exercise">
                    <div className="test-select-viewer__section"><p style={newSyle} dangerouslySetInnerHTML={{__html: question.question}} /></div>
                    <div style={newSyle} className="test-select-viewer__section">
                        <AtEnrichedText
                            value={thisResponse}
                            onChange={setThisResponse}
                            autofocus
                        ></AtEnrichedText>
                    </div>
                    <div>
                        <AtButton onClick={onClickSaveResponse}>Responder</AtButton>
                    </div>
                </div>
            </div>
        )
    
}


export default FillableFieldViewer;
import React,{useEffect,useState} from "react";

import "./At_AudioPlayer.scss";

import ImgPause from '../../../Images/ImgAtAudioPlayerPause.png';
import ImgPlay from '../../../Images/ImgAtAudioPlayerPlay.png';
import ImgVolume from '../../../Images/ImgAtAudioPlayerVolume.png';
import ImgClose from '../../../Images/ImgAtAudioPlayerClose.png';

const At_AudioPlayer=(props)=>{
    let {
        audio,
        type,
        onClickX
    }=props;
    
    const [thisAudio,setAudio]= useState();

    useEffect(() => {
        
        setAudio( new Audio(audio));
    }, [audio]);

    const onClickPlayAudio=()=>{
      
        if(thisAudio.paused){
            
            thisAudio.play();
        }
        
    }

    const onClickPauseAudio=()=>{
        if(!thisAudio.paused){
           
            thisAudio.pause();
        }
    }

    return(
        <div className="at-audio-player__controls">
            <audio src={audio} controls />
            <button className="at-audio-player__button-mute">
                <img className="button-mute__img" height="30px" src={ImgClose} alt="ImgVolume" onTouchStart={onClickX} onClick={onClickX}/>
            </button>
        </div>
    )

}



export default At_AudioPlayer;


export const quedarteConLaDiferenciaFinalString=(texto1,texto2)=>{
    let i;
    let textoResultante=String("");
    let txt1=String(texto1),txt2=String(texto2);
    
    if(txt1.length<=txt2.length){
        
        for(i=0;txt2.length>i;i++){
            
            
            if(txt1[i]!==txt2[i]){
                
                textoResultante=textoResultante.concat(txt2[i]);
            }
        }

    }else{
        for(i=0;txt1.length>i;i++){
            
            if(txt1[i]!==txt2[i]){
                
                textoResultante=textoResultante.concat(txt1[i]);
            }
        }
    }

    return textoResultante;

};

export const splitUrlSearch=(urlSearch)=>{
    let varUrl=(urlSearch.replace("?","")).split("&");
    let varsUrl=varUrl.map((elem)=>{
        return elem.split("=");
    });

    return varsUrl;
}
import React, { useState } from 'react';
import Popup from "reactjs-popup";

import AtButton from '../../Atoms/At_Button/At_Button';
import AtPopup from '../../Atoms/At_Popup/At_Popup';


const DTPopup =(props)=>{
     let {datos}=props;
               
    let content=datos.content;
        

    return(
        
        <td className="table-body__td">
            <AtPopup trigger={<AtButton>Editar</AtButton>} >
               {content}
            </AtPopup>
        </td>
        
    );
};

export default DTPopup;
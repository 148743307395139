import React from 'react';

import AtButton from '../../Atoms/At_Button/At_Button';
import AtPopup from '../../Atoms/At_Popup/At_Popup';

import TestSelectCreate from '../Exercises/TestSelect/TestSelectCreate';
import ImageTestCreate from '../Exercises/ImageTest/ImageTestCreate';
import FillableFieldCreate from '../Exercises/FillableField/FillableFieldCreate';
import DragAndDropCreate from '../Exercises/DragAndDrop/DragAndDropCreate';
import ColoringSvgCreate from '../Exercises/ColoringSvg/ColoringSvgCreate';
import BasicStaffCreate from '../Exercises/BasicStaff/BasicStaffCreate';

import {exercises} from '../../../Constantes/Exercises';

const InsertButtonEditExercise=(props)=>{
    let {
        element,
        language,
        dndExercise
    }=props;
    

    switch (element.caracteristicas.exercise.typeExercise) {
        case exercises.textSelect:

            return <AtPopup trigger={<AtButton question={element.caracteristicas.question} language={language} >Editar ejercicio</AtButton>} >
                        <TestSelectCreate element={element} exercise={element.caracteristicas.exercise}></TestSelectCreate>
                    </AtPopup>
        case exercises.imagesTest:

            return <AtPopup trigger={<AtButton question={element.caracteristicas.question} language={language} >Editar ejercicio</AtButton>} >
                        <ImageTestCreate element={element} exercise={element.caracteristicas.exercise}></ImageTestCreate>
                    </AtPopup>

        case exercises.fillableField:

            return <AtPopup trigger={<AtButton question={element.caracteristicas.question} language={language} >Editar ejercicio</AtButton>} >
                        <FillableFieldCreate element={element} exercise={element.caracteristicas.exercise}></FillableFieldCreate>
                    </AtPopup>

        case exercises.dragAndDrop:
            
            return <AtPopup trigger={<AtButton question={element.caracteristicas.exercise} dndExercise={dndExercise} >Editar ejercicio</AtButton>} >
                        <DragAndDropCreate idDroppableCorrect={element.caracteristicas.exercise.idDndDroppable}></DragAndDropCreate>
                    </AtPopup>
        
        case exercises.colorItem:

            return <AtPopup trigger={<AtButton question={element.caracteristicas.exercise} dndExercise={dndExercise} >Editar ejercicio</AtButton>} >
                        <ColoringSvgCreate idElement={element.ID}></ColoringSvgCreate>
                    </AtPopup>
            
        case exercises.basicStaff:

            return <AtPopup trigger={<AtButton question={element.caracteristicas.exercise} dndExercise={dndExercise} >Editar ejercicio</AtButton>} >
                        <BasicStaffCreate idElement={element.ID} exercise={element.caracteristicas.exercise}></BasicStaffCreate>
                    </AtPopup>
    
        default:
            break;
    }
    
}


export default InsertButtonEditExercise;
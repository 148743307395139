import React from 'react';
import './At_DivRack.scss';

const At_DivRack =(props)=>{
    let {
        image,
        children,
        onClick
    }=props;
    
    return(
        <div onClick={onClick} className="at-div-rack">
            <img height="85" width="126" src={image} alt="imagen no dispinible"/>
            <label className="at-div-rack__label">{children}</label>
        </div>
    )
}

export default At_DivRack;
import React,{useEffect} from 'react';
import {useDispatch,useSelector,shallowEqual} from 'react-redux';
import {httpGet} from '../../../Store/HttpCalls.jsx';

import {ruta} from '../../../Constantes/Rutas';
import {reloadRecursos} from '../../../Store/Actions/RecusosActions.js';
import {insertMultiedia} from '../../../Store/Actions/EditorElementosActions';
import {multimediaTypes} from '../../../Constantes/TiposRecursos';

import MolRack from '../../Molecules/Mol_Rack/Mol_Rack';


import './InsertMultimediaIntoElement.scss';

const InsertMultimediaIntoElement=(props)=>{
    const {
        idElement,
        seeker,
        onClick,
        filterBy,
        finished,
    }=props

   
    const dispatch=useDispatch();
    
    const recursos=useSelector((state)=>{
        
        return state.recursos;
    },shallowEqual);
    
    useEffect(()=>{

        if(filterBy===undefined){


            httpGet(ruta.getPagedMultimedia).then((recursos)=>{
                dispatch(reloadRecursos(recursos));
            },(error)=>{
                console.error("nuestro error " + error)
            })

        }else{

            httpGet(ruta.getPagedMultimediaFilter + "?type="+filterBy).then((multimedia)=>{
                dispatch(reloadRecursos(multimedia));
            },(error)=>{
                console.error("nuestro error " + error)
            })
        }
            
      
       

    },shallowEqual);

   

    const onClickInsertMultimediaIntoElement=(multimedia)=>{
        

        let newMultimedia=recursos.data.filter((elem)=>{
            return elem.id===multimedia.id;
        });
        
        dispatch(insertMultiedia(idElement,newMultimedia));
        
        finished();
    }


    const onClickChangePage=(urlEnd)=>{
            
        httpGet(ruta.getPagedMultimedia + urlEnd).then((multimedia)=>{
            dispatch(reloadRecursos(multimedia));
        },(error)=>{
            console.error("nuestro error " + error)
        })

    };

    const onChangeMultimediaFilter=(type,name)=>{
        
        httpGet(ruta.getPagedMultimediaFilter + "?type="+type+"&name="+name).then((multimedia)=>{
           
            dispatch(reloadRecursos(multimedia));
        },(error)=>{
            console.error("nuestro error " + error)
        })

    }
    
    let datos=[],paginate=[];
    if(recursos.data!=null){
        datos=recursos.data;
        paginate=recursos;
        
    }

    

    
    
    
    let onClickDivRack=onClickInsertMultimediaIntoElement;

    
    if(onClick!==undefined){
        
        onClickDivRack=(props)=>{
            onClick(props);
   
            if(finished!==undefined){
                finished();
            }
            
            
        }

        
    }

    let valuesFilterSelect=[["",'Todos'],...multimediaTypes];
    
    
    
    return (
        <div className="insert-multimedia-into-element">
            
            <MolRack 
                onClickDivRack={onClickDivRack}
                onClickChangePage={onClickChangePage} 
                onChangeFilter={onChangeMultimediaFilter} 
                paginate={paginate} 
                data={datos}
                seeker={seeker}
                valuesFilterSelect={valuesFilterSelect}>
            </MolRack>
            
        </div>
    )
}


export default InsertMultimediaIntoElement;
export const types = {
    GET_FICHA: 'GET_FICHA',
    INCREASE_DND_ID:"INCREASE_DND_ID",
    DELETE_DND:"DELETE_DND",
    SET_FIGURE_SIZE:"SET_FIGURE_SIZE"
    
  };

  const EstaFichasReducer = (state = [], action) => {
    let newState;
    switch (action.type) {
      

      case types.GET_FICHA:
       
        return action.payload;
          

      case types.INCREASE_DND_ID:
        
        newState={
          ...state[0],
          caracteristicas:{
            ...state[0].caracteristicas,
            dataExercise:{
              ...state[0].caracteristicas.dataExercise,
              dnds:[
                ...state[0].caracteristicas.dataExercise.dnds,
                ...action.items
              ],
              lastIdDnd:state[0].caracteristicas.dataExercise.lastIdDnd+1,
            }
          }
        }

        
        
        return [newState]
      
      case types.DELETE_DND:
     
        
        let newDnds=state[0].caracteristicas.dataExercise.dnds.filter((elem)=>{
          return elem.id!=action.idItems;
        });

       
        newState={
          ...state[0],
          caracteristicas:{
            ...state[0].caracteristicas,
            dataExercise:{
              ...state[0].caracteristicas.dataExercise,
              dnds:[
                ...newDnds
              ],
              lastIdDnd:state[0].caracteristicas.dataExercise.lastIdDnd+1,
            }
          }
        }

        return [newState];

        case types.SET_FIGURE_SIZE:
          
          newState={
            ...state[0],
            caracteristicas:{
              ...state[0].caracteristicas,
              figureSize:action.newSize
            }
          }

        return [newState];
       
      default:
        return state;
    }
  };

  export default EstaFichasReducer;
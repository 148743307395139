import React, { useState } from 'react';
import './ImageMultimedia.scss';
import {idiomas} from '../../../../Constantes/idiomas';
import BackgroundImagenes from './BackgroundImagenes';

const ImageMultimedia=(props)=>{
    let {
        language, 
        multimedia
    }=props;

    const [currentImg, setCurrentImg] = useState(0);
    
    const getImageByLanguage = (image) => {
      let imageUrl = image.items.filter((elem)=>{
        return elem.language === language;

      });
      if(imageUrl.length === 0){
        imageUrl = image.items.filter((elem)=>{
          return elem.language === idiomas.castellano;
        });
      }
      return imageUrl[0].url;
    };

    const handlerPreviusImage = () => {
      setCurrentImg(currentImg - 1 < 0 ? multimedia.length - 1 : currentImg - 1);
    };

    const handlerNextImage = () => {
      setCurrentImg(currentImg + 1 >= multimedia.length ? 0 : currentImg + 1);
    };
      
    return (
      <BackgroundImagenes onLeft={handlerPreviusImage} onRight={handlerNextImage} buttons={Array.isArray(multimedia)}>
        {!Array.isArray(multimedia) &&
          (
            <img src={getImageByLanguage(multimedia)} alt=""/>
          )
        }
        {Array.isArray(multimedia) &&
          multimedia.map((mult, index) => {
            return (<img key={`multimedia-${index}`} className={index !== currentImg ? `disabled` : ``} src={getImageByLanguage(mult)} alt=""/>);
          })
        }
      </BackgroundImagenes>
    );
}

export default ImageMultimedia;
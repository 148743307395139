export const ruta={
    main:"localhost:3000/",
    editor:"editor/",
    fichas:"fichas",
    fichasPaginadas:"fichasPaginadas/",
    ficha:"ficha/",
    gererarFicha:"generarFicha/",
    recuperarRecursos:"recuperarRecursos/",
    subidaArchivos:"subidaArchivos/",
    subidaArchivoARecurso:"subidaArchivoARecurso/",
    recuperarRecursosItems:"recuperarRecursosItems/",
    borrarRecurso:"borrarRecurso/",
    getFicha:"getFicha/",
    getRecurso:"getRecurso/",
    getRecursosPaginados:"getRecursosPaginados/",
    getPagedMultimedia:"getPagedMultimedia/",
    upLoadLinkMultimedia:"upLoadLinkMultimedia/",
    getPagedMultimediaFilter:"getPagedMultimediaFilter/",
    viewer:"viewer/",
    getTemary:"getTemary/",
    publishFille:"publishFille/",
    unpublishFille:"unpublishFille/",
    setEditFile:"setEditFile/",
    getOrderedPagedMultimedia:"getOrderedPagedMultimedia",
    upDateMultimediaLink:"upDateMultimediaLink/",
    upDateMultimediaNoLink:"upDateMultimediaNoLink/",
    upDateMultimediaNoLinkItem:"upDateMultimediaNoLinkItem/",
    login:"auth/login",
    loginFront:"/login",
    deleteOldExercises:"deleteOldExercises/",
    deleteOldStaticsFiles:"deleteOldStaticsFiles/",

    setExercises:"setExercises",
    getExercises:"getExercises/",
    setStaticsFiles:"setStaticsFiles",
    getStaticsFiles:"getStaticsFiles/",

    getComentariosFichas:"getComentariosFichas/",
    postComentariosFichas:"postComentariosFichas",
    getComentariosFichasAlumno:"getComentariosFichasAlumno/",
};

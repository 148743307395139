import React, { useState } from 'react';

import {idiomas} from '../../../../Constantes/idiomas';
function PdfMultimedia(props) {
    let {
        language, 
        multimedia,
        
    }=props
  

    let imageUrl=multimedia.items.filter((elem)=>{
      return elem.language===language;
    });
    if(imageUrl.length===0){
      imageUrl=multimedia.items.filter((elem)=>{
        return elem.language===idiomas.castellano;
      });
    }

const onClickOpenPdf=()=>{
  window.open(imageUrl[0].url, "_blank");
}

let style={width:100+"%",height:80+"vh"}
  return (
    <div style={style}>
        <object data={imageUrl[0].url} type="application/pdf" width="100%" height="100%">
          <p>Pdf no encontrado</p>
        </object>
    </div>
  );
}

export default PdfMultimedia;
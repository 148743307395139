import React, {createRef, useEffect, useState} from 'react';
import './BackgroundMultimedia.scss';
import MultimediaBackground from '../../../../Images/fondo_multimedia.png';
import Flexo from '../../../../Images/flexo.png';

const BackgroundMultimedia = (props) => {
    let {children} = props;
    const containerRef = createRef();
    const [small, setSmall] = useState(false);

    useEffect(() => {
      const { current } = containerRef;
      const boundingRect = current.getBoundingClientRect();
      const { width, height } = boundingRect;
      setSmall(width < 300);
    }, [containerRef])

    return (
        <div ref={containerRef} className={`background-multimedia ${small ? "background-multimedia--small" :  ""}`} >
            <img className="background-multimedia__background" src={MultimediaBackground} alt="Multimedia background"/>
            {children}
            <img src={Flexo} className="background-multimedia__flexo" alt="Flexo background"/>
        </div>
    );
}

export default BackgroundMultimedia;

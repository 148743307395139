import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";


import Editor from './Componentes/ComponentesEditor/EditorControlador/EditorControlador';
import Fichas from './Componentes/FichasControlador/FichasControlador.jsx';
import Recursos from './Componentes/RecursosMultimedia/RecursosMultimediaControlador';
import Viewer from './Componentes/Viewer/ViewerController';
import Login from './Componentes/Login/Login';
import PublicRoute from './PublicRoute';
import './App.scss';


import OrgMainHorizontalPanel from './Componentes/Organisms/Org_MainHorizontalPanel/Org_MainHorizontalPanel';


function App() {
  
  return (
    <div>
      <Router >
        <div>
          <nav>
                
            <OrgMainHorizontalPanel></OrgMainHorizontalPanel>
            
          </nav>

          
          <Switch>
            
            <Route  path="/login">
              <Login />
            </Route>
            <PublicRoute
              key="Viewer"
              Component={Viewer}
              path="/viewer/:ficha_id"
            />
            <PublicRoute
              key="Editor"
              restricted
              Component={Editor}
              path="/editor/:ficha_id"
            />
            <PublicRoute
              key="Fichas"
              restricted
              Component={Fichas}
              path="/fichas"
            />
            <PublicRoute
              key="Recursos"
              restricted
              Component={Recursos}
              path="/recursos/"
            />
            
          </Switch>
        </div>
      </Router>
      <div id="modal"></div>
    </div>
  );
}

export default App;

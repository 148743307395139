import React, { useState } from 'react';


const DTContent =(props)=>{
     let {datos}=props;
               
    let content=datos.content;
        

    return(
        
        <td className="table-body__td">
            {content}
        </td>
        
    );
};

export default DTContent;
import { types } from '../Reducers/EstaFichasReducer';


export const getEstaFicha = (thisFicha) => ({
  type: types.GET_FICHA,
  payload: thisFicha,
});


export const increaseDndId = (items) => ({
  type: types.INCREASE_DND_ID,
  items:items
});

export const deleteDndId = (idItems) => ({
  type: types.DELETE_DND,
  idItems:idItems
});

export const setFigureSize = (newSize) => ({
  type: types.SET_FIGURE_SIZE,
  newSize:newSize
});

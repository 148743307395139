import React from 'react'


import EditNoLinkMultimedia from './EditNoLinksMultimedia';
import EditLinkMultimedia from './EditLinksMultimedia';


import {tipoRecurso} from '../../../Constantes/TiposRecursos';

const EditorMultimediaController=(props)=>{
    let {
        data
    }=props
;
    let constent;
    if(data.tipo===tipoRecurso.audios ||
        data.tipo===tipoRecurso.svg ||
        data.tipo===tipoRecurso.imagen ||
        data.tipo===tipoRecurso.pdf ){
            constent=<EditNoLinkMultimedia data={data}></EditNoLinkMultimedia>
        }
    
    if(data.tipo===tipoRecurso.spotify ||
        data.tipo===tipoRecurso.youtube ||
        data.tipo===tipoRecurso.vimeo ){
            constent=<EditLinkMultimedia data={data}></EditLinkMultimedia>
        }


    return(
        <>
            {constent}
        </>
    )
}


export default EditorMultimediaController;
import {httpGet,httpPost,httpDelete} from '../../Store/HttpCalls.jsx';
import {createFichas,removeFichas} from '../../Store/Actions/FichasActions.js';
import {reloadFichas} from '../../Store/Actions/FichasActions.js';
import {reloadTemary} from '../../Store/Actions/TemaryItemsActions';
import {ruta} from '../../Constantes/Rutas.js';


    export const crearFicha=(dispatch,newFicha)=>{
        
       
        return httpPost(ruta.ficha,newFicha).then((ficha)=>{
            
            return ficha;
            
        },(error)=>{
            console.error("nuestro error " + error)
        });

    };

    export const setEditFile=(id,newFicha,dispatch)=>{
        
       
        return httpPost(ruta.setEditFile+id,newFicha).then((ficha)=>{

            httpGet(ruta.fichas).then((fichasP)=>{
                if(dispatch!==undefined){
                    dispatch(reloadFichas(fichasP));
                }
                
            },(error)=>{
                console.error("nuestro error " + error)
            })
            
            
        },(error)=>{
            console.error("nuestro error " + error)
        })

    };

    export const eliminarFicha=(id,dispatch)=>{
        
        httpDelete(ruta.ficha+id).then(()=>{
            dispatch(removeFichas(id));

            httpGet(ruta.fichas).then((fichasP)=>{
                dispatch(reloadFichas(fichasP));
            },(error)=>{
                console.error("nuestro error " + error)
            })

        },(error)=>{
            console.error("nuestro error " + error)
        });

    };
    
   export const publishFille=(id)=>{
        const promise = httpPost(ruta.publishFille+id);
        promise.then(()=>{
            
        },(error)=>{
            console.log("nuestro error ", error);
            alert(error);
        })
        return httpPost(ruta.publishFille+id);
    };

    export const unpublishFille=(id)=>{
        const promise = httpPost(ruta.unpublishFille+id);
        promise.then(()=>{
            
        },(error)=>{
            console.error("nuestro error " + error)
        })
        return promise;
    };

    export const getTemary=(dispatch)=>{
        
        httpGet(ruta.getTemary).then((temary)=>{
            let temario=temary.map((elem)=>{
                return {value:elem.id,text:elem.tema}
            });
            dispatch(reloadTemary(temario));
            
        },(error)=>{
            console.error("nuestro error " + error)
        })

    }



    
import React from 'react';

import './At_BoldText.scss';

const At_BoldText =(props)=>{
    let {
        children
    }=props

    return (
        <label className="at-bold-text">   
            {children}
        </label>
    )
}
    

export default At_BoldText;
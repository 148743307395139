import React from 'react';

import './SpotifyMultimedia.scss';

import SpotifyPlayer from 'react-spotify-player';
import {idiomas} from '../../../../Constantes/idiomas';
import BackgroundMultimedia from './BackgroundMultimedia';

const SpotifyMultimedia=(props)=>{
    let {
        language, 
        multimedia
    }=props
    
    const size = {
        width: '100%',
        height: 80,
      };
    const view = 'list'; // or 'coverart'
    const theme = 'black'; // or 'white'
       
    let imageUrl=multimedia.items.filter((elem)=>{
        return elem.language===language;

    });
    if(imageUrl.length===0){
        imageUrl=multimedia.items.filter((elem)=>{
          return elem.language===idiomas.castellano;
        });
      }

     

    return (
      <BackgroundMultimedia>
        <div className="spotify-player">
          <SpotifyPlayer
            uri={imageUrl[0].link}
            size={size}
            view={view}
            theme={theme}
          />
        </div>
      </BackgroundMultimedia>
    )
}

export default SpotifyMultimedia;
import React from 'react';
import {DragDrop} from '@uppy/react';

const UpLoadBox=(props)=>{
    let {
        uppy
    }=props

    return (
        
            <div className="up-load-multimedia__upload-box">
            <DragDrop
                    uppy={uppy}
                    locale={{
                    strings: {
                        // Text to show on the droppable area.
                        // `%{browse}` is replaced with a link that opens the system file selection dialog.
                        dropHereOr: 'Arrastra aquí o %{browse} para subir archivos',
                        // Used as the label for the link that opens the system file selection dialog.
                        browse: 'explora',
                    },
                    }}
                />
                </div>
        
    )
}


export default UpLoadBox;
import React from 'react';



import './At_Text.scss';

const At_Text =(props)=>{
    let {
        children,
        
    }=props

    return (
        <label className="at-text">{children}</label>
    )
}

export default At_Text;
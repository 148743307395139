import React, { useState } from 'react';

import {tipoRecurso} from '../../../Constantes/TiposRecursos';

import ImgAudio from '../../../Images/Recurso-Audio.png';
import ImgPDF from '../../../Images/Recurso-PDF.png';
import ImgSpotify from '../../../Images/Recurso-Spotify.png';
import ImgVimeo from '../../../Images/Recurso-Vimeo.png';
import ImgYoutube from '../../../Images/Recurso-Youtube.png';
import BandcampImg from '../../../Images/Recurso-Bandcamp.png';


const DTImageMultimedia =(props)=>{
    let {datos}=props
    let image,width,height;
    
    if (datos!=null) {
        width=datos.width;
        height=datos.height;



        switch (datos.multimedia.tipo) {
            case tipoRecurso.audios:
                image=ImgAudio;
                break;

            case tipoRecurso.imagen:
                if(datos.multimedia.items[0]!= null)
                    image=datos.multimedia.items[0].url;
                break;

            case tipoRecurso.svg:
                image=datos.multimedia.items[0].url;
                break;

            case tipoRecurso.pdf:
                image=ImgPDF;
                break;

            case tipoRecurso.spotify:
                image=ImgSpotify;
                break;

            case tipoRecurso.vimeo:
                image=ImgVimeo;
                break;
            case tipoRecurso.bandcamp:
                image=BandcampImg;
                break;

            case tipoRecurso.youtube:
                image=ImgYoutube;
                break;

            default:
                break;
        }


    }else{
        image="";
    }
        
        
      
    return(
        
        <td className="table-body__td">
            <img width={width} height={height} src={image}></img>
        </td>
        
    );
};

export default DTImageMultimedia;
import React from 'react';
import './At_Select.scss';



const At_Select =(props)=>{
    let {
        text,
        values,
        onChange,
        widthSelect,
        fontSize,
        className,
        defaultValue,
        children,
    } = props

    let i=-1;

    if(values==null){
        values=[]
    }
    
    let styleSelect;

    if(widthSelect!=null){
        styleSelect={
            ...styleSelect,
            width:widthSelect+"px"
        }
    }

    let styleSpan={
        fontSize:fontSize+"px",
       
    }


    return (
        <div className={'at-select ' + className}>
            <label style={styleSpan} className="at-select__span"> {children} </label>
            <select style={styleSelect} className='at-select__select' onChange={onChange}>
                {
                    values.map((elem)=>{
                        i++;
                        if(elem[0]==defaultValue){
                            return <option  key={elem[0]+elem[1]+i} selected value={elem[0]}>{elem[1]}</option>
                        }else{
                            return <option  key={elem[0]+elem[1]+i} value={elem[0]}>{elem[1]}</option>
                        }
                        
                    })
                }
            </select>
            
            
        </div>
     
            
    )
}

export default At_Select;
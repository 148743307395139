import React,{useState,useEffect} from "react";
import { BrushPreview } from "./brushPreview";
import DraggableEdit from 'react-draggable';
import Brush from '../../../Images/PaintTool1.png';
import Palet from '../../../Images/PaintTool2.png';
import Tool from '../../../Images/PaintTool3.png';
import Pencil from '../../../Images/PaintTool4.png';
import Eraser from '../../../Images/PaintTool5.png';
import BigBrush from '../../../Images/PaintTool6.png';
import ButtonOpen from '../../../Images/BotonAbrirPincel.png';
import ButtonClose from '../../../Images/BotonCerrarPincel.png';
import PencilClose from '../../../Images/PincelCerrado.png';
import {rgbToHex} from '../../../Services/CSSTratamiento';

export const Toolbar = ({
  currentWidth,
  currentColor,
  handleDownload,
  dateUrl,
  handleClear,
  handleSpecialMode,
  handleEraserMode,
  setAutoWidth,
  handleRegularMode,
  handleColor,
  handleWidth,
  setCurrentSaturation,
  setCurrentLightness,
  isRegularMode,
  isAutoWidth,
  isEraser,
}) => {
  const [localWidth,setLocalWidth]=useState(currentWidth);
  const [localColor,setLocalColor]=useState(currentColor);
  
  const handleLocalWidth=(newWidth)=>{
    setLocalWidth(newWidth);
    handleWidth(newWidth);
  }

  const handlerLocalColor = (event) => {
    let newColor=getComputedStyle(event.currentTarget).backgroundColor;
    setLocalColor(newColor);
    handleColor(
      rgbToHex(newColor)
    );
  }

  const [imgButtonOpenCLose,setImgButtonOpenCLose]=useState(ButtonClose);
  const [toolbarOpenClose,setToolbarOpenClose]=useState(true);

  const onClickOpenClose=()=>{

    if(imgButtonOpenCLose==ButtonClose){
      setImgButtonOpenCLose(ButtonOpen);
      setToolbarOpenClose(false);
    }

    if(imgButtonOpenCLose==ButtonOpen){
      setImgButtonOpenCLose(ButtonClose);
      setToolbarOpenClose(true);
    }
  }

  
  return (
  
    <DraggableEdit bounds='.viewerController' cancel='.painter__toolbar-range'>
      
      <div className="painter__toolbar">
        
        <div>
          <button className="painter__button-open-close" onTouchStart={onClickOpenClose} onClick={onClickOpenClose}>
            <img src={imgButtonOpenCLose} alt="" />
          </button>
        </div>
        <div className={"painter__toolbar-close " + (toolbarOpenClose ? "deactivate":"")}>
          <img width="20px" src={PencilClose} alt=""/>
        </div>
        {toolbarOpenClose ? 
        <>
        <div className="painter__toolbar-section">
          <div className="painter__toolbar-title">
            <div className="painter__toolbar-title-icon">
              <img src={Brush} alt="BrushIcon"/>
            </div>
            <label>
              Pincel y color
            </label>
          </div>
          <BrushPreview currentWidth={localWidth} currentColor={localColor} />
        </div>
        <div className="painter__toolbar-section">
          <div className="painter__toolbar-title">
            <div className="painter__toolbar-title-icon">
              <img src={Palet} alt="PaletIcon"/>
            </div>
            <label>
              Colores
            </label>
          </div>
          <div className="painter__toolbar-colors">
            <span className="painter__toolbar-colors-1" onTouchStart={handlerLocalColor} onClick={handlerLocalColor}></span>
            <span className="painter__toolbar-colors-2" onTouchStart={handlerLocalColor} onClick={handlerLocalColor}></span>
            <span className="painter__toolbar-colors-3" onTouchStart={handlerLocalColor} onClick={handlerLocalColor}></span>
            <span className="painter__toolbar-colors-4" onTouchStart={handlerLocalColor} onClick={handlerLocalColor}></span>
            <span className="painter__toolbar-colors-5" onTouchStart={handlerLocalColor} onClick={handlerLocalColor}></span>
            <span className="painter__toolbar-colors-6" onTouchStart={handlerLocalColor} onClick={handlerLocalColor}></span>
            <span className="painter__toolbar-colors-7" onTouchStart={handlerLocalColor} onClick={handlerLocalColor}></span>
            <span className="painter__toolbar-colors-8" onTouchStart={handlerLocalColor} onClick={handlerLocalColor}></span>
          </div>
        </div>
        <div className="painter__toolbar-section">
          <div className="painter__toolbar-title">
            <div className="painter__toolbar-title-icon">
              <img src={BigBrush} alt="BigBrushIcon"/>
            </div>
            <label>
              Tamaño pincel
            </label>
          </div>
          {!isAutoWidth && (
            <input
              className="painter__toolbar-range"
              defaultValue="10"
              type="range"
              min="2"
              max="90"
              onChange={event=>handleLocalWidth(event.target.value)}
            />
        )}
        </div>
        
        <div>
          <button className="painter__toolbar-eraser-all" onTouchStart={handleClear} onClick={handleClear}>
            Borrar todo
          </button>
        </div>
        </>
        :
        ""}
      
      </div>
      
    </DraggableEdit>
    
  );
};

export default Toolbar;

import React, { useState } from 'react';
import AtImage, {ImageIcon} from '../../Atoms/At_Image/At_Image';
import './BTButton.scss';


const DT_Boton =(celda)=>{
     
                    
    const nombreBoton=celda['datos'].nombreBoton;
    const funcionBoton=celda['datos'].fctBoton;
    const icon = celda['datos'].icon;

    return(
        
        <td className="table-body__td dt-boton">
            {icon !== undefined && (
                <AtImage className="dt-boton__img" image={icon}  width="16px" height="16px" />
            )}
            <button className="dt-boton__button" onClick={funcionBoton}>{nombreBoton}</button>
        </td>
        
    );
};

export default DT_Boton;
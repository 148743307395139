import React from 'react';
import './VimeoMultimedia.scss';
import Vimeo from '@u-wave/react-vimeo';
import {idiomas} from '../../../../Constantes/idiomas';
import BackgroundMultimedia from './BackgroundMultimedia';

const VimeoMultimedia=(props)=>{
    let {
        language, 
        multimedia
    }=props
    
       
    let imageUrl=multimedia.items.filter((elem)=>{
        return elem.language===language;

    });
    if(imageUrl.length===0){
        imageUrl=multimedia.items.filter((elem)=>{
          return elem.language===idiomas.castellano;
        });
    }

    const urlVimeo=imageUrl[0].link.split("=");
    const urlVimeoSplited = urlVimeo[urlVimeo.length-1].split('/');
    const vimeoId = urlVimeoSplited[urlVimeoSplited.length - 1];
      
    return (
        <BackgroundMultimedia>
            <div className="youtube-player__iframe">
                <iframe 
                    title="Vimeo Video" 
                    src={`https://player.vimeo.com/video/${vimeoId}`} 
                    width="100%" 
                    height="100%" 
                    frameborder="0" 
                    webkitallowfullscreen 
                    mozallowfullscreen allowFullScreen 
                />
            </div>
        </BackgroundMultimedia>
    );
}

export default VimeoMultimedia;
import React,{ useState,useEffect } from 'react';

import {useDispatch,useSelector} from 'react-redux';

import DraggableEdit from 'react-draggable';


import {seleccionarElemento} from '../../../Store/Actions/ElemSeleccionadoAction.js'

import {editarPosicionAc} from '../../../Store/Actions/EditorElementosActions.js';
import {tipoElemento} from '../../../Constantes/Elementos';

import ElementMultimedia from './ElementMultimedia';
import ImageElement from './ElementsType/ImageElement';
import ElementExercise from './ElementExercise';
import DraggableElement from './DndElements/DraggableElement';

import {textToHtml} from './FunctionsElements';
import {seleccionarIdiomaTextoEstatico,selectEnrichedText} from '../../../Services/FuncionesIdiomas';
import {eventsAnimation} from '../../../Constantes/Animation';

import '../../../Styles/Animations.scss';
import './ElementoBase.scss';

import TestImage from '../../../Images/ExerciseResponse2.png'

    const ElementoBase = (props) => {
        let {
            
            X,
            Y,
            ID,
            tipoSeleccionado,
            textoEstatico,
            estiloHijo,
            idiomaFicha,
            isMultimedia,
            property,
            canvasSize,
            viewer,
            dndExercise,
            selectedElement,
            setCursorVisivility,
            scaleCanvas,
            correctExercises,
            answerExercises

          } = props;
          
          

        let dispatch=useDispatch();
        
        const elementosEditor=useSelector((state)=>{
          
          return state.elementosEditor.find((elem)=>{return elem.ID===ID});
        },);
        

        let[Arrastrable,setArrastrable]=useState(viewer);
        
        

        let[posicion,setPosicion]=useState({x:X,y:Y});

        const editarPosicion = (Xx,Yy) => {
          
          setPosicion({x:Xx,y:Yy});

        };    

        useEffect(() => {
          
          editarPosicion(elementosEditor.X,elementosEditor.Y);
        },[elementosEditor.X, elementosEditor.Y] );

        const getTransforYX =()=>{
          let total = document.getElementById(ID).style.transform.length;
          let cadena = document.getElementById(ID).style.transform;
          cadena = cadena.substring(10,total-3);
          cadena = cadena.split(" ");
          cadena[0]=cadena[0].substring(0,cadena[0].length-3);
          cadena[0]=Number(cadena[0]);
          cadena[1]=Number(cadena[1]);
          return cadena;
          
        };

        
        const onStropDragg=()=>{
          
          let posiciones=getTransforYX();
          dispatch(editarPosicionAc({ID:ID,X:Number(posiciones[0]),Y:Number(posiciones[1])},canvasSize,estiloHijo));
          
        };


        const onClickSeleccionarElemento=()=>{

          dispatch(seleccionarElemento(ID));
          
        };
        let contenido,textStatic;


            if(tipoElemento.TextoEstatico==tipoSeleccionado){
              textStatic=seleccionarIdiomaTextoEstatico(idiomaFicha,textoEstatico);
            }

            if(tipoElemento.EnrichedText==tipoSeleccionado){
              
              
              let doc = selectEnrichedText(idiomaFicha,textoEstatico);
              
              textStatic=textToHtml(doc);
              

            }

            if(tipoElemento.Circulo==tipoSeleccionado){
              textStatic=textoEstatico;
            }

            if(tipoElemento.Cuadrado==tipoSeleccionado){
              textStatic=textoEstatico;
            }

            if(tipoElemento.Image==tipoSeleccionado){
              textStatic=<ImageElement style={estiloHijo} language={idiomaFicha} image={property.image}></ImageElement>;
            }


            if(isMultimedia==1){
              let openDelay;
              if( property!=undefined && property.animation!=undefined && property.animation.event===eventsAnimation.onClickEvent){
                openDelay=4000;
              }
              contenido= <ElementMultimedia openDelay={openDelay} language={idiomaFicha} typeElemento={tipoSeleccionado}  multimedia={property.multimedia} textStatic={textStatic}></ElementMultimedia>;
              
            }else{
              contenido=textStatic;
            }
        
            if(property!=undefined && property.exercise!=undefined){
              if(viewer){
                delete estiloHijo.border;
              }
              contenido=<ElementExercise answerExercises={answerExercises} correctExercises={correctExercises} scaleFile={scaleCanvas} styleElement={estiloHijo} idElement={ID} viewer={viewer} language={idiomaFicha} exercise={property.exercise} dndExercise={dndExercise} />;
            }

        
        const onclickgeneral=()=>{
          onClickSeleccionarElemento();
          
        }
      
        
    
        
        const [styleAnimation,setStyleAnimation]=useState();
        
        const onClickAnimation=()=>{
          if( property!==undefined && property.animation!==undefined && property.animation.event===eventsAnimation.onClickEvent){
            setStyleAnimation({
              animation: property.animation.animation + " 5s infinite",
              width:estiloHijo.width,
              height:estiloHijo.height
            });

            setTimeout(() => {
              setStyleAnimation({
              });
            }, 5000);


          }
        }

        const onMouseEnter=()=>{
          if( property!=undefined && property.animation!=undefined && property.animation.event===eventsAnimation.hoverEvent){
            setStyleAnimation({
              animation: property.animation.animation + " 5s infinite",
              width:estiloHijo.width,
              height:estiloHijo.height
            });
          }
        }

        const onMouseLeave=()=>{
          if( property!=undefined && property.animation!=undefined && property.animation.event===eventsAnimation.hoverEvent){
            setStyleAnimation({});
          }
        }

        

        useEffect(() => {
          setStyleAnimation({
            ...styleAnimation,
            width:estiloHijo.width,
            height:estiloHijo.height
          });

          if(property!=undefined){
            if(property.animation!=undefined){
              
              switch (property.animation.event) {
                case eventsAnimation.alwaisEvent:
                  setStyleAnimation({
                    animation: property.animation.animation + " 5s infinite",
                    width:estiloHijo.width,
                    height:estiloHijo.height
                  });
                  break;

                case eventsAnimation.delay:
                  setStyleAnimation({
                    animation: property.animation.animation + " 5s infinite",
                    width:estiloHijo.width,
                    height:estiloHijo.height,
                    animationDelay: "2s",
                  });
                  break;

                case eventsAnimation.hoverEvent:
                  setStyleAnimation({
                    animation: property.animation.animation + " 5s infinite",
                    width:estiloHijo.width,
                    height:estiloHijo.height,
                  });
                  

                  break;

                case eventsAnimation.onClickEvent:
                  setStyleAnimation({
                    animation: property.animation.animation + " 5s",
                    width:estiloHijo.width,
                    height:estiloHijo.height,
                  });
                  
                  break;

                default:
                  
                  setStyleAnimation({});
                  break;
              }
              
            }
          }
        
        },[property,estiloHijo] );

        let [outlineSelectElement,setOutlineSelectElement]=useState({});
        useEffect(() => {
          
          if(viewer!=true && selectedElement==ID){
            
            setOutlineSelectElement({
              outline: "thick double #32a1ce"
            });

          
          }else{
            setOutlineSelectElement({});
          }
        }, [selectedElement])

        

        let styleZIndex={
          zIndex:estiloHijo.zIndex,
          position:"absolute",
        }

        

      let thisElement=<div id={ID}   className="divPadre" onClick={onclickgeneral}  style={{...styleZIndex,...outlineSelectElement}}>
                        <div style={styleAnimation} 
                          onClick={onClickAnimation} 
                          onMouseEnter={onMouseEnter} 
                          onMouseLeave={onMouseLeave}>
                          <div id="contenido" style={estiloHijo} >
                              {contenido}
                          </div>
                        </div>
                      </div>
       
        

        if(viewer){
          //---------------------------------------------------------------------------------------------------------------------
          
          if(property.isDraggable){
            
            
            let paint;
            if(dndExercise!==undefined){
              paint=dndExercise.dnds.filter((elem)=>{
                return elem.id===property.idDroppable;
              });
            }
           
            thisElement=<DraggableElement
                            property={property}
                            paint={paint}
                            ID={ID}
                            onclickgeneral={onclickgeneral}
                            styleZIndex={styleZIndex}
                            styleAnimation={styleAnimation}
                            onClickAnimation={onClickAnimation}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                            estiloHijo={estiloHijo}
                            position={posicion}
                            scaleFile={scaleCanvas}
                            correctExercises={correctExercises}
                          >{contenido}</DraggableElement>
                        
         
          }else{
            if(property!==undefined && property.exercise!==undefined){
              let positionStyleExercise={
                position:"relative",
                left:posicion.x+"px",
                top:posicion.y+"px",
            }
              
              //thisElement=<div style={positionStyleExercise} >{thisElement}</div>;
              thisElement=<DraggableEdit   onStop={onStropDragg}   position={posicion}   handle={Arrastrable} >
                        {thisElement}
                      </DraggableEdit>
            }else{
              
              if(property!==undefined && property.isDndFree!==undefined && property.isDndFree==true){
                
                thisElement=<DraggableEdit onStop={onStropDragg} scale={scaleCanvas} position={posicion} handle={undefined}>
                                {thisElement}
                            </DraggableEdit>
                
              }else{
                
                thisElement=<DraggableEdit onStop={onStropDragg}  position={posicion} handle={Arrastrable} >
                              {thisElement}
                          </DraggableEdit>
              }
            }
            
          }

          //---------------------------------------------------------------------------------------------------------------------
        }else{
          
          thisElement=<DraggableEdit   onStop={onStropDragg}   position={posicion}   handle={Arrastrable} >
                        {thisElement}
                      </DraggableEdit>
                      
          
        }

        
       
      return thisElement;

    };
  

export default ElementoBase;


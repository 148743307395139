import React,{useEffect,useState} from 'react';
import {useDispatch,useSelector,shallowEqual} from 'react-redux';


import AtPopup from '../Atoms/At_Popup/At_Popup';

import {ruta} from '../../Constantes/Rutas.js'
import {resetExercisesResult} from '../../Store/Actions/ExerciseResultsActions';
import {SendExercisesToBbdd,setStaticsFiles} from './SendExercisesToBbdd';
import {exercises} from '../../Constantes/Exercises';
import {idiomas} from '../../Constantes/idiomas';

import ImgExerciseCorrectCastellano from '../../Images/correcto_castellano.png';
import ImgExerciseCorrectValenciano from '../../Images/correcto_val.png';
import ImgExerciseIncorrectCastellano from '../../Images/incorrecto_castellano.png';
import ImgExerciseIncorrectValenciano from '../../Images/incorrecto_val.png';

import CorrectSound from '../../Audio/SONIDO_ACIERTO.mp3';
import IncorrectSound from '../../Audio/SONIDO_ERROR.mp3';


import './ExercisesCorrectController.scss';


const ExercisesCorrectController=(props)=>{
    const {
        correctExercises,
        isCorrectExercises,
        fichaId,
        idiomFile,
        idStudent,
        staticsFiles
    }=props;
   
    const dispatch=useDispatch();

    const exerciseResults=useSelector((state)=>{
        return state.exerciseResults;
    },shallowEqual);

    const [thisExerciseResults,setThisExerciseResults]=useState([]);
    const [allRight,setAllRight]=useState(false);

    const [imgCorrectExercise,setImgCorrectExercise]=useState(ImgExerciseCorrectCastellano);
    const [imgIncorrectExercise,setImgIncorrectExercise]=useState(ImgExerciseIncorrectCastellano);
    
    const audioCorrect=new Audio(CorrectSound);
    const audioIncorrect=new Audio(IncorrectSound);

    useEffect(() => {
        switch (idiomFile) {
            case idiomas.castellano:
                setImgCorrectExercise(ImgExerciseCorrectCastellano);
                setImgIncorrectExercise(ImgExerciseIncorrectCastellano);
                break;

            case idiomas.valenciano:
                setImgCorrectExercise(ImgExerciseCorrectValenciano);
                setImgIncorrectExercise(ImgExerciseIncorrectValenciano);
                break;
        
            default:
                setImgCorrectExercise(ImgExerciseCorrectCastellano);
                setImgIncorrectExercise(ImgExerciseIncorrectCastellano);
                break;
        }
    }, [idiomFile])


    useEffect(() => {
        setThisExerciseResults(exerciseResults);
        
    }, [exerciseResults]);

    useEffect(() => {
        
        if(correctExercises==true){
            //mandar estadisticas staticsFiles
            setStaticsFiles(idStudent,fichaId,staticsFiles);
            SendExercisesToBbdd(thisExerciseResults,idStudent,fichaId);
            iscorrectExercises(thisExerciseResults);
        }
    }, [thisExerciseResults]);

    const iscorrectExercises=(thisExerciseResults)=>{
        let newAllRight=true;
        
        thisExerciseResults.map((elem)=>{
           
            switch (elem.typeExercise) {
                case exercises.textSelect:
                    if(elem.isCorrect!=true){
                        newAllRight=false;
                    }
                    break;
                case exercises.imagesTest:
                    if(elem.isCorrect!=true){
                        newAllRight=false;
                    }
                    break;
                case exercises.fillableField:
                    if(elem.isCorrect!=true){
                        newAllRight=false;
                    }
                    break;
                case exercises.dragAndDrop:
                    if(elem.isCorrect!=true){
                        newAllRight=false;
                    }
                    break;
                default:
                    break;
            }
            
        });
        if(newAllRight){
            audioCorrect.play();
        }else{
            audioIncorrect.play();
        } 
        setAllRight(newAllRight);
    }

    const onClickAcceptCorrection=()=>{
        isCorrectExercises(false);
        dispatch(resetExercisesResult());
    }

    useEffect(() => {
        if(thisExerciseResults[0]===undefined){
            setAllRight(true);
        }
    }, [correctExercises]);
    
    
    if(correctExercises){
       

        return <div className="exercises-correct-controller">
                    <div className="exercises-correct-controller__content">
                        <button className="exercises-correct-controller__content__btn" onClick={onClickAcceptCorrection}>
                            <img height="450px" src={allRight ? imgCorrectExercise : imgIncorrectExercise} alt="allRight"></img>
                        </button>
                    </div>
                </div>
                
    }else{
        return <></>
    }
    
    
}

export default ExercisesCorrectController;
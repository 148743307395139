import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector,shallowEqual} from 'react-redux';

import './EditNoLinksMultimedia.scss';

import Uppy from '@uppy/core';
import XHRUpload  from '@uppy/xhr-upload';
import Dashboard from '@uppy/dashboard'
import { DragDrop, ProgressBar, StatusBar} from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/progress-bar/dist/style.css';
import '@uppy/status-bar/dist/style.css';

import { httpPost} from '../../../Store/HttpCalls';

import {ruta} from '../../../Constantes/Rutas.js';
import {reloadRecursos} from '../../../Store/Actions/RecusosActions.js';
import {httpGet} from '../../../Store/HttpCalls.jsx';
import {idioms,idiomas} from '../../../Constantes/idiomas';
import {typeCss} from '../../../Constantes/TypeCss';
import {tipoRecurso} from '../../../Constantes/TiposRecursos';
import NoLinksMultimedia from '../UploadMultimediaItems/NoLinksMultimedia';

import AtSelect from '../../Atoms/At_Select/At_Select';
import AtTitle from '../../Atoms/At_Title/At_Title';
import AtInputText from '../../Atoms/At_InputText/At_InputText';
import AtButton from '../../Atoms/At_Button/At_Button';


import {ImageIcon} from '../../Atoms/At_Image/At_Image';

import UpLoadBox from './UpLoadBox';
import ImgAudio from '../../../Images/Recurso-Audio.png';
import ImgPDF from '../../../Images/Recurso-PDF.png';

let archivosParaEnvio=[];
let newId = 0;
let filesForUpload=[],languagesForUpdate=[]; // Arrays para almacenar info que se ha de actualizar

const EditNoLinksMultimedia=(props)=>{
    /*let {
        onChangeLanguage,
        files,
        onClickDelete,
        typeMultimedia
    } =props*/
    let {
        data,finished
    }=props;

    const dispatch=useDispatch();
    const [recursoData,setRecursoData]=useState(data); //Creamos objeto recursoData para actualizar los campos
    //const [archivos,setArchivos]= useState([]); //Objeto para almacenar nuevos archivos
    const [fileForSend,setFileForSend]=useState([]);
    const [uploadRuta,setUploadRuta]=useState(ruta.upDateMultimediaNoLink);//Array para archivos subidos
    const [opStatus, setOpStatus] =useState('new');
    let upLoadNoLinks;
    /*useEffect(() => {
        archivosParaEnvio=[]
        newId=0;
    }, []);*/

    const updateMultimediaInTable=()=>{
        
        httpGet(ruta.getPagedMultimedia).then((recursos)=>{
            
            dispatch(reloadRecursos(recursos));

        },(error)=>{
            console.error("nuestro error " + error)
        })
    }

    const onClickDeleteMultimediaPrev=(id)=>{
            
        let newFilesToSend=archivosParaEnvio.filter((elem)=>{
            return elem.id!==id;
        });

        archivosParaEnvio=newFilesToSend;
        
        let newArchivos=newFilesToSend.map((elem)=>{
            return elem.nombre;
        });

        setFileForSend(newFilesToSend);
        //setArchivos(newArchivos);
    }

    const onChangeCambioIdiomaRecurso=(nombre,nuevoIdioma)=>{
        
        let indice=archivosParaEnvio.findIndex((elem)=>{
            return elem.nombre==nombre;
        });
      
        archivosParaEnvio[indice].language=nuevoIdioma;
    };

    /* Según el tipo de recurso previsualizamos la imagen o un archivo por defecto */
    let nameButton,imageItem,imageOrIcon;
    switch (data.tipo) {
        case tipoRecurso.audios:
            nameButton="audio";
            imageItem=ImgAudio;
            imageOrIcon=false;
            upLoadNoLinks= <NoLinksMultimedia typeMultimedia={tipoRecurso.audios} onClickDelete={onClickDeleteMultimediaPrev} files={fileForSend} onChangeLanguage={onChangeCambioIdiomaRecurso}></NoLinksMultimedia>
            break;
        case tipoRecurso.imagen:
            nameButton="imagen";
            imageOrIcon=true;
            upLoadNoLinks= <NoLinksMultimedia typeMultimedia={tipoRecurso.imagen} onClickDelete={onClickDeleteMultimediaPrev} files={fileForSend} onChangeLanguage={onChangeCambioIdiomaRecurso}></NoLinksMultimedia>
            break;
        case tipoRecurso.pdf:
            nameButton="pdf";
            imageItem=ImgPDF;
            imageOrIcon=false;
            upLoadNoLinks= <NoLinksMultimedia typeMultimedia={tipoRecurso.pdf} onClickDelete={onClickDeleteMultimediaPrev} files={fileForSend} onChangeLanguage={onChangeCambioIdiomaRecurso}></NoLinksMultimedia>
            break;
        case tipoRecurso.svg:
            nameButton="svg";
            imageOrIcon=true;
            upLoadNoLinks= <NoLinksMultimedia typeMultimedia={tipoRecurso.svg} onClickDelete={onClickDeleteMultimediaPrev} files={fileForSend} onChangeLanguage={onChangeCambioIdiomaRecurso}></NoLinksMultimedia>
            break;
        default:
            nameButton="";
            imageOrIcon=false;
            upLoadNoLinks= <div></div>
            break;
    }
    /* Actualizar input nombre recurso */
    const onChangeName=(newNombre)=>{
        let newNombreRecurso={
            ...recursoData,
            nombre:newNombre
        }
        console.log(newNombre);
        setRecursoData(newNombreRecurso);

    };
    /* Actualizar el idioma de un item de recurso */
    const onChangeLanguage=(idItem,newLanguage)=>{
        debugger
        let existsInArray=languagesForUpdate.filter((elem)=>{
            return elem.idMultimediaItem===idItem;
        });

        if(existsInArray[0]===undefined){//Si no existe el elemento lo añadimos
            languagesForUpdate=[...languagesForUpdate,
                {
                    idMultimediaItem:idItem,
                    newLanguage:newLanguage,
                }
            ];
        }else{ //Si existe lo actualizamos
            languagesForUpdate=languagesForUpdate.map((elem)=>{
                if(elem.idMultimediaItem===idItem){
                    return {
                        ...elem,
                        newLanguage:newLanguage,
                    };
                }
                return elem;
            });
        }
        debugger
    }
    /* Cambiar el archivo del item */
    const onChangeAddNewMultimedia=(idItem,newFile)=>{

        let existsInArray=filesForUpload.filter((elem)=>{
            return elem.idMultimediaItem===idItem;
        });
        debugger
        if(existsInArray[0]===undefined){
            filesForUpload=[...filesForUpload,
                {
                    idMultimediaItem:idItem,
                    file:{
                        data:newFile[0],
                    },
                }
            ];
            debugger
        }else{
            filesForUpload=filesForUpload.map((elem)=>{
                if(elem.idMultimediaItem===idItem){
                    return {
                        ...elem,
                        file:{
                            data:newFile[0],
                        },
                    };
                }
                return elem;
            });
            debugger
        }

    }

    /* Crear un nuevo item de recurso */

    const uppy = Uppy({
        autoProceed: false,
        allowMultipleUploads: true,
        
    });

    uppy.use(XHRUpload, {
        endpoint: process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_API_ENDPOINT + uploadRuta,
        formData: true,
        fieldName: ['name1','name2'],
        bundle:true
    });
    uppy.on('upload', (result) => {
    });

    uppy.on('upload-error', (file, error) => {

    //console.error('error with file:', file.id);
    //console.error('error message:', error);

    });
    uppy.on('file-added', (file) => {
        debugger
        if(opStatus == 'new'){
            archivosParaEnvio=[...archivosParaEnvio,
                {
                id:newId,
                nombre:file.name,
                language:idiomas.castellano,
                archivo:file}
            ];
            newId++;
            debugger
            setFileForSend(archivosParaEnvio);
            debugger
        }
       
        //setArchivos([...archivos,file.name]);
        //debugger
        //console.log(archivos);
        //console.log(archivosParaEnvio);
    });
    uppy.on('upload-success', (file, result) => {
    });

    uppy.on('complete', (result) => {
        updateMultimediaInTable();
    });
    
    
    let arrayIds={},indexArray=0,arrayIdsRegistry=[];
    const addIdArray=(newId)=>{
        
        let existsInArray=arrayIdsRegistry.filter((elem)=>{
            return elem===newId;
        });

        if(existsInArray[0]===undefined){
            
            arrayIdsRegistry=[...arrayIdsRegistry,newId];
            arrayIds={...arrayIds,["ids["+indexArray+"]"]:newId};
            indexArray++;
            debugger
        }
    }
   
   /* Guardar los cambios */
   const onClickSendUpdates=()=>{
    if(archivosParaEnvio.length>0){
        guardarNuevosItemsRecurso();
    }        
    guardarCambiosItemsRecurso();

   }
   const guardarCambiosItemsRecurso=()=>{
        let idUppy;
         //Archivos modificados en filesForUpload
         filesForUpload.map((elem)=>{
            debugger
            setOpStatus('update');
            idUppy=uppy.addFile(elem.file);
            debugger
            uppy.setFileMeta(idUppy,{idItem:elem.idMultimediaItem});
            debugger
            addIdArray(elem.idMultimediaItem);
            debugger
        });
        
        let i,paqueteEnvio=uppy.getFiles(),languages,thisId;

        setUploadRuta(ruta.upDateMultimediaNoLink);
        for(i=0;i<paqueteEnvio.length;i++){
            
            thisId=paqueteEnvio[i].meta.idItem;

            uppy.setFileState(paqueteEnvio[i].id, {
                xhrUpload: { fieldName: "newFile-"+thisId }
            });
            
        }
        
        /*
        if(paqueteEnvio.length>0){
            uppy.setMeta({
                ...arrayIds,
                typeMultimedia:data.tipo,
                numberOfMultimedia:arrayIdsRegistry.length,
                ...languages
            });
        }*/
        debugger
        //Comprobamos si hay lenguajes por actualizar
        for(i=0;i<languagesForUpdate.length;i++){

            if(languagesForUpdate[i].newLanguage!==undefined){
                languages={...languages,["newLanguageFile-"+languagesForUpdate[i].idMultimediaItem]:languagesForUpdate[i].newLanguage}
            }
            addIdArray(languagesForUpdate[i].idMultimediaItem);
        }
        debugger
        
        uppy.setMeta({
            ...arrayIds,
            typeMultimedia:data.tipo,
            numberOfMultimedia:arrayIdsRegistry.length,
            ...languages
        });

        if(paqueteEnvio[0]===undefined){
            console.log("paquete envio undef");
            let headerPost={
                ids:arrayIdsRegistry,
                id:recursoData.id,
                nombre:recursoData.nombre,
                typeMultimedia:data.tipo,
                numberOfMultimedia:arrayIdsRegistry.length,
                ...languages
            };
            httpPost(ruta.upDateMultimediaNoLinkItem,headerPost).then((ficha)=>{
               updateMultimediaInTable();
            },(error)=>{
                console.error("nuestro error " + error)
            });
        }else{
            console.log("uppy upload");
            uppy.upload();
        }
        setOpStatus('new');
   }
    const guardarNuevosItemsRecurso=()=>{
        let idUppy;
        setUploadRuta(ruta.subidaArchivoARecurso);
        switch(data.tipo){
            case "imagen":
                debugger
                archivosParaEnvio.map((elem)=>{
                    idUppy = uppy.addFile(elem.archivo);
                    debugger
                    //uppy.setFileMeta(idUppy,{idItem:elem.idMultimedia});
                    //debugger
                    //addIdArray(elem.idMultimedia);
                    //debugger
                });
                let paqueteEnvioNuevo=uppy.getFiles();
                debugger 
                let i,languages;
                if(paqueteEnvioNuevo.length > 0){
                    debugger
                    for(i=0; i<paqueteEnvioNuevo.length; i++){
                        debugger
                        uppy.setFileState(paqueteEnvioNuevo[i].id, {
                            xhrUpload: { fieldName: 'archivo'+i }
                            })
                        languages={...languages,
                            ["languageFile"+i]:archivosParaEnvio[i].language}
                        
                    }
                    debugger
                    uppy.setMeta({
                        nameMultimedia:recursoData.nombre,
                        id:recursoData.id,
                        typeMultimedia:data.tipo,
                        numberOfMUltimedia:paqueteEnvioNuevo.length,
                        ...languages
                    });
                    debugger

                    paqueteEnvioNuevo=uppy.getFiles();
                    debugger
                    archivosParaEnvio=[];
                    uppy.upload();
                    debugger
                    //setArchivos([]);
                }
                
                break;
            default:
                break;
        }
        setFileForSend([]);
    }

    return (

        <div className="no-links-multimedia">
            <div class="d-flex mb-1 align-items-center">
            <div className="col-md-6 d-inline">
                Nombre del recurso: 
                <AtInputText type="text" width="442" value={recursoData.nombre} placeHolder="Nombre de recurso" onChange={event=>onChangeName(event.target.value)} className="mx-4"></AtInputText>
            </div>
            <div className="col-md-6 ">
                Número de items en el recurso: {recursoData.items.length}
            </div>
            </div>
            <div className="no-links-multimedia__at-title">
                <AtTitle fontSize="13"  fonstColor="#2E4467" >Estos son los items del recurso:</AtTitle>
            </div>
            {data.items.map((elem)=>{
              
              return <div className="edit-no-link-multimedia__content-items mb-1">
                          <div className="content-items__item">
                              {imageOrIcon ?
                              <img src={elem.url} alt="No disponible" width="84px" height="56.6px"></img> : 
                              <img src={imageItem} alt="No icono" width="84px" height="56.6px"></img>}
                          </div>
                          <div>
                             
                              <AtInputText type="file" onChange={event=>onChangeAddNewMultimedia(elem.id,event.target.files)}>Editar {nameButton}</AtInputText>
                          </div>
                          <div>
                              <AtSelect values={idioms} defaultValue={elem.language} onChange={event=>onChangeLanguage(elem.id,event.target.value)}></AtSelect>
                          </div>
                          
                  </div>
          })}
            <div className="no-links-multimedia__at-title">
                <AtTitle fontSize="13"  fonstColor="#2E4467" >¿Necesitas añadir items al recurso? Puedes hacerlo aquí:</AtTitle>
            </div>
            <div className="no-links-multimedia__items-container">
                
            </div>
            <div className="up-load-box"><UpLoadBox  uppy={uppy}></UpLoadBox></div>
            {upLoadNoLinks}
            <AtButton onClick={onClickSendUpdates}>Guardar</AtButton>
        </div>
    )
}

/*

{thisFiles.map((elem)=>{
                    
                    return <div className="no-links-multimedia__items">
                                {
                                tipoRecurso.imagen ===typeMultimedia ||
                                tipoRecurso.svg === typeMultimedia ?
                                    <img className="no-links-multimedia__items__img" src={elem.src}></img> :
                                    ""
                                }
                                

                                <label className="no-links-multimedia__item no-links-multimedia__item__label">{elem.nombre}</label>

                                <div className="no-links-multimedia__item no-links-multimedia__item__select">
                                    <AtSelect onChange={event=>onChangeLanguage(elem.nombre,event.target.value)} defaultValue={elem.language} values={idioms} widthSelect="195"></AtSelect>
                                </div>

                                <div className="no-links-multimedia__item">
                                    <AtButton widthImg="13" heightImg="16" image={ImageIcon.delete} type={typeCss.tertiary} 
                                    onClick={()=>{onClickDelete(elem.id)}}></AtButton>
                                </div>

                            </div>
                            
                })}
                */
export default EditNoLinksMultimedia;
import React, { useState,useEffect } from 'react';
import {useDispatch} from 'react-redux';
import AtPopup from '../../../../Atoms/At_Popup/At_Popup';
import FillableFieldViewer from '../FillableFieldViewer';

import {addExerciseResult} from '../../../../../Store/Actions/ExerciseResultsActions';

import ExerciseResponse1 from '../../../../../Images/ExerciseResponse1.png';
import ExerciseResponse2 from '../../../../../Images/ExerciseResponse2.png';
import ExerciseResponse3 from '../../../../../Images/ExerciseResponse3.png';
import ExerciseResponse5 from '../../../../../Images/IconoEscribir.png';
import ExerciseResponse6 from '../../../../../Images/IconoInstrumento.png';
import ExerciseResponse7 from '../../../../../Images/IconoInvestigar.png';
import ExerciseResponse8 from '../../../../../Images/IconoPentagrama.png';

import {deleteHtml} from '../../../../Atoms/At_EnrichedText/At_EnrichedText';
import {idiomas} from '../../../../../Constantes/idiomas';
import {PositionAnswer,imagePrevAnswer,imagePrevAnswerSelect} from '../../../../../Constantes/ExercisesVars';
import {quitarPxFinal} from '../../../../../Services/CSSTratamiento';

import './AnswerFF.scss';

import { exercises } from '../../../../../Constantes/Exercises';

const AnswerFF =(props)=>{
    let {
        exercise,
        language,
        viewer,
        styleElement,
        correctExercises,
        answerExercises
    }=props;

    const dispatch=useDispatch();
    const [firstLoad, setFirstLoad] = useState(true);

    let questionDataByIdiom;
   
    
    questionDataByIdiom=exercise.questionData.filter((elem)=>{
            return elem.language===language
        });
    
     
        if(questionDataByIdiom[0]===undefined){
            questionDataByIdiom=exercise.questionData.filter((elem)=>{
                return elem.language===idiomas.castellano;
            });
        }
    
        questionDataByIdiom=questionDataByIdiom[0];

    
    
        const [thisResponse,setThisResponse]=useState("");

        useEffect(() => {
            
            if(answerExercises!==undefined && answerExercises[0]!==undefined){
                let thisAnswerExercises=answerExercises.filter((elem)=>{
                    return elem.exerciseData.idLinkExercise==exercise.idLinkExercise && elem.exerciseData.typeExercise == exercises.fillableField;
                });

                if(thisAnswerExercises[0]!==undefined && 
                    thisAnswerExercises[0].exerciseData!==undefined && 
                    thisAnswerExercises[0].exerciseData.answer!==undefined){

                    setThisResponse(thisAnswerExercises[0].exerciseData.answer);
                    
                }
                
            }
            
            
        }, [answerExercises]);
        
      //<div dangerouslySetInnerHTML={{__html: thisResponse}} />
     
        let styleImg={
            width:styleElement.height,
            //height:styleElement.height,
            maxWidth:"50px",
            maxHeight:"50px",
            
        }

        if(quitarPxFinal(styleElement.height)<=quitarPxFinal(styleElement.width)){
            styleImg={
                width:styleElement.height,
                maxWidth:"50px",
                maxHeight:"50px",
            };
        }
        if(quitarPxFinal(styleElement.height)>quitarPxFinal(styleElement.width)){
            
            styleImg={
                width:styleElement.width,
                maxWidth:"50px",
                maxHeight:"50px",
            };
        }
        
        let classContent,classImg,styleContentSon;
        if(exercise.positionAnswer!==undefined){
            if(PositionAnswer.center==exercise.positionAnswer){
                classContent=" div-content-ff__center";
                classImg=" img-ff__center";
                styleContentSon={margin:"auto"}
                
            }
            if(PositionAnswer.leftTop==exercise.positionAnswer){
                classContent=" div-content-ff__leftTop";
                classImg=" img-ff__leftTop";
                styleImg={...styleImg,marginTop:exercise.fontStyle.fontSize};
            }
            if(PositionAnswer.leftBottom==exercise.positionAnswer){
                classContent=" div-content-ff__leftBottom";
                classImg=" img-ff__leftBottom";
            }
            
            
        }else{
            classContent=" div-content-ff__center";
            classImg=" img-ff__center";
        }

        let imgPrevAnswer;
    
        if(exercise.iconPrevAnswer!==undefined){
             if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse1){
                 imgPrevAnswer=ExerciseResponse1;
                 
                 
             }
             if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse2){
                 imgPrevAnswer=ExerciseResponse2;
                
                 
             }
             if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse3){
                 imgPrevAnswer=ExerciseResponse3;
              
             }
             if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse4){
                imgPrevAnswer=ExerciseResponse3;
                styleImg={
                    width:"20px",
                }
            }
            if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse5){
                imgPrevAnswer=ExerciseResponse5;
            }
            if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse6){
                imgPrevAnswer=ExerciseResponse6;
            }
            if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse7){
                imgPrevAnswer=ExerciseResponse7;
            }
            if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse8){
                imgPrevAnswer=ExerciseResponse8;
            }
             
        }else{
             imgPrevAnswer=ExerciseResponse1;
        }

        useEffect(() => {
            if(correctExercises==true){
                let replyToSend={answer:thisResponse,thisExercise:exercise,typeExercise:exercises.fillableField,isCorrect:setisCorrectExercise(thisResponse)};
          
                 dispatch(addExerciseResult(replyToSend));
            }
        }, [correctExercises]);

        useEffect(() => {
            setClassNameIncorrectAnswer("");
            if(firstLoad && answerExercises){
                setFirstLoad(false);
                setisCorrectExercise(thisResponse);
            }
           }, [thisResponse]);

        const [classNameIncorrectAnswer,setClassNameIncorrectAnswer]=useState("");
        
        const setisCorrectExercise=(thisResponse)=>{
            
            
            let answers=exercise.questionData.filter((elem)=>{
                return elem.language==language;
            });
            
            if(answers[0].answer!==undefined){
                answers=deleteHtml(answers[0].answer).toUpperCase();
                answers=answers.split(":");
                
                let response=deleteHtml(thisResponse).toUpperCase();
    
                
                let isCorrect=false;
                answers.map((elem)=>{
                    if(response.includes(elem)){
                        
                        isCorrect=true;
                    }
                });
                if(isCorrect==false){
                    setClassNameIncorrectAnswer("class-name-incorrect-answer-FF");
                }
                return isCorrect;
            }else{
                return true;
            }
            
        }

        
    
    if(viewer){
        styleImg={
            ...styleImg,
            border:"2px solid transparent"
        }

        
        return(
            <AtPopup noCloseInClickOut trigger={
                thisResponse!=="" && thisResponse!==null  ?
                <div style={exercise.fontStyle} className={"div-response-ff div-response-ff__overflow "+ classContent + " " + classNameIncorrectAnswer}>
                    <div style={styleContentSon} dangerouslySetInnerHTML={{__html: thisResponse}}/>
                </div>
                :
                <div className={"div-response-ff "+ classContent}>
                    <img className={classImg} style={styleImg} src={imgPrevAnswer} alt="imagen no disponible"/>
                </div>
            }    
            transparent 
            >
                <FillableFieldViewer style={exercise.fontStyle} value={thisResponse} onSaveResponse={setThisResponse} question={questionDataByIdiom}></FillableFieldViewer>
            </AtPopup>
        )
    }else{
       
        return(
            <div className={"div-response-ff "+ classContent}>
                <img className={classImg} style={styleImg} src={imgPrevAnswer} alt="imagen no disponible"/>
            </div>
        )
    }
    
}


export default AnswerFF;


import React, { useState,useEffect } from 'react';
import {useDispatch,useSelector,shallowEqual} from 'react-redux';
import Popup from "reactjs-popup";
import { useHistory } from "react-router-dom";

import './NewFile.scss';

import Uppy from '@uppy/core';
import XHRUpload  from '@uppy/xhr-upload';

import {idiomas} from '../../Constantes/idiomas';
import {tamannosLienzo} from '../../Constantes/TamannosLienzos'
import {crearFicha,getTemary,setEditFile} from './FichasConexionesServidor.jsx';
import {tipoRecurso} from '../../Constantes/TiposRecursos';
import {ruta} from '../../Constantes/Rutas';
import {typeCss} from '../../Constantes/TypeCss';

import AtSearchSelector from '../Atoms/At_SearchSelector/At_SearchSelector';
import AtButton from '../Atoms/At_Button/At_Button';
import AtInputText from '../Atoms/At_InputText/At_InputText';
import SelectBackgroundAndMiniature from './SelectBackgroundAndMiniature';
import AtText from '../Atoms/At_Text/At_Text';


const NuevaFicha =(props)=>{
    let {
        editFile,
        file,
        finished,
    } = props;
   
    
    
    const dispatch=useDispatch();

    const temary=useSelector((state)=>{
        
        return state.temary;
    },shallowEqual);
    //uppy
    


    //variables de creacion auxiliares
    const [altoLienzo,setAltoLienzo]=useState();
    const [anchoLienzo,setAnchoLienzo]=useState();


    //variables a enviar
    const [nameFileCastellano,setNameFileCastellano]=useState();
    const [nameFileValenciano,setNameFileValenciano]=useState();
    const [nameFileEnglish,setNameFileEnglish]=useState();
    
    const [idiomasFicha,setIdiomasFicha]=useState({castellano: true});
    const [resolucion,setResolucion]=useState(tamannosLienzo.HD);
    const [fondoFicha,setFondoFicha]=useState("#ffffff");
    const [marcoFicha,setMarcoFicha]=useState("#F8F8F8");
    const [imgBackGoundFile,setImgBackGoundFile]=useState();
    const [imgFrameworkFile,setImgFrameworkFile]=useState();
    const [idImgMinuature,setIdImgMinuature]=useState();

    //variables de control de formulario
    const [boolNameValenciano,setBoolNameValenciano]=useState(true);
    const [boolNameEnglish,setBoolNameEnglish]=useState(true);

    const [boolTamannoLienzoPorDefecto,setBoolTamannoLienzoPorDefecto]=useState(false);
    const [boolTipoFondoFicha,setBoolTipoFondoFicha]=useState(false);
    const [boolTipoMarcoFicha,setBoolTipoMarcoFicha]=useState(false);

    const [selectedThemes,setSelectedThemes]=useState([]);

    const [imgMinuature,setImgMinuature]=useState();

    //editar--------
    useEffect(() => {
        if(editFile){
            
            setAltoLienzo(Number(file.caracteristicas.lienzo[0]));
            setAnchoLienzo(file.caracteristicas.lienzo[1]);
            setResolucion(file.caracteristicas.lienzo);
            
    
            setNameFileCastellano(file.nombre.castellano);
            setNameFileValenciano(file.nombre.valenciano);
            setNameFileEnglish(file.nombre.ingles);
            setIdiomasFicha(file.caracteristicas.idiomas);
            
            setFondoFicha(file.caracteristicas.fondoFicha);
            setMarcoFicha(file.caracteristicas.marcoFicha);
            setImgBackGoundFile(file.caracteristicas.imgBackGoundFile);
            setImgFrameworkFile(file.caracteristicas.imgFrameworkFile);
            setIdImgMinuature(file.caracteristicas.idMiniature);
            setImgMinuature(file.caracteristicas.urlImgMinuature);
    
            setBoolNameValenciano(file.caracteristicas.boolNameValenciano);
            setBoolNameEnglish(file.caracteristicas.boolNameEnglish);
           
            setBoolTamannoLienzoPorDefecto(file.caracteristicas.boolTamannoLienzoPorDefecto);
            setBoolTipoFondoFicha(file.caracteristicas.boolTipoFondoFicha);
            setBoolTipoMarcoFicha(file.caracteristicas.boolTipoMarcoFicha);
            setSelectedThemes(file.caracteristicas.selectedThemes);
            
        }
    }, [])

    



    const onChangeChangeNameFile =(newName,idiom)=>{
        switch (idiom) {
            case idiomas.castellano:
                setNameFileCastellano(newName);
            break;

            case idiomas.valenciano:
                setNameFileValenciano(newName);
            break;

            case idiomas.ingles:
                setNameFileEnglish(newName);
            break;

            default:

            break;
        }
    };
    
    const onClickEnableNameImputValenciano=()=>{
        if(boolNameValenciano){
            setBoolNameValenciano(false);
        }else{
            setBoolNameValenciano(true);
        }
        
    }

    const onClickEnableNameImputEnglish=()=>{
        if(boolNameEnglish){
            setBoolNameEnglish(false);
        }else{
            setBoolNameEnglish(true);
        }
        
    }

    const onClickHabilitarTiposTamanno =(bool)=>{
        
        setBoolTamannoLienzoPorDefecto(bool);
    };

    const onClickHabilitarTiposFondoFicha =(bool)=>{
        
        setBoolTipoFondoFicha(bool);
    };

    const onClickHabilitarTiposMarcoFicha =(bool)=>{
        
        setBoolTipoMarcoFicha(bool);
    };

    const onClickCambiarResolucion =(resolucionSeleccionada)=>{

        setResolucion(resolucionSeleccionada);

    };

   

    const onChangeCambiarResolucion=(alto,ancho)=>{
        
       // setAltoLienzo(Number(alto));
       // setAnchoLienzo(Number(ancho));
        setResolucion([Number(alto),Number(ancho)]);
    };
   
    let history=useHistory();
    const onClickCrearFicha=()=>{
                
        let caracteristicasEdit={
            dataExercise:{
                lastIdDnd:0,
                dnds:[],
                }
            }
        if(editFile==true && file!==undefined && file.caracteristicas!==undefined){
            caracteristicasEdit={...file.caracteristicas};
        }

        let nuevaFicha={
            nombre:{
                [idiomas.castellano]:nameFileCastellano,
                [idiomas.valenciano]:nameFileValenciano,
                [idiomas.ingles]:nameFileEnglish,
            },
            public:false,
            caracteristicas:{
                ...caracteristicasEdit,
                lienzo:resolucion,
                idiomas:idiomasFicha,
                fondoFicha:fondoFicha,
                marcoFicha:marcoFicha,
                idMiniature:idImgMinuature,
                urlImgMinuature:imgMinuature,
                selectedThemes:selectedThemes,
                imgBackGoundFile:imgBackGoundFile,
                imgFrameworkFile:imgFrameworkFile,
                boolNameValenciano:boolNameValenciano,
                boolNameEnglish:boolNameEnglish,
                boolTamannoLienzoPorDefecto:boolTamannoLienzoPorDefecto,
                boolTipoFondoFicha:boolTipoFondoFicha,
                boolTipoMarcoFicha:boolTipoMarcoFicha,

                
                backGoundFile:{
                    //...recurso con items
                    }
                ,
                frameFile:{
                    //...recurso con items
                    }

            }
        };

 
        if(nameFileCastellano==="" || nameFileCastellano===undefined){
            alert("falta nombre de la ficha")
        }else{
            if(editFile){
                setEditFile(file.id,nuevaFicha,dispatch).then(() => {
                    finished();
                });
            }else{
    
                crearFicha(dispatch,nuevaFicha).then((ficha)=>{
    
                    history.push(ruta.editor + ficha.id);
                    finished();
                        
                },(error)=>{
                    console.error("nuestro error " + error)
                })
    
                
            }
        }
        
     
    };

    const onChangeIdiomaSeleccionado=(estado,idiomaSeleccionado)=>{
       

        switch (idiomaSeleccionado) {
            case idiomas.valenciano:
                onClickEnableNameImputValenciano();
                break;
            case idiomas.ingles:
                onClickEnableNameImputEnglish();
                break;      
            default:
                break;
        }

        setIdiomasFicha({...idiomasFicha,[idiomaSeleccionado]:estado});
        
    };

    

    const onChangeCambioFondoFicha=(newFondo)=>{

        setFondoFicha(newFondo);

    };

    const onChangeCambioMarcoFicha=(newMarco)=>{

        setMarcoFicha(newMarco);

    };

    
    const onClickSeleccionarFondo=(recurso)=>{
    
        setImgBackGoundFile(recurso.items[0].url);
        setFondoFicha(recurso);
    };
    const onClickSeleccionarMarco=(recurso)=>{

        setImgFrameworkFile(recurso.items[0].url);
        setMarcoFicha(recurso);
        
    };

    const onClickSelectMiniature=(recurso)=>{
        
        setIdImgMinuature(recurso.id);
        setImgMinuature(recurso.items[0].url);
    }

    const onClickDeleteMiniature=()=>{
        setIdImgMinuature();
        setImgMinuature();
    }
    
    useEffect(()=>{

        getTemary(dispatch);

    },[]);

    const onSelectTema=(id)=>{
        let newSelectedThemes

        newSelectedThemes=temary.filter((elem)=>{
            return elem.value==id
        });

        newSelectedThemes=[...selectedThemes,...newSelectedThemes];
        setSelectedThemes(newSelectedThemes);

    }

    const onClickDeleteTheme=(value)=>{
      
        let newSelectedThemes

        newSelectedThemes=selectedThemes.filter((elem)=>{
            return elem.value!=value
        });

        setSelectedThemes(newSelectedThemes);

    }

   
   

    return(
        <div className="new-file">
            <div className="fondo-ficha">
                <div className="new-file__item ficha-titulo"><AtText>Nombre de la ficha</AtText></div>
                <div className="new-file__item">
                    <div className="inputs-name">
                        <AtText>Castellano: </AtText>
                        <AtInputText type="text" value={nameFileCastellano} placeholder="Nombre ficha" onChange={event=>onChangeChangeNameFile(event.target.value,idiomas.castellano)}></AtInputText> 
                    </div>
                    <div className="inputs-name inputs-name__checkbox">
                        <AtInputText type="checkbox" checked={!boolNameValenciano} value={idiomas.valenciano} onChange={event=>onChangeIdiomaSeleccionado(event.target.checked,event.target.value)}/>
                        <AtText>Valenciano: </AtText>
                        <AtInputText type="text"  disabled={boolNameValenciano} value={nameFileValenciano} placeholder="Nom fitxa" onChange={event=>onChangeChangeNameFile(event.target.value,idiomas.valenciano)}></AtInputText> 
                    </div>
                    <div className="inputs-name inputs-name__checkbox">
                        <AtInputText type="checkbox" checked={!boolNameEnglish} value={idiomas.ingles} onChange={event=>onChangeIdiomaSeleccionado(event.target.checked,event.target.value)}/>
                        <AtText>Inglés: </AtText>
                        <AtInputText type="text" disabled={boolNameEnglish} value={nameFileEnglish} placeholder="Name file" onChange={event=>onChangeChangeNameFile(event.target.value,idiomas.ingles)}></AtInputText> 
                    </div>
                </div>
            </div>
            <div className="fondo-ficha">
            <div className="new-file__item ficha-titulo"><AtText>Tamaño del lienzo </AtText></div>
            <div className="new-file__item resolution">
                <div>
                    <div>
                        <AtInputText type="radio" name="tamanno" value="defecto" checked={!boolTamannoLienzoPorDefecto}  onClick={()=>{onClickHabilitarTiposTamanno(false)}}/> 
                        <AtText> Por defecto</AtText>
                    </div>
                    <div className="resolution__items-resolution">
                        <div>
                            <AtInputText type="radio" name="lienzoTamanno" defaultChecked="true" value={tamannosLienzo.HD} onClick={()=>{onClickCambiarResolucion(tamannosLienzo.HD)}} disabled={boolTamannoLienzoPorDefecto}/> 
                            <AtText> HD</AtText>
                        </div>
                        <div>
                            <AtInputText type="radio" name="lienzoTamanno" value={tamannosLienzo.FullHD} onClick={()=>{onClickCambiarResolucion(tamannosLienzo.FullHD)}} disabled={boolTamannoLienzoPorDefecto}/> 
                            <AtText> Full HD</AtText>
                        </div>
                        <div>
                            <AtInputText type="radio" name="lienzoTamanno" value={tamannosLienzo.CuatroKCine} onClick={()=>{onClickCambiarResolucion(tamannosLienzo.CuatroKCine)}} disabled={boolTamannoLienzoPorDefecto}/> 
                            <AtText> 4k</AtText>
                        </div>
                    </div>
                </div>
                <div >
                    <div >
                        <AtInputText type="radio" name="tamanno" value="personalizado" checked={boolTamannoLienzoPorDefecto} onClick={()=>{onClickHabilitarTiposTamanno(true)}}/> 
                        <AtText> Personalizado</AtText>
                    </div>
                    <div className="resolution__item">
                        <div>
                          Ancho  <AtInputText type="number" placeholder="Ancho" disabled={!boolTamannoLienzoPorDefecto} value={resolucion[0]} onChange={event=>onChangeCambiarResolucion(event.target.value,resolucion[1])}></AtInputText> 
                        </div>
                        <div>
                          Alto  <AtInputText type="number" placeholder="Alto" disabled={!boolTamannoLienzoPorDefecto} value={resolucion[1]} onChange={event=>onChangeCambiarResolucion(resolucion[0],event.target.value)}></AtInputText>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div className="fondo-ficha">
            <div className="new-file__item ficha-titulo"><AtText>Wallpaper</AtText></div>
            <div className="new-file__item wallpaper">
                <div>
                    <AtText>Fondo de ficha / Background:</AtText>
                    <div className="wallpaper__item">
                        <div>
                            <AtInputText type="radio" name="fondoFicha" checked={!boolTipoFondoFicha} onClick={()=>{onClickHabilitarTiposFondoFicha(false)}}/> 
                            <AtText>Color: </AtText>
                            <AtInputText type="color" value={fondoFicha} disabled={boolTipoFondoFicha} defaultValue="#ffffff" onChange={event=>onChangeCambioFondoFicha(event.target.value)}/>
                        </div>
                        <div>
                            <AtInputText type="radio" value={fondoFicha} name="fondoFicha" checked={boolTipoFondoFicha}  onClick={()=>{onClickHabilitarTiposFondoFicha(true)}}/> 
                            <Popup trigger={<button disabled={!boolTipoFondoFicha}>Selecionar Rercurso</button>} modal position="right center">
                                <SelectBackgroundAndMiniature onClickInsertMultimediaIntoElement={onClickSeleccionarFondo}></SelectBackgroundAndMiniature>
                            </Popup>
                        </div>
                        <div>
                            <img height="80px" width="80px" alt="Background file" src={imgBackGoundFile}></img>
                        </div>
                    </div>
                </div>
                
                <div>
                    <AtText>Fondo/Framework:</AtText>
                    <div className="wallpaper__item">
                        <div>
                            <AtInputText type="radio" name="marcoFicha" value="color" checked={!boolTipoMarcoFicha} onClick={()=>{onClickHabilitarTiposMarcoFicha(false)}}/> 
                            <AtText>Color: </AtText>
                            <AtInputText type="color" value={marcoFicha}  disabled={boolTipoMarcoFicha} defaultValue="#F8F8F8" onChange={event=>onChangeCambioMarcoFicha(event.target.value)}/>
                        </div>
                        <div>
                            <AtInputText type="radio" value={marcoFicha} name="marcoFicha" checked={boolTipoMarcoFicha} onClick={()=>{onClickHabilitarTiposMarcoFicha(true)}}/> 
                            <Popup trigger={<button disabled={!boolTipoMarcoFicha}>Selecionar Rercurso</button>} modal position="right center">
                                <SelectBackgroundAndMiniature onClickInsertMultimediaIntoElement={onClickSeleccionarMarco}></SelectBackgroundAndMiniature>
                            </Popup>
                        </div>
                        <div>
                            <img height="80px" width="80px" alt="Framework file" src={imgFrameworkFile}></img>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div className="fondo-ficha">
            <div className="new-file__item ficha-titulo"><AtText>Themes</AtText></div>     
           <div className="new-file__item search-selector">
            <AtSearchSelector onSelect={onSelectTema} values={temary}></AtSearchSelector>
                <div>
                    
                    {selectedThemes.map((elem)=>{
                    return   <div>
                                    <label>{elem.text}</label>
                                    <button value={elem.value}
                                    onClick={event=>onClickDeleteTheme(event.target.value)}
                                    >X</button>
                                </div>
                    })}
                </div>
            </div>
            </div>
            <div className="fondo-ficha">
            <div className="new-file__item ficha-titulo"><AtText>Miniatura</AtText></div>
            <div className="new-file__item miniature">
                <div>
                    <div>
                        <Popup trigger={<button>Selecionar Miniatura</button>} modal position="right center">
                                <SelectBackgroundAndMiniature onClickInsertMultimediaIntoElement={onClickSelectMiniature}></SelectBackgroundAndMiniature>
                        </Popup>
                    </div>
                    <div>
                        <AtButton type={typeCss.tertiary} onClick={onClickDeleteMiniature}>Borrar miniatura</AtButton>
                    </div>
                </div>
                
                <div>
                    <img height="80px" width="80px" src={imgMinuature}></img>
                </div>
            </div>
            </div>
            <div className="button-create-file">
                <AtButton onClick={onClickCrearFicha}>Crear Ficha</AtButton>
            </div>
            
            
        </div>


    );
};


export default NuevaFicha;
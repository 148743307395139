export const multimediaOrder=[

    ["created_at","Fecha de creacion"],
    ["nombre","Nombre"],
    ["tipo","Tipo"],


]

export const descAsc=[
    ["desc","Descendente"],
    ["asc","Ascendente"]


]

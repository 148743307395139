import React,{useEffect} from 'react';
import {useDispatch,useSelector,shallowEqual} from 'react-redux';
import {httpGet} from '../../../../Store/HttpCalls.jsx';

import MolRack from '../../../Molecules/Mol_Rack/Mol_Rack';

import {reloadRecursos} from '../../../../Store/Actions/RecusosActions.js';
import {ruta} from '../../../../Constantes/Rutas';
import {tipoRecurso} from '../../../../Constantes/TiposRecursos';

const SelectImage=(props)=>{
    let {
        onClick,
        finished,
        idAnswer
    }=props;

    const dispatch=useDispatch();

    const recursos=useSelector((state)=>{
        
        return state.recursos;
    },shallowEqual);

    useEffect(() => {
        httpGet(ruta.getPagedMultimediaFilter + "?type="+tipoRecurso.imagen).then((multimedia)=>{
            dispatch(reloadRecursos(multimedia));
        },(error)=>{
            console.error("nuestro error " + error)
        });
    }, [])

    

    const onClickChangePage=(urlEnd)=>{
     
        httpGet(ruta.getPagedMultimedia + urlEnd).then((multimedia)=>{
            dispatch(reloadRecursos(multimedia));
        },(error)=>{
            console.error("nuestro error " + error)
        })

    };


    let datos=[],paginate=[];
    if(recursos.data!=null){
        datos=recursos.data;
        paginate=recursos;
        
    }

    
        
       const onClickDivRack=(props)=>{
           if(idAnswer!==undefined){
                onClick(idAnswer,props);
           }else{
                onClick(props);
           }
            
   
            if(finished!==undefined){
                finished();
            }
            
        }

        
    

    
    return(
        <div>
            <MolRack 
                onClickDivRack={onClickDivRack}
                onClickChangePage={onClickChangePage} 
                paginate={paginate} 
                data={datos}
                >
                
            </MolRack>
        </div>
    )
}


export default SelectImage;
import React, {useState} from 'react';

import './Mol_AccordionMenu.scss';
import At_button from '../../Atoms/At_Button/At_Button';
import {typeCss} from '../../../Constantes/TypeCss';

import '../../../Styles/GeneralCssClasses.scss';

const Mol_AccordionMenu=(props)=>{
    let {
        children,
        className,
        nameButton,
        imageOpen,
        imageClose,
        widthImg, 
        heightImg,
        defaultOpen,
    }=props

    let [flag,setFlag]=useState(defaultOpen);
    let preDeactivate;
    defaultOpen ? preDeactivate="": preDeactivate="deactivate";
    let [imageButton,setImageButton]=useState(imageOpen);
    let [deactivate,setDeactivate]=useState(preDeactivate);

    
    

    const onClickOpenClose=()=>{
        
        if(flag){
            setFlag(false);
            setImageButton(imageOpen);
            setDeactivate("deactivate");
        }else{
            setFlag(true);
            setImageButton(imageClose);
            setDeactivate("");
        }
        
    }
 
    
    

    return(
        <div >
            <At_button onClick={onClickOpenClose} type={typeCss.tertiary} image={imageButton} widthImg={widthImg} heightImg={heightImg}>{nameButton}</At_button>
           
            <div className={className +" "+ deactivate}>
            {children}
            </div>
            
        
        </div>
    )
};




export default Mol_AccordionMenu;
import React,{useState,useEffect} from 'react';

import './TestSelectViewer.scss';
import NoteBook from '../../../../Images/cuaderno.png';
import MolTextAndInputText from '../../../Molecules/Mol_TextAndInputText/Mol_TextAndInputText';
import AtButton from '../../../Atoms/At_Button/At_Button';



import {idiomas} from '../../../../Constantes/idiomas';

const TestSelectViewer=(props)=>{
    let {
        value,
        onSaveResponse,
        question,
        finished,
        style
        
    }=props;

    
    let questionByIdiom;
    let answers;
    
    useEffect(() => {
        onSaveResponse([]);
    }, [])
    

    
   
    if(questionByIdiom!==undefined){
        answers=questionByIdiom.answers.map((elem)=>{
            return {...elem,answer:false}
        });
    }   

    if(questionByIdiom===[]){

    }
    const [selectValues,setSelectValues]=useState(value);

    const onChangeselectAnswer=(id)=>{
        let newValue=question.answers.filter((elem)=>{
            return elem.id==id;
        });
    
        onSaveResponse([...newValue]);

    }

    const onClickorrectExercise=()=>{
        let numCorrectAnswers=0;

        answers.map((elem)=>{
            //if(elem.value===true){
                if(elem.answer===elem.value){
                    numCorrectAnswers++;
                }
            //}
        });

        alert("Acertaste: "+numCorrectAnswers+ " de " + answers.length);
    }

    
    if(questionByIdiom===undefined){
        questionByIdiom={question:"pregunta",answers:[]};
    }

    let newSyle={
        ...style,
        fontSize:"20px",
        color:"black"
    }

    return (
        <div className="test-select-viewer">
            <img src={NoteBook} className="test-select-viewer__background" alt="Notebook background"/>
            <div className="test-select-viewer__exercise">
                <div style={newSyle} className="test-select-viewer__section" dangerouslySetInnerHTML={{__html: question.question}}/>
                <div className="test-select-viewer__section">
                {question.answers.map((elem)=>{
                    return <MolTextAndInputText 
                    className="test-select-viewer__answers" 
                    onChange={()=>{onChangeselectAnswer(elem.id)}} 
                    name="itemAnswerTestSelectViewer" type="radio">
                                    <div style={{...newSyle,color:"#006AC1"}} dangerouslySetInnerHTML={{__html: elem.answer}}/>
                                </MolTextAndInputText>
                               
                            
                            
                })}
                </div>
                <div>
                    <AtButton onClick={finished}>Responder</AtButton>
                </div>
            </div>
            
        </div>
    )
}



export default TestSelectViewer;
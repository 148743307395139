import {crearElementoEditor} from '../../../Store/Actions/EditorElementosActions.js';
import {tipoElemento,estilosElementos} from '../../../Constantes/Elementos.js';
import {controlador_ids,getIdActual,getIdLinkExercises,addIdLinkExercises} from '../EditorControlador/EditorConexionesServidor.jsx';

import {increaseDndId} from '../../../Store/Actions/EstaFichasActions.js';

import { idiomas } from '../../../Constantes/idiomas.js';
import {exercises,typePartQorA} from '../../../Constantes/Exercises';
import {PositionAnswer,imagePrevAnswer,typeQuestionOfIT} from '../../../Constantes/ExercisesVars';


  export const crearCuadrado=(dispatch)=>{
    controlador_ids();
    let id =getIdActual();
    
    let newelemento=[{X:50,Y:50,ID:id,tipoSeleccionado:tipoElemento.Cuadrado,textoEstatico:"",estiloHijo:estilosElementos.Cuadrado}];
    
    dispatch(crearElementoEditor(newelemento));
  };

  export const crearCirculo=(dispatch)=>{
    controlador_ids();
    let id =getIdActual();
    let newelemento=[{X:200,Y:50,ID:id,tipoSeleccionado:tipoElemento.Circulo,textoEstatico:"",estiloHijo:estilosElementos.Circulo}];
    dispatch(crearElementoEditor(newelemento));
  };

  export const crearTriangulo=(dispatch)=>{
    controlador_ids();
    let id =getIdActual();
    let newelemento=[{X:300,Y:50,ID:id,tipoSeleccionado:tipoElemento.Triangulo,textoEstatico:"",estiloHijo:estilosElementos.Triangulo}];
    dispatch(crearElementoEditor(newelemento));
  };


  export const crearTextoEstatico=(dispatch)=>{
    controlador_ids();
    let id =getIdActual();
    let newelemento=[{
      X:50,
      Y:200,
      ID:id,
      tipoSeleccionado:tipoElemento.TextoEstatico,
      textoEstatico:{
        [idiomas.castellano]:"texto nuevo",
        [idiomas.valenciano]:"text nou",
        [idiomas.ingles]:"new text",
      },
      estiloHijo:estilosElementos.TextoEstatico}];
    dispatch(crearElementoEditor(newelemento));
  };


  export const createText2=(dispatch)=>{
    controlador_ids();
    let id =getIdActual();
    let newelemento=[{
      X:200,
      Y:200,
      ID:id,
      tipoSeleccionado:tipoElemento.EnrichedText,
      textoEstatico:{
        [idiomas.castellano]:"<p>texto2 nuevo</p>",
        [idiomas.valenciano]:"<p>text2 nou</p>",
        [idiomas.ingles]:"<p>new2 text</p>",
      },
      estiloHijo:estilosElementos.Text2}];
     
    dispatch(crearElementoEditor(newelemento));
  }

  export const verElementosEditor=(elementosEditor)=>{

      
  };

  export const pegarElemento=(dispatch,copia,xX,yY)=>{
    controlador_ids();
    let id =getIdActual();
    let newelemento=[{X:xX,Y:yY,ID:id,tipoSeleccionado:copia[0].tipoSeleccionado,textoEstatico:copia[0].textoEstatico,estiloHijo:copia[0].estiloHijo}];
    dispatch(crearElementoEditor(newelemento));
  };


  export const insertarRecusro=(dispatch,idRecurso,multimedia,typeElement)=>{

    
    controlador_ids();
    let id =getIdActual();
    let newelemento=[{
      X:250,
      Y:250,
      ID:id,
      tipoSeleccionado:tipoElemento.Multimedia,
      textoEstatico:"",
      estiloHijo:estilosElementos.Recurso,
      idRecurso:idRecurso,
      isMultimedia: 1,
      caracteristicas:{
        typeElementMultimedia:typeElement,
        multimedia:{
          ...multimedia,
          itemsMultimedia:multimedia.items
        }
      }
    }];

    

    dispatch(crearElementoEditor(newelemento));
  };


  export const createImage=(dispatch,idRecurso,multimedia,typeElement)=>{

 
    
    controlador_ids();
    let id =getIdActual();
    let newelemento=[{
      X:250,
      Y:250,
      ID:id,
      tipoSeleccionado:tipoElemento.Image,
      textoEstatico:"",
      estiloHijo:estilosElementos.Image,
      idRecurso:idRecurso,
      isMultimedia: 0,
      
      caracteristicas:{
        idImageMultimedia:idRecurso,
        typeElementMultimedia:typeElement,
        image:{
          ...multimedia,
          itemsMultimedia:multimedia.items
        }
      }
    }];

    dispatch(crearElementoEditor(newelemento));

  }



  export const createExercise=(dispatch,typeExercise,exerciseData)=>{
    let exercise,estiloHijo;
    
    if(exercises.dragAndDrop==typeExercise){
        estiloHijo=estilosElementos.DndExercise;

        exercise={
          typeExercise:typeExercise,
          idDndDroppable:exerciseData.lastIdDnd,
          
        }
        let newItemDroppable={   
          id:exerciseData.lastIdDnd,
          items:[],
        }
        dispatch(increaseDndId([newItemDroppable]));
    }
    
    if(typeExercise==exercises.fillableField || typeExercise==exercises.textSelect){
      estiloHijo=estilosElementos.Exercise;
      exercise={
        part:typePartQorA.question,
        typeExercise:typeExercise,
      }
    }


    controlador_ids();
    let id =getIdActual();
    let newelemento=[{
      X:0,
      Y:500,
      ID:id,
      tipoSeleccionado:tipoElemento.Exercise,
      textoEstatico:"",
      estiloHijo:estiloHijo,
      isMultimedia: 0,
      caracteristicas:{
          exercise:{
            ...exercise
          }
        }
      
    }];

    dispatch(crearElementoEditor(newelemento));
  }

  export const createExerciseFFAndTest=(dispatch,typeExercise)=>{
    let exercise,estiloHijo,newelemento,id,questionData;

    if(typeExercise==exercises.fillableField){
      questionData= [
        {language:idiomas.castellano,question:"pregunta",answer:"respuesta1:respuesta2"},
        {language:idiomas.valenciano,question:"pregunta val",answer:"respuesta1 val:respuesta2 val"},
        {language:idiomas.ingles,question:"question",answer:"answer1:answer2"}
      ]
    }

    if(typeExercise==exercises.textSelect){
      questionData=[
          {
            answers: [
              {id:0,answer:"respuesta1",correct:false},
              {id:1,answer:"respuesta2",correct:false},
            ],
            numCorrect:0,
            language: idiomas.castellano,
            question: "<p>Pregunta</p>",
           
          },
          {
            answers: [
              {id:0,answer:"respuesta1 val",correct:false},
              {id:1,answer:"respuesta2 val",correct:false},
            ],
            numCorrect:0,
            language: idiomas.valenciano,
            question: "<p>Pregunta val</p>",
           
          },
          {
            answers: [
              {id:0,answer:"Answer1",correct:false},
              {id:1,answer:"Answer2",correct:false},
            ],
            numCorrect:0,
            language: idiomas.ingles,
            question: "<p>Question</p>",
           
          }
        ];
    }


    addIdLinkExercises();
    let idLink=getIdLinkExercises();
    //creamos la pregunta
      exercise={
        part:typePartQorA.question,
        idLinkExercise:idLink,
        typeExercise:typeExercise,
        questionData:questionData,
        fontStyle:{
          fontFamily:"Open Sans",
          fontSize:"20px",
          color:"#000000"
        },
        positionAnswer:PositionAnswer.center,
        iconPrevAnswer:imagePrevAnswer.ExerciseResponse1
      }
    


    controlador_ids();
    id =getIdActual();
    newelemento=[{
      X:10,
      Y:10,
      ID:id,
      tipoSeleccionado:tipoElemento.Exercise,
      textoEstatico:"",
      estiloHijo:estilosElementos.ExerciseQuestion,
      isMultimedia: 0,
      caracteristicas:{
          exercise:{
            ...exercise
          }
        }
      
    }];

    dispatch(crearElementoEditor(newelemento));

    //creamos la respuesta
      exercise={
        part:typePartQorA.answer,
        typeExercise:typeExercise,
        questionData:questionData,
        idLinkExercise:idLink,
        fontStyle:{
          fontFamily:"Open Sans",
          fontSize:"20px",
          color:"#000000"
        },
        positionAnswer:PositionAnswer.center,
        iconPrevAnswer:imagePrevAnswer.ExerciseResponse1
      }
    


    controlador_ids();
    id =getIdActual();
    newelemento=[{
      X:10,
      Y:100,
      ID:id,
      tipoSeleccionado:tipoElemento.Exercise,
      textoEstatico:"",
      estiloHijo:estilosElementos.ExerciseAnswer,
      isMultimedia: 0,
      caracteristicas:{
          exercise:{
            ...exercise
          }
        }
      
    }];

    dispatch(crearElementoEditor(newelemento));
  }

  export const createExerciseImageTest=(dispatch,typeExercise)=>{
    let exercise,estiloHijo,newelemento,id,questionData;


    
    
      questionData=[
          {
            answers: [
              {id:0,answer:"",correct:false},
              {id:1,answer:"",correct:false},
            ],
            numCorrect:0,
            language: idiomas.castellano,
            question: "",
           
          },
          {
            numCorrect:0,
            language: idiomas.valenciano,
            question: "",
          },
          {
            numCorrect:0,
            language: idiomas.ingles,
            question: "",
          }
        ];
    


    addIdLinkExercises();
    let idLink=getIdLinkExercises();
    //creamos la pregunta
      exercise={
        part:typePartQorA.question,
        idLinkExercise:idLink,
        typeExercise:typeExercise,
        questionData:questionData,
        positionAnswer:PositionAnswer.center,
        iconPrevAnswer:imagePrevAnswer.ExerciseResponse1,
        typeQuestion:typeQuestionOfIT.image,
        fontStyle:{
          fontFamily:"Open Sans",
          fontSize:"20px",
          color:"#000000"
        },
      }
    


    controlador_ids();
    id =getIdActual();
    newelemento=[{
      X:10,
      Y:10,
      ID:id,
      tipoSeleccionado:tipoElemento.Exercise,
      textoEstatico:"",
      estiloHijo:estilosElementos.ExerciseQuestion,
      isMultimedia: 0,
      caracteristicas:{
          exercise:{
            ...exercise
          }
        }
      
    }];

    dispatch(crearElementoEditor(newelemento));

    //creamos la respuesta
      exercise={
        part:typePartQorA.answer,
        typeExercise:typeExercise,
        questionData:questionData,
        idLinkExercise:idLink,
        positionAnswer:PositionAnswer.center,
        iconPrevAnswer:imagePrevAnswer.ExerciseResponse1,
        typeQuestion:typeQuestionOfIT.image,
        fontStyle:{
          fontFamily:"Open Sans",
          fontSize:"20px",
          color:"#000000"
        },
      }
    


    controlador_ids();
    id =getIdActual();
    newelemento=[{
      X:10,
      Y:100,
      ID:id,
      tipoSeleccionado:tipoElemento.Exercise,
      textoEstatico:"",
      estiloHijo:estilosElementos.ExerciseAnswer,
      isMultimedia: 0,
      caracteristicas:{
          exercise:{
            ...exercise
          }
        }
      
    }];
   
    dispatch(crearElementoEditor(newelemento));
  }


  export const createSvg=(dispatch,typeExercise,svgData)=>{


    let estiloHijo=estilosElementos.Exercise;
    let exercise={
      typeExercise:typeExercise,
      svg:svgData
    }

    controlador_ids();
    let id =getIdActual();
    let newelemento=[{
      X:0,
      Y:500,
      ID:id,
      tipoSeleccionado:tipoElemento.Exercise,
      textoEstatico:"",
      estiloHijo:estiloHijo,
      isMultimedia: 0,
      caracteristicas:{
          exercise:{
            ...exercise
          }
        }
      
    }];

    dispatch(crearElementoEditor(newelemento));

  }

  export const createBasicStaff=(dispatch,typeExercise)=>{
    //tamaño de imagen
    
    let estiloHijo=estilosElementos.BasicStaff;
    let exercise={
      typeExercise:typeExercise,
      
    }
    controlador_ids();
    let id =getIdActual();
    let newelemento=[{
      X:100,
      Y:100,
      ID:id,
      tipoSeleccionado:tipoElemento.Exercise,
      textoEstatico:"",
      estiloHijo:estiloHijo,
      isMultimedia: 0,
      caracteristicas:{
          exercise:{
            ...exercise
          }
        }
      
    }];

    dispatch(crearElementoEditor(newelemento));
  }
  
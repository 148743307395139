import React from 'react';

import './At_Button.scss';
import {typeCss} from '../../../Constantes/TypeCss';
import AtImage from '../At_Image/At_Image';

const At_Button =(props)=>{
    let {
        children,
        image,
        onClick,
        type,
        widthImg,
        heightImg,
        imageRight,
        className

    }=props

    let classCssAtButton;
    switch (type) {
        case typeCss.secondary:
            classCssAtButton="at-button--secondary"
            break;

        case typeCss.tertiary:
            classCssAtButton="at-button--tertiary"
            break;

        default:
            
            break;
    }

    let imgClass;
    if(children==null){
        imgClass="at-button__img--alone"
    }
    
    return (
        <button onClick={onClick} className={"at-button "+classCssAtButton+" "+ className}>

            {image !== undefined && imageRight === undefined ? <AtImage className={"at-button__img " +imgClass} image={image} width={widthImg} height={heightImg} /> : ""}
            <span className="at-button__span">{children}</span>
            {image !==  undefined && imageRight === true ? <AtImage  className="at-button__img at-button__img--right" image={image} width={widthImg} height={heightImg} /> : ""}

        </button>
        
    )
}

export default At_Button;
export const tipoDatoTabla={
    texto:'texto',
    boton:'boton',
    select:'select',
    checkBox:'checkBox',
    enlace:'enlace',
    textBold:'textBold',
    image:'image',
    svg:'svg',
    imageMultimedia:'imageMultimedia',
    imageFiles:'imageFiles',
    content:"content",
    popup:"popup",
    popupEditFile:"popupEditFile",
    popupEditMultimedia:"popupEditMultimedia",
}
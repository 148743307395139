import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import Login from './Componentes/Login/Login';

const PublicRoute = (props) => {
  const {
    Component,
    restricted,
    path
  } = props;
  

  if(sessionStorage.getItem("login_lmyy2_access_token")==null && restricted==true){
    return (
        <Route>
          <Redirect to={"/login?redirect="+path} />
           
        </Route>
      ); 
  }else{
    return (
        <Route  path={path}>
            <Component />
        </Route>
      );
  }
  
};


export default PublicRoute;

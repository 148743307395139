import { types } from '../Reducers/FiguresInViewerReducer';


export const createFigure = (figure) => ({
  type: types.ADD_FIGURE,
  figure: figure,
});

export const undoFigure = () => ({
  type: types.UNDO,
});

export const deleteAllFigure = () => ({
  type: types.DELETE_ALL_FIGURE,
});




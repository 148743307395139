
export const types = {
    SELECCIONAR_ELEM: 'SELECCIONAR_ELEM',
    
  };

  const ElemSeleccionadoReducer = (state = [], action) => {
    switch (action.type) {
      

      case types.SELECCIONAR_ELEM:
       
        return action.id;
          
        
      default:
        return state;
    }
  };

  export default ElemSeleccionadoReducer;
/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */



const server = process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_API_ENDPOINT;
//const server = 'https://local-backend.lamusicayyo.com/api/';
const getHeaders = () => {
  //const { apiToken } = loadState();
  return {
    //'api-token': apiToken,
    'Content-Type': 'application/json',
  };
};

const handleResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response);
  }
  return response.json();
};
const handleResponseExternal = (response) => {
  if (!response.ok) {
    return Promise.reject(response);
  }
  return response.text();
};

const handleError = async (response) => {
  const error = await response.json().then((data) => data);
  return Promise.reject(error);
};

export const httpGet = (api) => {
  const headers = getHeaders();
  
  return fetch(server + api, {
    method: 'GET',
    headers,
  })
    .then(handleResponse)
    .catch(handleError);
};

export const httpPost = (api, data) => {
  const headers = getHeaders();
  
  return fetch(server + api, {
    method: 'POST',
    body: JSON.stringify(data),
    headers,
  })
    .then(handleResponse)
    .catch(handleError);
};

export const httpPostWithHeaders = (api, myHeaders) => {
  
  return fetch(server + api, {
    method: 'POST',
    'Content-Type': 'application/json',
    body: JSON.stringify(myHeaders),
    
  })
    .then(handleResponse)
    .catch(handleError);
};

export const httpPostLogin = (api,logData) => {
  const headers = getHeaders();
  
  return fetch(server + api, {
    method: 'POST',
    'Content-Type': 'application/json',
    'X-Requested-With':'XMLHttpRequest',
    body: JSON.stringify(logData),
    headers,
  })
    .then(handleResponse)
    .catch(handleError);
};

export const httpPut = (api, data) => {
  const headers = getHeaders();
  return fetch(server + api, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers,
  })
    .then(handleResponse)
    .catch(handleError);
};

export const httpDelete = (api) => {
  const headers = getHeaders();
  //condicion
  
  return fetch(server + api, {
    method: 'DELETE',
    headers,
  })
    //if
    .then(handleResponse)
    //else
    .catch(handleError);
};

export const httpGetExternal = (path) => {
  
  return fetch(path, {
    method: 'GET',
    headers:{
      "Content-Type":"text/plain"
    },

  }).then(handleResponseExternal);
    
};
import React, { useState } from 'react';
import Popup from "reactjs-popup";

import AtButton from '../../Atoms/At_Button/At_Button';
import EditorMultimediaController from '../../RecursosMultimedia/EditorMultimedia/EditorMultimediaController';
import AtPopup from '../../Atoms/At_Popup/At_Popup';
import FormUploadMultimedia from '../../RecursosMultimedia/FormUploadMultimedia';

const DTPopupEditFile =(props)=>{
     let {datos, updateMultimediaInTable}=props;
     
    return(
        
        <td className="table-body__td">
            <AtPopup open={datos.openPupup} className={datos.className} trigger={<AtButton onClick={datos.setOpenPupup}>Editar</AtButton>} >
                <FormUploadMultimedia datos={datos} updateMultimediaInTable={updateMultimediaInTable}></FormUploadMultimedia>
            </AtPopup>
        </td>
        
    );
};

export default DTPopupEditFile;
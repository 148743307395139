import React from 'react';
import './BackgroundImages.scss';
import ImagesBackground from '../../../../Images/fondo_imagenes.png';
import ImagesButton from '../../../../Images/boton_imagenes.png';

const BackgroundImagenes = (props) => {
    let {children, onLeft, onRight, buttons} = props;

    return (
        <div className="background-images">
            <img className="background-images__background" src={ImagesBackground} alt="Multimedia background"/>
            <div className="background-images__loader">
                <div className="background-images__loader__resize">
                    {children}
                </div>
            </div>
            { buttons &&
            [
                <button key="background-image-prev" onClick={onLeft}>
                    <img src={ImagesButton} alt=""/>
                </button>,
                <button key="background-image-next" onClick={onRight}>
                    <img src={ImagesButton} alt=""/>
                </button>
            ]
            }
            
        </div>
    );
}

export default BackgroundImagenes;

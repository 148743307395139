
import {tipoElemento} from '../../../Constantes/Elementos';
import { httpPost} from '../../../Store/HttpCalls';
import {cargarNuevosElementoEditor} from '../../../Store/Actions/EditorElementosActions';
import {ruta} from '../../../Constantes/Rutas';
import {quitarPxFinal} from '../../../Services/CSSTratamiento';

  

let idActual=0;
export const getIdActual=()=> idActual;
export const controlador_ids =()=>{
  return idActual++;
};

let idLinkExercises=0
export const getIdLinkExercises=()=>idLinkExercises;
export const addIdLinkExercises=()=>{
    return idLinkExercises++;
}

export const regargarElementosDelServidor=(fichasElementos,dispatch)=>{

   

    let elementosEnvio=[];
    
    let isMultimedia=false;
    let newEstilo,newMultimedia,newItems=[],newIdLinkExercises=0;
    
    
    elementosEnvio=fichasElementos.map((elem)=>{
      
      controlador_ids();
      
    
      newEstilo={...elem.caracteristicas.css};
          delete newEstilo.top;
          delete newEstilo.left;

      if(elem.isMultimedia==true){
        newMultimedia = elem.caracteristicas.multimedia;
      }else {
        newMultimedia = undefined;
      }
      if(elem.caracteristicas.exercise!==undefined && 
        elem.caracteristicas.exercise.idLinkExercise!==undefined && 
        elem.caracteristicas.exercise.idLinkExercise>newIdLinkExercises){
          newIdLinkExercises=elem.caracteristicas.exercise.idLinkExercise;
        }
      
        
        return {
          Y:Number(elem.Y),
          X:Number(elem.X),
          ID:getIdActual(),
          tipoSeleccionado:elem.tipoSeleccionado,
          isMultimedia:elem.isMultimedia,
          textoEstatico:elem.caracteristicas.texto_estatico,
          estiloHijo:newEstilo,
          idRecurso:elem.idRecurso,
          caracteristicas:{
            ...elem.caracteristicas,
            animation:{...elem.caracteristicas.animation},
            multimedia:newMultimedia
          }
        }
          
    });

    
    dispatch(cargarNuevosElementoEditor(elementosEnvio));
    idLinkExercises=newIdLinkExercises;
    return elementosEnvio;
};

export const guardasElementosEnServidor=(divsPadres,ficha_id)=>{
  let elementosAEnviar=[];
  let newElem;
  let isMultimedia;
  let animation;

  
 

  divsPadres.map((elem)=>{
    if(elem.isMultimedia==1){
        isMultimedia=true;
       }else{
        isMultimedia=false;
       }

    

       
    if(elem.caracteristicas!=undefined){
      if(elem.caracteristicas.animation!=undefined){
        animation={...elem.caracteristicas.animation};
      }else{
        animation=undefined
      }
    }

        newElem={
          Y:elem.Y,
          X:elem.X,
          fichas_id:ficha_id,
          tipoSeleccionado:elem.tipoSeleccionado,
          isMultimedia:isMultimedia,
          idRecurso:elem.idRecurso,
          caracteristicas:{
            ...elem.caracteristicas,
            animation:animation,
            css:{
              ...elem.estiloHijo
            },
          texto_estatico:elem.textoEstatico
        },
          
        };


    
    elementosAEnviar=[...elementosAEnviar,newElem];
    
  });
  
  
  httpPost(ruta.editor +ficha_id,elementosAEnviar).then((elementos)=>{
    
  },(error)=>{
    console.error("nuestro error " + error)
})
  
  
};



import React,{useState,useEffect} from 'react';
import '../TestSelect/TestSelectViewer.scss';
import './ImageTestViewer.scss';
import NoteBook from '../../../../Images/CuadernoApaisado.png';
import MolTextAndInputText from '../../../Molecules/Mol_TextAndInputText/Mol_TextAndInputText';
import AtButton from '../../../Atoms/At_Button/At_Button';
import {typeQuestionOfIT} from '../../../../Constantes/ExercisesVars';


import {idiomas} from '../../../../Constantes/idiomas';

const ImageTestViewer=(props)=>{
    let {
        value,
        onSaveResponse,
        question,
        finished,
        style,
        typeQuestion
        
    }=props;
    
    let questionByIdiom;
    let answers;
    
    useEffect(() => {
        onSaveResponse([]);
    }, [])
    
   
    if(questionByIdiom!==undefined){
        answers=questionByIdiom.answers.map((elem)=>{
            return {...elem,answer:false}
        });
    }   

    if(questionByIdiom===[]){

    }
    const [selectValues,setSelectValues]=useState(value);

    const onChangeselectAnswer=(id)=>{
    
            let newValue=question.answers.filter((elem)=>{
                return elem.id==id;
            });
            
            onSaveResponse([...newValue]);
        
    }

    const onClickorrectExercise=()=>{
        let numCorrectAnswers=0;

        answers.map((elem)=>{
            //if(elem.value===true){
                if(elem.answer===elem.value){
                    numCorrectAnswers++;
                }
            //}
        });

        alert("Acertaste: "+numCorrectAnswers+ " de " + answers.length);
    }

    
    if(questionByIdiom===undefined){
        questionByIdiom={question:"pregunta",answers:[]};
    }
    let newSyle={
        ...style,
        fontSize:"20px",
    }
    return (
        <div className="image-test-viewer">
            <img src={NoteBook} className="image-test-viewer__background" alt="Notebook background"/>
            <div className="image-test-viewer__exercise">
                <div className="image-test-viewer__section"> 
                    {(typeQuestion==typeQuestionOfIT.image || typeQuestion===undefined) ?
                        <img src={question.question} className="image-test-viewer__question" height="100px" alt="Imagen no disponible"/> 
                    :
                    ""}

                    {typeQuestion==typeQuestionOfIT.text ? 
                            <div className="p-question" style={newSyle} dangerouslySetInnerHTML={{__html: question.question}}/>
                    :
                    ""}
                </div>
                <div className="image-test-viewer__section image-test-viewer__section-grid">
                    {question.answers.map((elem)=>{
                        return <div className="image-test-viewer__option">
                            <MolTextAndInputText 
                            className="image-test-viewer__option__mol-item" 
                            onChange={()=>{onChangeselectAnswer(elem.id)}} 
                            name="itemAnswerImageTestViewer"
                            type="radio"
                            invested>
                                <img className="image-test-viewer__option-img" src={elem.answer} alt="Imagen no disponible" />
                            </MolTextAndInputText>
                        </div>
                    })}
                </div>
                <div>
                    <AtButton onClick={finished}>Responder</AtButton>
                </div>
            </div>
        </div>
    )
}



export default ImageTestViewer;
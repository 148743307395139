import {getWidthAndHeightWhitOutPx} from '../../Services/CSSTratamiento';


export const types = {
    CREAR_ELEMENTO: 'CREAR_ELEMENTO',
    RECARGAR_PAGINA: 'RECARGAR_PAGINA',
    EDITAR_POSICION: 'EDITAR_POSICION',
    EDITAR_ESCALADO:"EDITAR_ESCALADO",
    EDITAR_CSS:'EDITAR_CSS',
    EDIT_TEXT:"EDIT_TEXT",
    ELIMINAR_ELEMETO:'ELIMINAR_ELEMETO',
    INSERT_MULTIMEDIA:'INSERT_MULTIMEDIA',
    INSERT_ANIMATION:"INSERT_ANIMATION",
    DELETE_MULTIMEDIA:'DELETE_MULTIMEDIA',
    DELETE_ANIMATION:"DELETE_ANIMATION",
    EDIT_EXERCISE:"EDIT_EXERCISE",
    DO_DROPPABLE:"DO_DROPPABLE",
    DELETE_DROPPABLE:"DELETE_DROPPABLE",
    SET_COLOR_SVG:"SET_COLOR_SVG",
    EMPTY_ITEMS:"EMPTY_ITEMS",
    EDIT_EXERCISE_FF_TEST:"EDIT_EXERCISE_FF_TEST",
    EDIT_EXERCISE_IT:"EDIT_EXERCISE_IT",
    SET_AUDIO_VIEWER_TYPE:"SET_AUDIO_VIEWER_TYPE",
    DO_DND_FREE:"DO_DND_FREE"
  };

  const EditorElementosReducer = (state = [], action) => {
    let newElements;
    let canvasSize;
    let elemetSize;
    let limitX;
    let limitY;
    let newPositionX;
    let newPositionY;
    switch (action.type) {

      case types.EMPTY_ITEMS:
       
        return [];
      

      case types.CREAR_ELEMENTO:
       
        return [...state,...action.payload];


      case types.RECARGAR_PAGINA:
      
        return [...action.payload];
          
        
      case types.EDITAR_POSICION:
        
        canvasSize=getWidthAndHeightWhitOutPx(action.canvasSize);
        elemetSize=getWidthAndHeightWhitOutPx(action.elementStyle);
        limitX=canvasSize[0]-elemetSize[0];
        limitY=canvasSize[1]-elemetSize[1];

        newPositionX=action.value.X;
        newPositionY=action.value.Y;

        if(action.value.X<0){
          newPositionX=0;
        }

        if(action.value.Y<0){
          newPositionY=0;
        }

        if(action.value.X>limitX){
          newPositionX=limitX;
        }
        
        if(action.value.Y>limitY){
          newPositionY=limitY;
        }
        
       
        newElements=state.map((elem)=>{
          if(elem.ID===action.value.ID){
            const newelem={...elem,X:newPositionX,Y:newPositionY}

            return newelem;
          }
          return elem;
        });
      
      return newElements;
      //------------------------------------------------------------------------------------------------------------------------------

      case types.EDITAR_ESCALADO:
        
        newElements=state.map((elem)=>{
          if(elem.ID===action.id){

            let newelem={...elem,estiloHijo:{...elem.estiloHijo,...action.css}};
            canvasSize=getWidthAndHeightWhitOutPx(action.canvasSize);
            elemetSize=getWidthAndHeightWhitOutPx(action.css);
            

            let newX,newY;
            //izquierda
            if(newelem.X<0){

            }

            //arriba
            if(newelem.Y<0){}


            //derecha
            if((newelem.X+elemetSize[0]>canvasSize[0])){
             
              newX=canvasSize[0]-elemetSize[0];
              newelem={...newelem,X:newX}
            }

            //abajo
            if((newelem.Y+elemetSize[1]>canvasSize[1])){
              
              newY=canvasSize[1]-elemetSize[1];
              newelem={...newelem,Y:newY}
            }

            return newelem;
          }
          return elem;
        });
      
      return newElements;

      //------------------------------------------------------------------------------------------------------------------------------
      
      case types.EDIT_TEXT:
        
        newElements=state.map((elem)=>{
          if(elem.ID===action.id){
            const newelem={
              ...elem,
              caracteristicas:{
                ...elem.caracteristicas,
                texto_estatico:action.text
              },
            }

            return newelem;
          }
          return elem;
        });


      return newElements;
          
      

      case types.EDITAR_CSS:

        newElements=state.map((elem)=>{
          if(elem.ID===action.id){

            const newelem={...elem,estiloHijo:{...elem.estiloHijo,...action.css}};
            
           
            return newelem;
          }
          return elem;
        });
      
      return newElements;

        case types.ELIMINAR_ELEMETO:
          newElements=state.filter((elem)=>{
            return elem.ID!==action.id;
          });
          return newElements


      case types.INSERT_MULTIMEDIA:
          
          newElements=state.map((elem)=>{
              if(action.id===elem.ID){
                let newElem;
                if(action.multimedia[0].tipo === "imagen"){
                 
                  let newMultimedia = elem.caracteristicas === undefined ? [] : elem.caracteristicas.multimedia;
                  if(Array.isArray(newMultimedia)){
                    newMultimedia.push({
                      ...action.multimedia[0],
                      items:action.multimedia[0].items
                    });
                  }else{
                    newMultimedia = [{
                      ...action.multimedia[0],
                      items:action.multimedia[0].items
                    }];
                  }
                  newElem = {
                    ...elem,
                    idRecurso:action.multimedia[0].id,
                    isMultimedia:1,
                    caracteristicas:{
                        ...elem.caracteristicas,
                        multimedia: newMultimedia
                    }
                  };
                }else{
                  newElem = {
                    ...elem,
                    idRecurso:action.multimedia[0].id,
                    isMultimedia:1,
                    caracteristicas:{
                        ...elem.caracteristicas,
                        multimedia:{
                          ...action.multimedia[0],
                          items:action.multimedia[0].items
                        }
                    }
                  };
                }
              
              return newElem;
            }
            return elem;
          });
          
          return newElements;


        
      case types.INSERT_ANIMATION:
        newElements=state.map((elem)=>{
          if(action.id===elem.ID){
            
          const newElem={
            ...elem,
            caracteristicas:{
                ...elem.caracteristicas,
                //añadimos animacion
                animation:{...action.animation}
            }
          }
          
          return newElem;
          }

        return elem;
         });
      
        return newElements;

      case types.DELETE_MULTIMEDIA:
        newElements=state.map((elem)=>{
          if(action.id===elem.ID){
            
          const newElem={
            ...elem,
            idRecurso:null,
            isMultimedia:0,
            caracteristicas:{
                ...elem.caracteristicas,
                multimedia:{
                  
                }
            }
          }
          
          return newElem;
        }

        return elem;
         });
      
        return newElements;
        

      case types.DELETE_ANIMATION:
        newElements=state.map((elem)=>{
          if(action.id===elem.ID){
            
          const newElem={
            ...elem,
            caracteristicas:{
                ...elem.caracteristicas,
                //borramos animacion
                animation:{}
            }
          }
          
          return newElem;
          }

        return elem;
         });
      
        return newElements;

         case types.EDIT_EXERCISE:
           
           newElements=state.map((elem)=>{
            
              if(action.id===elem.ID){
                
                const newElem={
                  ...elem,
                  caracteristicas:{
                      ...elem.caracteristicas,
                      exercise:{
                        typeExercise:elem.caracteristicas.exercise.typeExercise,
                        question:action.exercise
                      },
                  }
                }
                
                return newElem;

              }

              return elem;
           })
           
           return newElements;

        case types.EDIT_EXERCISE_FF_TEST:

          newElements=state.map((elem)=>{
            
            if(elem.caracteristicas.exercise!==undefined && 
              elem.caracteristicas.exercise.idLinkExercise!==undefined && 
              elem.caracteristicas.exercise.idLinkExercise==action.idLink){
                
              const newElem={
                ...elem,
                caracteristicas:{
                    ...elem.caracteristicas,
                    exercise:{
                      ...elem.caracteristicas.exercise,
                      questionData:action.exercise.questionData,
                      fontStyle:action.exercise.fontStyle,
                      positionAnswer:action.exercise.positionAnswer,
                      iconPrevAnswer:action.exercise.iconPrevAnswer,
                    },
                }
              }
         
              return newElem;
            }

            return elem;
         });
         
         return newElements;

         case types.EDIT_EXERCISE_IT:

          newElements=state.map((elem)=>{
            
            if(elem.caracteristicas.exercise!==undefined && 
              elem.caracteristicas.exercise.idLinkExercise!==undefined && 
              elem.caracteristicas.exercise.idLinkExercise==action.idLink){
                
              const newElem={
                ...elem,
                caracteristicas:{
                    ...elem.caracteristicas,
                    exercise:{
                      ...elem.caracteristicas.exercise,
                      questionData:action.exercise.questionData,
                      fontStyle:action.exercise.fontStyle,
                      positionAnswer:action.exercise.positionAnswer,
                      iconPrevAnswer:action.exercise.iconPrevAnswer,
                      typeQuestion:action.exercise.typeQuestion,
                    },
                }
              }
         
              return newElem;
            }

            return elem;
         });
         
         return newElements;

        case types.DO_DROPPABLE:

           newElements=state.map((elem)=>{
              if(elem.ID===action.id){
                const newElem={
                  ...elem,
                  caracteristicas:{
                      ...elem.caracteristicas,
                      isDraggable:true,
                      idDroppable:action.idDroppable
                  }
                }

                return newElem;
              }

              return elem;
           });
          
           return newElements

        case types.DELETE_DROPPABLE:

            newElements=state.map((elem)=>{
               if(elem.ID===action.id){
                 const newElem={
                   ...elem,
                   caracteristicas:{
                       ...elem.caracteristicas,
                       isDraggable:false,
                       idDroppable:action.idDroppable
                   }
                 }
 
                 return newElem;
               }
 
               return elem;
            });
           
            return newElements

        case types.SET_COLOR_SVG:

          newElements=state.map((elem)=>{
            if(elem.ID===action.id){
              const newElem={
                ...elem,
                caracteristicas:{
                    ...elem.caracteristicas,
                    exercise:{
                      ...elem.caracteristicas.exercise,
                      colorSvg:action.color
                    },
                }
              }
             
              return newElem;
            }

            return elem;
         });

           return newElements

        case types.SET_AUDIO_VIEWER_TYPE:
         
          newElements=state.map((elem)=>{
            if(elem.ID===action.id){
              
             
             let newElement={
                ...elem,
                caracteristicas:{
                  ...elem.caracteristicas,
                  multimedia:{
                    ...elem.caracteristicas.multimedia,
                    audioViewerType:action.newType
                  }
                }
              }
              
              return newElement;
            }

            return elem;
         });

        return newElements

        case types.DO_DND_FREE:
          
          newElements=state.map((elem)=>{
            if(elem.ID===action.id){
              
             
             let newElement={
                ...elem,
                caracteristicas:{
                  ...elem.caracteristicas,
                  isDndFree:action.isDnd
                }
              }
              
              return newElement;
            }

            return elem;
         });
         
        return newElements;

      default:
        return state;
    }
  };

  export default EditorElementosReducer;
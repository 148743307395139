import React, {useState, useEffect} from 'react';
import './Mol_Comments.scss';
import At_Button from '../../Atoms/At_Button/At_Button';
import { typeCss } from '../../../Constantes/TypeCss';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {httpGet, httpPost} from '../../../Store/HttpCalls';
import {ruta} from '../../../Constantes/Rutas';
import EmojiPicker from 'emoji-picker-react';

const Mol_Comments = (props) => {
    const {
        fichaId,
        profesorId,
        idStudent,
    }=props;

    const [message, setMessage] = useState("");
    const [messageList, setMessageList] = useState([]);
    
    useEffect(() => {
        const datos = profesorId + "/" + idStudent + "/" + fichaId;
        httpGet(ruta.getComentariosFichas + datos).then((values) => { 
            setMessageList(values.map(value => {
                return value.comentario;
            })
        )
        });
    }, []);

    const saveMessage = () => {
        let thisMessage;
        thisMessage={
            profesor_id: profesorId,
            alumno_id: idStudent,
            comentario: message,
            ficha_id: fichaId
        }

        httpPost(ruta.postComentariosFichas,thisMessage).then(()=>{
                
        },(error)=>{
            console.error("error en el envío de comentarios" + error)
        });
        messageList.push(message);
        setMessageList(messageList);
        setMessage("");
    }

    return <div className='mol_comments'>
             { messageList.map((message, index) =>{
                  return <div key={`message-${index}`}className='mol_comments_comment'>{message}</div>
             })}
            <div className='mol_comments_new-mesage'>
            <textarea name="comment-text" id="comment-text" onChange={(e) => setMessage(e.target.value)} value={message}></textarea>
            {message.length !== 0 &&
                <At_Button type={typeCss.secondary} onClick={saveMessage}>Enviar</At_Button>
            }
            <br/><br/>
            <div><EmojiPicker height={400} width={310} reactionsDefaultOpen={true} allowExpandReactions={true} 
            //TODO Añadir reacciones adecuadas: https://apps.timwhitlock.info/emoji/tables/unicode 
            //quitar del código U+ y poner la f en minúscula.
            reactions={['1f600', '1f601', '1f680', '1f621']}
            onEmojiClick={(emojiObject) => setMessage((message) => message + emojiObject.emoji)}/></div>
            </div>
        </div>
}

export default Mol_Comments;
import React from 'react';
import {ruta} from '../../Constantes/Rutas.js';
import {httpGet,httpPost,httpDelete} from '../../Store/HttpCalls.jsx';
import {exercises} from '../../Constantes/Exercises';

export const SendExercisesToBbdd=(exercisesToSend,idStudent,idFile)=>{
   
    let thisMessage;
    httpDelete(ruta.deleteOldExercises+idStudent+"/"+idFile).then(()=>{
        exercisesToSend.map((elem)=>{
            switch (elem.typeExercise) {
                case exercises.textSelect:
                    thisMessage={
                        idStudent:idStudent,
                        idFile:idFile,
                        exerciseData:{
                            idLinkExercise:elem.thisExercise.idLinkExercise,
                            answer:elem.answer,
                            typeExercise:exercises.textSelect,
                        }
                    }
                   
                    httpPost(ruta.setExercises,thisMessage).then(()=>{
                
                    },(error)=>{
                        console.error("nuestro error " + error)
                    });
                    break;
                case exercises.imagesTest:
                    thisMessage={
                        idStudent:idStudent,
                        idFile:idFile,
                        exerciseData:{
                            idLinkExercise:elem.thisExercise.idLinkExercise,
                            answer:elem.answer,
                            typeExercise:exercises.imagesTest,
                        }
                    }
                   
                    httpPost(ruta.setExercises,thisMessage).then(()=>{
                
                    },(error)=>{
                        console.error("nuestro error " + error)
                    });
                    break;
                case exercises.fillableField:
                 
                    thisMessage={
                        idStudent:idStudent,
                        idFile:idFile,
                        exerciseData:{
                            idLinkExercise:elem.thisExercise.idLinkExercise,
                            answer:elem.answer,
                            typeExercise:exercises.fillableField,
                            correct:elem.isCorrect
                        }
                    }
                 
                    httpPost(ruta.setExercises,thisMessage).then(()=>{
                
                    },(error)=>{
                        console.error("nuestro error " + error)
                    });
                    break;

                case exercises.dragAndDrop:
                 
                    thisMessage={
                        idStudent:idStudent,
                        idFile:idFile,
                        exerciseData:{
                            idLinkExercise:elem.dndId,
                            typeExercise:exercises.dragAndDrop,
                            correct:elem.isCorrect
                        }
                    }
           
                    httpPost(ruta.setExercises,thisMessage).then(()=>{
                
                    },(error)=>{
                        console.error("nuestro error " + error)
                    });
                    break;

                default:
                   
                    break;
            }
            
        });
    },(error)=>{
        console.error("nuestro error " + error)
    });

}


export const deleteOldExercises=(idStudent,idFile)=>{
    httpDelete(ruta.deleteOldExercises+idStudent+"/"+idFile).then(()=>{
    
    },(error)=>{
        console.error("nuestro error " + error)
    });
}


export const deleteOldStaticsFiles=(idStudent,idFile)=>{
    httpDelete(ruta.deleteOldStaticsFiles+idStudent+"/"+idFile).then(()=>{
    
    },(error)=>{
        console.error("nuestro error " + error)
    });
}

export const getExercises=(idStudent,idFile,setAnswerExercises)=>{
    httpGet(ruta.getExercises+idStudent+"/"+idFile).then((exercises)=>{
        setAnswerExercises(exercises);
    },(error)=>{
        console.error("nuestro error " + error)
    });

}

export const setStaticsFiles=(idStudent,idFile,staticsFiles)=>{
 
    httpDelete(ruta.deleteOldStaticsFiles+idStudent+"/"+idFile).then(()=>{
    
        httpPost(ruta.setStaticsFiles,staticsFiles).then(()=>{
                
        },(error)=>{
            console.error("nuestro error " + error)
        });
    },(error)=>{
        console.error("nuestro error " + error)
    });
    
}

export const getStaticsFiles=(idStudent,idFile,setStaticsFiles,setDndExercise)=>{
    httpGet(ruta.getStaticsFiles+idStudent+"/"+idFile).then((staticsFiles)=>{
        
        setStaticsFiles(staticsFiles);
        if(staticsFiles!==undefined && staticsFiles[0]!==undefined && staticsFiles[0].dndExercise!==undefined){
            setDndExercise(staticsFiles[0].dndExercise);
        }
    },(error)=>{
        console.error("nuestro error " + error)
    });

}

import { types } from '../Reducers/RecursosItemsReducer';


export const createRecursosItems = (values) => ({
  type: types.POST,
  payload: values,
});

export const updateRecursosItems = (values) => ({
  type: types.PUT,
  payload: values,
});

export const removeRecursosItems = (id) => ({
  type: types.DELETE,
  id,
});

export const reloadRecursosItems = (items) => ({
  type: types.RELOAD,
  payload: items,
});

export const resetRecursosItems = () => ({
  type: types.RESET,
});
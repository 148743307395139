import React,{useState,useEffect} from 'react';
import {useSelector,shallowEqual,useDispatch} from 'react-redux';

import './At_Canvas.scss';

import { DragDropContext} from 'react-beautiful-dnd';
import {deleteAllFigure} from '../../../Store/Actions/FiguresInViewerAction';
import ElementoBase from './../../ComponentesEditor/ElementoBase/ElementoBase';
import MusicalNoteInViewer,{ImageFigure} from '../../ComponentesEditor/Exercises/BasicStaff/MusicalNoteInViewer';
import {onDragEnd} from '../../ComponentesEditor/Exercises/DragAndDrop/DragAndDropFunction';



const At_Canvas=(props)=>{
    let{
        style,
        editorElements,
        fileIdiom,
        viewer,
        exerciseData,
        scaleCanvas,
        figureSize,
        correctExercises,
        answerExercises
    }=props;
    
    
    const [lista,setLista]=useState();
    const dispatch=useDispatch();
    const idElemSeleccionado=useSelector((state)=>{

      return state.idElemSeleccionado;
    },shallowEqual);

    useEffect(()=>{
        
        setLista(exerciseData);

    },[exerciseData]);

    useEffect(() => {
      dispatch(deleteAllFigure());
    }, []);
    
    

    const onDragEndUpdateItemsDnd=(result)=>{
      
      let newLista=onDragEnd(result,lista.dnds);

      setLista({...lista,dnds:newLista});
    }

    const figuresInViewer=useSelector((state)=>{
      return state.figuresInViewer;
    },shallowEqual);

    

    const [cursorVisivility,setCursorVisivility]=useState({});
    let styleCanvas;

      if(style!=null){
        styleCanvas={
          ...style,
          ...cursorVisivility
          }
        
      }

    
    let styleReticle;
   
    if(style!=null){
      
      styleReticle={...style};
      delete styleReticle.backgroundColor;
      delete styleReticle.backgroundImage;
      }

    return (
        <div  className="at-canvas" style={styleCanvas}>
         
            <DragDropContext onDragEnd={onDragEndUpdateItemsDnd}>
            <div className="at-canvas__container" >
            
            { 
          
          editorElements.map((atributos) => {


            

              return (
                
                  
                    <ElementoBase 
                      key={`editor-${atributos.ID}`}
                      X={atributos.X} 
                      Y={atributos.Y} 
                      ID={atributos.ID} 
                      tipoSeleccionado={atributos.tipoSeleccionado} 
                      textoEstatico={atributos.textoEstatico} 
                      estiloHijo={atributos.estiloHijo} 
                      ref={atributos.referencia}  
                      idiomaFicha={fileIdiom}
                      isMultimedia={atributos.isMultimedia}
                      property={atributos.caracteristicas}
                      canvasSize={styleCanvas}
                      viewer={viewer}
                      dndExercise={lista}
                      selectedElement={idElemSeleccionado}
                      setCursorVisivility={setCursorVisivility}
                      scaleCanvas={scaleCanvas}
                      correctExercises={correctExercises}
                      answerExercises={answerExercises}
                    />
                  
                
              )
            
          })}
          {figuresInViewer.map((elem)=>{
            return <MusicalNoteInViewer key={"MusicalNoteInViewer"+elem.id} figureSize={figureSize} figure={elem} scaleCanvas={scaleCanvas}></MusicalNoteInViewer>
          })}
          
        </div>
        {viewer===true ? 
          ""
        :
          <div id="lienzo_reticula"  style={styleReticle}></div>
        }
        
        </DragDropContext>
       
        </div>
    )
}



export default At_Canvas;

import React,{useEffect,useState} from 'react';
import {
    useLocation
  } from "react-router-dom";
import {useSelector,shallowEqual} from 'react-redux';


import './Org_MainHorizontalPanel.scss';


import OrgLinksPanel from './../Org_LinksPanel/Org_LinksPanel';
import AtTitle from './../../Atoms/At_Title/At_Title';
import OrgRightMainPanel from '../Org_RightMainPanel/Org_RightMainPanel';


const Org_MainHorizontalPanel =()=>{
    
    


    let thisFile=useSelector((state)=>{
          
        return state.estaFicha;
      },shallowEqual);

    const [showController,setShowController]=useState({
                mainPanel:true,        
                orgRightMainPanel:true,
                buttonSave:true,
                titleFile:false,
            });
    const [classBodyHtml, setClassBodyHtml] = useState("");
    let showOrgRightMainPanel=false;
    let mainTitle;
    let url=useLocation();
    
    useEffect(() => {
        
        if(url.pathname.includes("fichas")){
            setShowController({
                mainPanel:true,        
                orgRightMainPanel:false,
                buttonSave:false,
                titleFile:false,
            });
            document.getElementsByTagName('body')[0].style.overflow="auto";
        }
        if(url.pathname.includes("login")){
            setShowController({
                mainPanel:false,        
                orgRightMainPanel:false,
                buttonSave:false,
                titleFile:false,
            });
            document.getElementsByTagName('body')[0].style.overflow="auto";
        }
        if(url.pathname.includes("recursos")){
            setShowController({
                mainPanel:true,        
                orgRightMainPanel:false,
                buttonSave:false,
                titleFile:false,
            });
            document.getElementsByTagName('body')[0].style.overflow="auto";
        }
        if(url.pathname.includes("editor")){
            setShowController({
                mainPanel:true,        
                orgRightMainPanel:true,
                buttonSave:true,
                titleFile:true,
            });
            document.getElementsByTagName('body')[0].style.overflow="auto";
        }
        if(url.pathname.includes("viewer")){
            setShowController({
                mainPanel:false,        
                orgRightMainPanel:true,
                buttonSave:true,
                titleFile:false,
            });
            
            document.getElementsByTagName('body')[0].style.overflow="hidden";
            document.getElementsByTagName('body')[0].style.position="relative";
        }
    }, [url])

    if(thisFile[0]!==undefined){
        showOrgRightMainPanel=true;
    }

    if(thisFile[0]!==undefined ){
        mainTitle=thisFile[0].nombre.castellano;
    }else{
        mainTitle="La musica y yo";
    }

    

   

   

    return (
            <div className={showController.mainPanel ? "org-main-horizontal-panel" : "org-main-horizontal-panel__display-none"}>
                <div className="org-main-horizontal-panel__item">
                    <OrgLinksPanel></OrgLinksPanel>
                </div>

                <div className="org-main-horizontal-panel__item org-main-horizontal-panel__item--title">
                    <AtTitle>{showController.titleFile ?  mainTitle : "La musica y yo" }</AtTitle>
                </div>
                
                <div className="org-main-horizontal-panel__item">
                    {
                        showController.orgRightMainPanel ? <OrgRightMainPanel buttonSave={showController.buttonSave}></OrgRightMainPanel> : ""
                    }
                    
                </div>
            </div>
    )
}

export default Org_MainHorizontalPanel;

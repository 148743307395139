export const tipoRecurso={
    imagen:'imagen',
    spotify:'spotify',
    youtube:'youtube',
    bandcamp:'bandcamp',
    vimeo:'vimeo',
    pdf:'pdf',
    audios:'audios',
    svg:"svg",
   
}

export const multimediaTypes=[
    
    ["imagen",'Imagen'],
    ["spotify",'Spotify'],
    ["youtube",'Youtube'],
    ['bandcamp','Bandcamp'],
    ["vimeo",'Vimeo'],
    ["pdf",'Pdf'],
    ["audios",'Audios'],
    ["svg","svg"]
  

]

export const AudioViewerType=[
    ["popup","Popup"],
    ["flotante","Flotante"],
    ["click","Click"],
]

export const AudioViewerTypeJson={
    popup:"popup",
    flotante:"flotante",
    click:"click",
}
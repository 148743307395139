import React from 'react';
import {useDispatch,useSelector,shallowEqual} from 'react-redux';

import {tipoElemento} from '../../../../Constantes/Elementos';
import {increaseDndId,deleteDndId} from '../../../../Store/Actions/EstaFichasActions.js';
import {doDroppable,deleteDroppable} from '../../../../Store/Actions/EditorElementosActions.js';
import './DragAndDropCreate.scss';

import DndListCreate from './DndItemCreate/DndListCreate';

const DragAndDropCreate=(props)=>{
    let {
        idDroppableCorrect
    }=props

    
    
    let dispatch=useDispatch();

    const estaFicha=useSelector((state)=>{
          
        return state.estaFicha;
      },shallowEqual);
    

    const doDraggable=(elementSelected)=>{
       
        let contentToElemet={...elementSelected.estiloHijo};
        delete contentToElemet.position;
       
        let myIdDroppable=estaFicha[0].caracteristicas.dataExercise.lastIdDnd;

        //darle info nueva al elemento
        dispatch(doDroppable(elementSelected.ID,myIdDroppable));

        //registrarlo en la lista de zonas dnd
        let myDroppable;

        if(elementSelected.caracteristicas!==undefined &&
            elementSelected.caracteristicas.typeElementMultimedia!==undefined && 
            elementSelected.caracteristicas.typeElementMultimedia==tipoElemento.Image){
            
            myDroppable=[
                {
                    id:myIdDroppable,
                    items:[
                        {
                            id: "item-"+myIdDroppable, 
                            content:contentToElemet, 
                            corretContainer:idDroppableCorrect,
                            itemsMultimedia:elementSelected.caracteristicas.image.itemsMultimedia
                    }]
                }
            ];
        }else{
            myDroppable=[
                {
                    id:myIdDroppable,
                    items:[{id: "item-"+myIdDroppable, content:contentToElemet, corretContainer:idDroppableCorrect}]
                }
            ];
        }
        
        dispatch(increaseDndId(myDroppable));

       
    }

    const deleteDraggable=(elementSelected)=>{
        
   
        //eliminar que el elemento sea draggable
        dispatch(deleteDroppable(elementSelected.ID,-1));

        //eliminar de la lista
        dispatch(deleteDndId(elementSelected.caracteristicas.idDroppable));
        
    }
   
    return(
        <div>
            <DndListCreate 
                filterBy={[1,2,3,4,5,8]} 
                thisIdDndDroppable={idDroppableCorrect}
                dndList={estaFicha[0].caracteristicas.dataExercise.dnds}
                onClickOnElement={doDraggable}
                deleteElement={deleteDraggable}
                ></DndListCreate>
        </div>
    )
};


export default DragAndDropCreate;
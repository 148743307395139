import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector,shallowEqual} from 'react-redux';
import {httpPostLogin} from '../../Store/HttpCalls.jsx';
import {useLocation} from "react-router-dom";
import {ruta} from '../../Constantes/Rutas.js';
import {splitUrlSearch} from '../../Services/OperacionesStrings';
import { useHistory } from "react-router-dom";

import AtInputText from '../Atoms/At_InputText/At_InputText';
import AtButton from '../Atoms/At_Button/At_Button';
import './Login.scss';

const Login =()=>{
    let history=useHistory();
    let url=useLocation();
    let urlVars=splitUrlSearch(url.search);
    

    const [logData,setLogData]=useState({
        //email:"test1@lmyy.es",
        //password:"test1",
        remember_me:true
    });

    const onChangeUserLogData=(newUser)=>{
        setLogData({...logData,email:newUser});
    }
    const onChangePassLogData=(newPassword)=>{
        setLogData({...logData,password:newPassword});
    }
    const onChangeRememberLogData=(newRemember)=>{
        setLogData({...logData,remember_me:newRemember});
    }

    const onClickLogin=()=>{
       
        httpPostLogin(ruta.login,logData).then((token)=>{
            
            if(token.access_token!==undefined){
                sessionStorage.setItem('login_lmyy2_access_token', token.access_token);
                sessionStorage.setItem('login_lmyy2_expires_at', token.expires_at);
                sessionStorage.setItem('login_lmyy2_token_type', token.token_type);
                if(url.search!==undefined){
                    history.push(urlVars[0][1]);
                }else{
                    history.push(ruta.loginFront);
                }
                
            }

        },(error)=>{
            console.error("nuestro error " + error)
        });
        
    }

    const onClickLogOut=()=>{
        sessionStorage.removeItem('login_lmyy2_access_token');
        sessionStorage.removeItem('login_lmyy2_expires_at');
        sessionStorage.removeItem('login_lmyy2_token_type');

        history.push(ruta.loginFront);
    }

    if(sessionStorage.getItem("login_lmyy2_access_token")!==null){
        return(
            <div className="login">
                <AtButton className="login__btn-login" onClick={onClickLogOut}>Cerrar sesion</AtButton>
            </div>
        )
    }else{
        return(
            <div className="login">
                <AtInputText className="login__user-text" type="text" placeholder="Usuario" onChange={event=>onChangeUserLogData(event.target.value)}></AtInputText>
                <AtInputText className="login__password-text" type="password" placeholder="Contraseña" onChange={event=>onChangePassLogData(event.target.value)}></AtInputText>
                <AtButton className="login__btn-login" onClick={onClickLogin}>Entrar</AtButton>
            </div>
        )
    }

    
}

export default Login;
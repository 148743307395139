import React from 'react';
import './Org_LinksPanel.scss';
import MyLink from './../../Atoms/MyLink/MyLink';



const Org_LinksPanel =() => {

    return (
            <div className="org-links-panel">
                
                <div className="org-links-panel__item--margin-left org-links-panel__item">
                    <MyLink to="/login" text="Inicio"></MyLink>
                </div>
                <div className="org-links-panel__item--margin-left org-links-panel__item">
                    <MyLink to="/fichas" text="Fichas"></MyLink>
                </div>

                <div className="org-links-panel__item--margin-left org-links-panel__item">
                    <MyLink to="/recursos" text="Recursos"></MyLink>
                </div>
            </div>
    )
}

export default Org_LinksPanel;

import { types } from '../Reducers/IdiomReducer';




export const updateIdiom = (values) => ({
  type: types.PUT,
  idiom: values,
});


import React from 'react';
import './VimeoMultimedia.scss';
import {idiomas} from '../../../../Constantes/idiomas';
import BackgroundMultimedia from './BackgroundMultimedia';
import './BandcampMultimedia.scss';

const BandcampMultimedia=(props)=>{
    let {
        language, 
        multimedia
    }=props
    
       
    let imageUrl=multimedia.items.filter((elem)=>{
        return elem.language===language;

    });
    if(imageUrl.length===0){
        imageUrl=multimedia.items.filter((elem)=>{
          return elem.language===idiomas.castellano;
        });
    }
      
    return (
        <BackgroundMultimedia>
            <div className="bandcamp-player__container">
                <iframe 
                    title="Bandcamp player" 
                    src={imageUrl[0].link} 
                    width="100%" 
                    height="100%" 
                    frameborder="0" 
                />
            </div>
        </BackgroundMultimedia>
    );
}

export default BandcampMultimedia;
import React,{ useState,useEffect } from 'react';
import {useDispatch,useSelector,shallowEqual} from 'react-redux';

import Popup from "reactjs-popup";
import AtPopup from '../../Atoms/At_Popup/At_Popup';

import {obtenerGradosRotacion,quitarPxFinal,desglosarBorder} from '../../../Services/CSSTratamiento.js';
import {editarPosicionAc,editarCssAc,eliminarElementoAc,deleteMultimedia,deleteAnimation,editText,editarEscaladoAc,setAudioViewerType,doDndFree,deleteDroppable} from '../../../Store/Actions/EditorElementosActions.js';
import {setFigureSize} from '../../../Store/Actions/EstaFichasActions';
import {pegarElemento} from '../PanelHerramientas/PanelHerramientasControlador'
import {seleccionarIdiomaTextoEstatico,editarJsonTextoEstatico,selectEnrichedText} from '../../../Services/FuncionesIdiomas';

import InsertMultimediaIntoElement from './InsertMultimediaIntoElement';
import InsertAnimationIntoElement from './InsertAnimationIntoElement';
import InsertButtonEditExercise from './InsertButtonEditExercise';
import './PanelModifier.scss';
import '../../../Styles/GeneralCssClasses.scss';

import MolTextAndInputText from '../../Molecules/Mol_TextAndInputText/Mol_TextAndInputText';
import AtButton from '../../Atoms/At_Button/At_Button';
import {typeCss} from '../../../Constantes/TypeCss';
import Template from '../../../ImagenesPrueba/home.png';
import AtTitle from '../../Atoms/At_Title/At_Title';
import AtSelect from '../../Atoms/At_Select/At_Select';
import MolAccordionMenu from '../../Molecules/Mol_AccordionMenu/Mol_AccordionMenu';
import MolMultimediaSelected from '../../Molecules/Mol_MultimediaSelected/Mol_MultimediaSelected';
import MolAnimationSelected from '../../Molecules/Mol_AnimationSelected/Mol_AnimationSelected';
import AtEnrichedText,{toolbarTypes} from '../../Atoms/At_EnrichedText/At_EnrichedText';




import {borderTypes} from '../../../Constantes/BorderTypes';
import {textFonts} from '../../../Constantes/TextFonts';
import {modifierPanelSubmenu} from '../../../Constantes/ModifierPanelSubmenu';
import { ImageIcon } from '../../Atoms/At_Image/At_Image.jsx';
import { tipoElemento } from '../../../Constantes/Elementos';
import { multimediaTypes,tipoRecurso, AudioViewerType } from '../../../Constantes/TiposRecursos';

const PanelEditor = (props) => {
  let {
    idiomaFicha,
    canvasSize,
    thisFile,
    figureSize
  } = props;

    let dispatch=useDispatch();
    const elementosEditor=useSelector((state)=>{
      return state.elementosEditor;
    },shallowEqual);

    

    const idElemSeleccionado=useSelector((state)=>{

      return state.idElemSeleccionado;
    },shallowEqual);

      let id=idElemSeleccionado;
      let elementoActual;
      let [X,setX]=useState();
      let [Y,setY]=useState();
      let [altura,setAltura]=useState();
      let [anchura,setAnchura]=useState();
      let [rotacion,setRotacion]=useState();
      let [textoEstatico,setTextoEstatico]=useState();
      let [enrichedText,setEnrichedText]=useState();
      let [anchoBorde,setAnchoBorde]=useState();
      let [tipoBorde,setTipoBorde]=useState();
      let [borderRadius,setBorderRadius]=useState();
      let [colorBorde,setColorBorde]=useState();
      let [color,setColor]=useState();
      let [colortext,setColorText]=useState();
      let [fontSize,setFontSize]=useState();
      let [profundidad,setProfundidad]=useState();
      const [fontFamily,setFontFamily]=useState();

      const [thisFigureSize,setThisFigureSize]=useState(figureSize);

      useEffect(() => {
        if(figureSize===undefined){
          setThisFigureSize(40);
        }else{
          setThisFigureSize(figureSize);
        }
        
      }, [figureSize])
      
      let [copia,setCopia]=useState();
      elementoActual=elementosEditor.filter((elem)=>{return elem.ID === idElemSeleccionado});
      useEffect(()=>{
        let bordeDesglosado;
        
        
        if(elementoActual[0]!=null){
          
          setX(elementoActual[0].X);
          setY(elementoActual[0].Y);
          if(elementoActual[0].estiloHijo.height!=="auto"){
            setAltura(quitarPxFinal(elementoActual[0].estiloHijo.height));
          }else{
            setAltura(0);
          }

          if(elementoActual[0].estiloHijo.height!=="auto"){
            setAnchura(quitarPxFinal(elementoActual[0].estiloHijo.width));
          }else{
            setAnchura(0);
          }
          
          
          setRotacion(obtenerGradosRotacion(elementoActual[0].estiloHijo.transform));


          
          if(elementoActual[0].textoEstatico!=null){
            
            if(elementoActual[0].tipoSeleccionado==tipoElemento.TextoEstatico){
              setTextoEstatico(seleccionarIdiomaTextoEstatico(idiomaFicha,elementoActual[0].textoEstatico));
            }

            if(elementoActual[0].tipoSeleccionado==tipoElemento.EnrichedText){
              setEnrichedText(selectEnrichedText(idiomaFicha,elementoActual[0].textoEstatico));
            }

            
            
            if(elementoActual[0].estiloHijo.color!==undefined){
              setColorText(elementoActual[0].estiloHijo.color);
            }


            if(elementoActual[0].estiloHijo.fontSize!=undefined){
              setFontSize(quitarPxFinal(elementoActual[0].estiloHijo.fontSize));
            }

            if(elementoActual[0].estiloHijo.fontFamily!=undefined){
              setFontFamily(elementoActual[0].estiloHijo.fontFamily);
            }
            
            
          }
          bordeDesglosado=desglosarBorder(elementoActual[0].estiloHijo.border);
          
          setAnchoBorde(bordeDesglosado[0]);
          setTipoBorde(bordeDesglosado[1]);
          setColorBorde(bordeDesglosado[2]);

          if(elementoActual[0].estiloHijo.borderRadius!==undefined){
            setBorderRadius(quitarPxFinal(elementoActual[0].estiloHijo.borderRadius));
          }else{
            setBorderRadius(0);
          }
          
          setColor(elementoActual[0].estiloHijo.backgroundColor);
          
          setProfundidad(elementoActual[0].estiloHijo.zIndex);
        }
      },[idElemSeleccionado,elementosEditor,idiomaFicha]);


      const [nameButtonDndFree,setNameButtonDndFree]=useState();
      useEffect(() => {
        if(elementoActual[0]!==undefined){
          if(elementoActual[0].caracteristicas!==undefined){
            if(elementoActual[0].caracteristicas.isDndFree==true){
              setNameButtonDndFree(true);
            }else{
              setNameButtonDndFree(false);
            }
          }else{
            setNameButtonDndFree(false);
          }
        }else{
          setNameButtonDndFree(false);
        }
        
      }, [idElemSeleccionado,elementoActual[0]]);
          

      const cambioPosicion=(x,y)=>{
        
        dispatch(editarPosicionAc({ID:id,X:Number(x),Y:Number(y)},canvasSize,elementoActual[0].estiloHijo));
      };

      const cambioEscalado=(W,H)=>{
        
        
        if(W>quitarPxFinal(canvasSize.width) || H>quitarPxFinal(canvasSize.height)){
          alert("El ancho o el alto del elemento no puede ser mayor que el lienzo");
        }else{
          dispatch(editarEscaladoAc(id,{width:W+"px",height:H+"px"},canvasSize));
          
        }
        
      };

      const cambioRotacion=(rota)=>{
        dispatch(editarCssAc(id,{transform: `rotateZ( `+rota+`deg )`}));
      };
//TEXTO--------------------------------------------------------
      const cambioTextoEstatico=(txt)=>{
        
        let newTxt=editarJsonTextoEstatico(idiomaFicha,elementoActual[0].textoEstatico,txt);
        dispatch(editText(id,newTxt));
        
      };
      const changeText2=(txt)=>{
      
        let newTxt=editarJsonTextoEstatico(idiomaFicha,elementoActual[0].textoEstatico,txt);
        dispatch(editText(id,newTxt));
        
      };

      const onChangeCambioTextoNegrita=(event)=>{
        if(event){
          dispatch(editarCssAc(id,{fontWeight: "bold"}));
        }else{
          dispatch(editarCssAc(id,{fontWeight: "normal"}));
        }
        
      };

      const onChangeCambioSubrayado=(event)=>{
        if(event){
          dispatch(editarCssAc(id,{textDecoration: "underline"}));
        }else{
          dispatch(editarCssAc(id,{textDecoration: "none"}));
        }
        
      };
      
      const onChangeCambioCursiva=(event)=>{
        if(event){
          dispatch(editarCssAc(id,{fontStyle: "italic"}));
        }else{
          dispatch(editarCssAc(id,{fontStyle: "normal"}));
        }
        
      };
      
      const eventCambioFuenteLetra=(event)=>{
        
          dispatch(editarCssAc(id,{fontFamily: event}));
                
      };

      const eventChangeColorText=(event)=>{
        
        dispatch(editarCssAc(id,{color: event}));
              
    };

      const onChangeFontSize=(event)=>{
        dispatch(editarCssAc(id,{fontSize: event+"px"}));
      }

//ELIMINAR COPIAR PEGAR--------------------------------------
      const eliminarElemento=()=>{ 
        dispatch(eliminarElementoAc(id));
      };

      const eventCambioAnchoBorde=(anchoBor)=>{
        dispatch(editarCssAc(id,{border: anchoBor+"px "+ tipoBorde + " " + colorBorde}));
      };

      const eventRoundedBorder=(newBorderRadius)=>{
        dispatch(editarCssAc(id,{borderRadius: newBorderRadius+"px"}));
      };

      const eventCambioTipoBorde=(tipoBor)=>{
        dispatch(editarCssAc(id,{border: anchoBorde+"px "+ tipoBor + " " + colorBorde}));
      };

      const eventCambioColorBorde=(colorBor)=>{
        dispatch(editarCssAc(id,{border: anchoBorde+"px "+ tipoBorde + " " + colorBor}));
      };

      const eventCambioColor=(colorEnt)=>{
        dispatch(editarCssAc(id,{backgroundColor: colorEnt}));
      };

      const eventCambioZIndex=(Zind)=>{
        
        dispatch(editarCssAc(id,{zIndex: Zind}));
      };

      const onClickCopiar=()=>{
        setCopia(elementosEditor.filter((elem)=>{return elem.ID === idElemSeleccionado}));
        
        
      };
      const onClickPegar=()=>{
        pegarElemento(dispatch,copia,0,0);
      };

      const onClickDoDndFree=()=>{
        if(elementoActual[0]!==undefined){
          if(elementoActual[0].caracteristicas!==undefined &&
            elementoActual[0].caracteristicas.isDndFree!==undefined
            ){
  
              if(elementoActual[0].caracteristicas.isDndFree==true){
                dispatch(doDndFree(id,false));
              }else{
                dispatch(doDndFree(id,true));
              }
  
            }else{
              dispatch(doDndFree(id,true));
            }
        }
      }

      const onClickDeleteDraggable=()=>{
        if(elementoActual[0]!==undefined &&
          elementoActual[0].caracteristicas!==undefined &&
          elementoActual[0].caracteristicas.isDraggable!==undefined
          ){
            dispatch(deleteDroppable(id,elementoActual[0].caracteristicas.idDroppable));
          }
        
      }

      //---------------------------------------------------------------
      //popup--------------------------------------------------------------
      const [openPupupMultimedia, setOpenPupupMultimedia] = useState(false);
      const onClickClosePopupMultimedia = () => {
       
        setOpenPupupMultimedia(false);
      }

      const [openPupupAnimation, setOpenPupupAnimation] = useState(false);
      const onClickClosePopupAnimation = () => {
       
        setOpenPupupAnimation(false);
      }
     
      let [propertiesItemsDeactivate,setPropertiesItemsDeactivate]=useState(modifierPanelSubmenu);

      

      const onClickPropertiesDeactivate=(submenus)=>{

        let newStateMenus=propertiesItemsDeactivate.map((elem)=>{

          if(elem[0] === submenus){
            if(elem[1] === "activate"){

              return [submenus,"deactivate"];

            }else{

              return [submenus,"activate"];

            }
            
            
          }

          return elem;

        });

       

        setPropertiesItemsDeactivate(newStateMenus);

      }

      let stylePopupContent= {
         
          "width": "941px",
          "position": "relative",
          "background": "rgb(255, 255, 255)",
          "margin": "auto",
          "padding":0,
          "border":"none",
    }
    let [openCloseClassName,setOpenCloseClassName]=useState("");
    let [openCloseClassNameButton,setOpenCloseClassNameButton]=useState("");
    let [icoButton,setIcoButton]=useState(ImageIcon.chevronRightDouble);

    const onClickOpenCloseMenu=()=>{
      if(openCloseClassName === ""){
        setOpenCloseClassName("panel-modifier-controller__close");
        setIcoButton(ImageIcon.chevronLeftDouble);
        setOpenCloseClassNameButton("panel-modifier-controller__at-button__close");
      }else{
        setOpenCloseClassName("");
        setIcoButton(ImageIcon.chevronRightDouble);
        setOpenCloseClassNameButton("");
      }
    };


    const onClickDeleteMultimedia=()=>{

      dispatch(deleteMultimedia(idElemSeleccionado));
      
    }

    const onClickdeleteAnimation=()=>{

      dispatch(deleteAnimation(idElemSeleccionado));
      
    }

    const onChangeAudioViewerType=(newType)=>{
      dispatch(setAudioViewerType(id,newType));
    }
   
    //-------------------------------------------------------------------------

    const onChangeFigureSize=(newSize)=>{
      dispatch(setFigureSize(newSize));
    }

  return (   
    <div className={"panel-modifier-controller "}>

      

      <div className={"panel-modifier-controller-content "+openCloseClassName}>
          <div className="panel-modifier-controller__properties-button">
            <AtButton image={Template} onClick={()=>{onClickPropertiesDeactivate(modifierPanelSubmenu[0][0])}} type={typeCss.tertiary} widthImg={12} heightImg={6}  >Propiedades</AtButton>
          </div>

          <div className={propertiesItemsDeactivate[0][1] +" panel-modifier-controller__properties"}>

            

            <div className="properties__colum">
                <div className="properties__colum__item">

                  <AtTitle fontSize={11} >Tamaño</AtTitle>
                  <MolTextAndInputText  fontSize={11} widthInput={68}   onChange={event=>cambioEscalado(event.target.value,altura)}  type="number"  value={anchura}  min={0}  >Ancho:</MolTextAndInputText>
                  <MolTextAndInputText  fontSize={11} widthInput={68}   onChange={event=>cambioEscalado(anchura,event.target.value)}  type="number"  value={altura}  min={0}  >Alto:</MolTextAndInputText>

                </div>
                
                <div className="properties__colum__item">

                  <AtTitle fontSize={11} >Posición</AtTitle>          
                  <MolTextAndInputText  fontSize={11} text="X:"    widthInput={68}   onChange={event=>cambioPosicion(event.target.value,Y)}  type="float"  value={X}  min={0}  />            
                  <MolTextAndInputText  fontSize={11} text="Y:"    widthInput={68}   onChange={event=>cambioPosicion(X,event.target.value)}  type="float"  value={Y}  min={0}  />

                </div>
                
                <MolTextAndInputText  fontSize={11} widthInput={68}   onChange={event=>eventCambioColor(event.target.value)}  type="color"  value={color}  min={0}  >Fondo:</MolTextAndInputText>
                <AtButton onClick={()=>{eventCambioColor("transparent")}} type={typeCss.tertiary}>Sin fondo</AtButton>
            </div>

            {
            elementoActual[0]!==undefined &&
            (elementoActual[0].tipoSeleccionado==1 || 
            elementoActual[0].tipoSeleccionado==2 || 
            elementoActual[0].tipoSeleccionado==3 || 
            elementoActual[0].tipoSeleccionado==5 ||
            elementoActual[0].tipoSeleccionado==4) ? 
            <MolAccordionMenu 
            className={"properties__items"} 
            nameButton="Borde"
            defaultOpen={true}>

              <MolTextAndInputText  fontSize={11} widthInput={68} onChange={event=>eventCambioColorBorde(event.target.value)}  type="color"  value={colorBorde}  min={0}  >Color:</MolTextAndInputText>

              <MolTextAndInputText  fontSize={11} widthInput={40} onChange={event=>eventCambioAnchoBorde(event.target.value)}  type="number"  value={anchoBorde}  min={0}  >Grosor:</MolTextAndInputText>
                  
              <div className="border-Select">
                <AtSelect fontSize={11}  text="Tipo" fontColor={"#2E4467"}  widthSelect={184} values={borderTypes} onChange={event=>eventCambioTipoBorde(event.target.value)}></AtSelect>
              </div>

              <MolTextAndInputText className="border-Select"  fontSize={11} widthInput={40} onChange={event=>eventRoundedBorder(event.target.value)}  type="number"  value={borderRadius}  min={0}  >Radio de borde:</MolTextAndInputText>
            </MolAccordionMenu>
            : 
            ""}
            
            
            <MolAccordionMenu 
              className={"properties__colum "} 
              nameButton="Trasformaciones"
              defaultOpen={false}>

              <div className="properties__colum__item">

                <AtTitle fontSize={11} >Rotacion</AtTitle>
                <MolTextAndInputText fontSize={11}  widthInput={68}  onChange={event=>cambioRotacion(event.target.value)}  type="number" min="-180" max="180"  value={rotacion} >Grados:</MolTextAndInputText>
                <MolTextAndInputText widthInput={118} fontSize={11} step="1" onChange={event=>cambioRotacion(event.target.value)}  type="range" min="-180" max="180"  value={rotacion}  />

              </div>

              <div className="properties__colum__item">

                <AtTitle fontSize={11} >Profundidad</AtTitle>
                <MolTextAndInputText widthInput={40} fontSize={11} onChange={event=>eventCambioZIndex(event.target.value)} type="number" value={profundidad}  />
                
                
              </div>

            </MolAccordionMenu>
            
            {
            elementoActual[0]!==undefined &&
            elementoActual[0].tipoSeleccionado==3 ? 
            <MolAccordionMenu 
              className={"properties__items"}
              nameButton="Texto"
              defaultOpen={false}>

                <div className="text--2colums"> 
                  <MolTextAndInputText fontSize={11}  widthInput={184} type="text" value={textoEstatico} onChange={event=>cambioTextoEstatico(event.target.value)} >Texto:</MolTextAndInputText>
                </div>

                <div className="text--2colums"> 
                  
                  <AtSelect fontSize={11} fontColor={"#2E4467"}  widthSelect={184} values={textFonts} onChange={event=>eventCambioFuenteLetra(event.target.value)}>Fuente</AtSelect>
                </div>

                <MolTextAndInputText fontSize={11}  widthInput={68} type="text" value={fontSize} onChange={event=>onChangeFontSize(event.target.value)} >Tamaño:</MolTextAndInputText>

                <MolTextAndInputText fontSize={11} widthInput={40} type="color" value={colortext} onChange={event=>eventChangeColorText(event.target.value)} >Color:</MolTextAndInputText>

                <div>
                  <MolTextAndInputText fontSize={11} type="checkbox" value={textoEstatico} onChange={ event=>onChangeCambioTextoNegrita(event.target.checked)} >Negrita:</MolTextAndInputText>
                  <MolTextAndInputText fontSize={11} type="checkbox" value={textoEstatico} onChange={ event=>onChangeCambioSubrayado(event.target.checked)} >Subrayado:</MolTextAndInputText>
                  <MolTextAndInputText fontSize={11} type="checkbox" value={textoEstatico} onChange={ event=>onChangeCambioCursiva(event.target.checked)} >Cursiva:</MolTextAndInputText>
                </div> 
              
            </MolAccordionMenu>
            : ""}

          {
            elementoActual[0]!==undefined &&
            elementoActual[0].tipoSeleccionado==8 ? 
            <MolAccordionMenu 
              className={"properties__items"}
              nameButton="Texto2"
              defaultOpen={false}>

                <div className="text--2colums"> 
                <AtEnrichedText
                  toolbar={toolbarTypes.boldItalic}
                  value={enrichedText}
                  onChange={changeText2}
                ></AtEnrichedText>
                </div>

                <div className="text--2colums"> 
                  
                  <AtSelect fontSize={11} fontColor={"#2E4467"}  widthSelect={184} defaultValue={fontFamily} values={textFonts} onChange={event=>eventCambioFuenteLetra(event.target.value)}>Fuente</AtSelect>
                </div>

                <MolTextAndInputText fontSize={11}  widthInput={68} type="text" value={fontSize} onChange={event=>onChangeFontSize(event.target.value)} >Tamaño:</MolTextAndInputText>

                <MolTextAndInputText fontSize={11} widthInput={40} type="color" value={colortext} onChange={event=>eventChangeColorText(event.target.value)} >Color:</MolTextAndInputText>

                
              
            </MolAccordionMenu>
            : ""}
          
          {
            elementoActual[0]!==undefined &&
            (elementoActual[0].tipoSeleccionado==1 || 
            elementoActual[0].tipoSeleccionado==2 || 
            elementoActual[0].tipoSeleccionado==3 || 
            elementoActual[0].tipoSeleccionado==4 || 
            elementoActual[0].tipoSeleccionado==5 ||
            elementoActual[0].tipoSeleccionado==8) ? 
            <MolAccordionMenu 
              className={"properties__multimedia-Animation"}
              nameButton="Multimedia"
              defaultOpen={false}>

              <AtPopup open={openPupupMultimedia} trigger={<AtButton onClick={()=>{setOpenPupupMultimedia(true)}}>Insertar multimedia</AtButton>} className="popup-insert-multimedia">
                    <InsertMultimediaIntoElement closePopup={onClickClosePopupMultimedia} seeker idElement={idElemSeleccionado}></InsertMultimediaIntoElement>
              </AtPopup>
              
              
              <MolMultimediaSelected element={elementoActual}></MolMultimediaSelected>
              <AtButton onClick={onClickDeleteMultimedia}>Eliminar Multimedia</AtButton>
              
            </MolAccordionMenu>
          : ""}
          {
            (elementoActual[0]!==undefined &&
            elementoActual[0].isMultimedia==1 &&
            elementoActual[0].caracteristicas.multimedia.tipo==tipoRecurso.audios)
            ? 
            <MolAccordionMenu 
              className={"properties__multimedia-Animation"}
              nameButton="Sonido"
              defaultOpen={false}>

              <AtSelect 
                fontSize={11} 
                fontColor={"#2E4467"}  
                widthSelect={120} 
                values={AudioViewerType}
                defaultValue={elementoActual[0].caracteristicas.multimedia.audioViewerType!==undefined 
                  ? 
                  elementoActual[0].caracteristicas.multimedia.audioViewerType
                  :
                   undefined}
                onChange={event=>onChangeAudioViewerType(event.target.value)}
                >
                Visor sonido</AtSelect>
              
            </MolAccordionMenu>
          : ""}

            {
            elementoActual[0]!==undefined &&
            (elementoActual[0].tipoSeleccionado==1 || 
            elementoActual[0].tipoSeleccionado==2 || 
            elementoActual[0].tipoSeleccionado==3 || 
            elementoActual[0].tipoSeleccionado==4 || 
            elementoActual[0].tipoSeleccionado==5 ||
            elementoActual[0].tipoSeleccionado==6 ||
            elementoActual[0].tipoSeleccionado==8) ? 
            <MolAccordionMenu 
              className={"properties__multimedia-Animation"}
              nameButton="Animaciones"
              defaultOpen={false}>

              <AtPopup open={openPupupAnimation}  trigger={<AtButton onClick={()=>{setOpenPupupAnimation(true)}}>Insertar animacion</AtButton>} modal contentStyle={stylePopupContent}  className="popup-insert-multimedia">
                    <InsertAnimationIntoElement  seeker idElement={idElemSeleccionado}></InsertAnimationIntoElement>
              </AtPopup>
              
              <MolAnimationSelected closePopup={onClickClosePopupAnimation} element={elementoActual}></MolAnimationSelected>
              
              <AtButton onClick={onClickdeleteAnimation}>Eliminar animacion</AtButton>

            </MolAccordionMenu>
            : ""}

            {
            elementoActual[0]!==undefined &&
            elementoActual[0].tipoSeleccionado==6 ? 
              <MolAccordionMenu 
              className={""}
              nameButton="Ejercicios"
              defaultOpen={false}>

                <InsertButtonEditExercise  
                  element={elementoActual[0]}
                  language={idiomaFicha}
                  dndExercise={thisFile}
                  ></InsertButtonEditExercise>
              
              

            </MolAccordionMenu>
            : ""}
            <MolAccordionMenu 
              className={"properties__items"}
              nameButton="Figuras musicales"
              defaultOpen={false}>

            <MolTextAndInputText 
              onChange={event=>onChangeFigureSize(event.target.value)} 
              fontSize={11} 
              widthInput={68}  
              type="number"  
              value={thisFigureSize}  
              min={1}  >Tamaño figura:</MolTextAndInputText>
            <button >Activar figura de prueba</button>
              
            </MolAccordionMenu>

            <MolAccordionMenu 
              className={"properties__items"}
              nameButton="Otros"
              defaultOpen={false}>

              <button onClick={eliminarElemento}>Eliminar</button>
              <button onClick={onClickCopiar}>Copiar</button>
              <button onClick={onClickPegar}>Pegar</button>

              <button onClick={onClickDoDndFree}>
                {nameButtonDndFree ?
                    "Quitar arrastrable"
                   :
                    "Hacer arrastrable"
                   }
                
              </button>
              <button onClick={onClickDeleteDraggable}>Quitar ejercicios arrastrable</button>
            </MolAccordionMenu>

          </div>

          

        </div>
        <div className={"panel-modifier-controller__at-button "+openCloseClassNameButton}>
        <AtButton onClick={onClickOpenCloseMenu} image={icoButton} type={typeCss.tertiary} />
      </div>
  </div>
  )
};
/*
<MolAccordionMenu 
              className={"panel-modifier-controller__elements"}
              nameButton="Elementos"
              widthImg={12} 
              heightImg={6}
              imageOpen={Template}
              imageClose={Template}
              defaultOpen={true}
              >

              <EditorElementsListController></EditorElementsListController>

            </MolAccordionMenu>*/

export default PanelEditor;

 
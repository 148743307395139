import React,{useEffect, useState} from 'react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AtEnrichedText,{toolbarTypes} from '../../../../Atoms/At_EnrichedText/At_EnrichedText';

const CreationEnrichedTextOptions=(props)=>{
  let {
      value,
      idAnswer,
      onChange,
      style
  }=props;

  
  const [thisValue,setThisValue]=useState(value);

  useEffect(() => {
      setThisValue(value);
  }, [value]);

  useEffect(() => {
      onChange(idAnswer,thisValue);
  }, [thisValue]);

  

 
  return (
      <div style={style}>
      <AtEnrichedText 
          value={thisValue}
          onChange={setThisValue}
          ></AtEnrichedText>
      </div>
  )
}

export default CreationEnrichedTextOptions;
export const types = {
    POST: 'POST_EDITOR',
    PUT: 'UPDATE_EDITOR',
    DELETE: 'DELETE_EDITOR',
    RESET: 'RESET_EDITOR',
    RELOAD: 'RELOAD_EDITOR',
  };

  const FichasEditorElementosReducer = (state = [], action) => {
    switch (action.type) {
      case types.POST:
        
        return [
          ...state,
          { ...action.payload },
        ];

      case types.PUT:
        return state.map((child) => (child.id === action.payload.id ? action.payload : child));

      case types.DELETE:
        const newValues = [...state];
        
        const index = newValues.findIndex(
          (elem)=>{
            return elem.id===action.id;
          }
          );
       
        newValues.splice(index, 1);
        return newValues;

      case types.RELOAD:
        return action.payload;

      case types.RESET:
        return [];

      case types.CREAR_ELEMENTO:
        return action.payload;
          
        
      default:
        return state;
    }
  };

  export default FichasEditorElementosReducer;
import React, {useEffect,useState} from 'react';
import {httpGetExternal} from '../../../Store/HttpCalls';

import { SVG } from '@svgdotjs/svg.js';


const At_Svg=(props)=>{
    let {
        svg,
        idElement,
        color,
        onClick
    }=props;

    const [draw,setDraw]=useState();
    let idThisSvg="svg-lmyy-exercise"+idElement;
    
    
    useEffect(() => {
        
        
        if(draw!==undefined){
            draw.remove();
        }

        httpGetExternal(svg).then((svgString)=>{
            
            
            let newdraw = SVG().addTo('#'+idThisSvg).size(50, 50);
            newdraw.svg(svgString);
            if(color!==undefined){
                newdraw.fill(color);
            }
            
            setDraw(newdraw);
            
        });
        
    }, [color])

    
    return(
        
        <div onClick={onClick} id={idThisSvg}>
            
        </div>
    )
}


export default At_Svg;
export const types = {
    POST: 'POST_TEMARY',
    PUT: 'UPDATE_TEMARY',
    DELETE: 'DELETE_TEMARY',
    RESET: 'RESET_TEMARY',
    RELOAD: 'RELOAD_TEMARY',
  };
  
 
  
 const TemaryItemsReducer = (state = [], action) => {
    switch (action.type) {
      
      case types.RELOAD:
          
        return action.payload;

      default:
        return state;
    }
  };

  export default TemaryItemsReducer;
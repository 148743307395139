import React from 'react';
import DraggableEdit from 'react-draggable';
import {useDispatch} from 'react-redux';
import {createFigure,undoFigure} from '../../../../Store/Actions/FiguresInViewerAction';
import {ImageFigure} from './MusicalNoteInViewer';

import './BasicStaffViewer.scss';
let idFigure=0;
const BasicStaffViewerPanel=(props)=>{
    
    const dispatch=useDispatch();
    const onClickCreateFigure=(figure)=>{
        
        dispatch(createFigure({figure:figure,id:idFigure}));
        idFigure=idFigure+1;
    }

    const onClickUndoFigure=()=>{
        
        dispatch(undoFigure());
    }

    return  <DraggableEdit>
                <div className="float-menu-basic-staff__draggable-edit">
                <div className="float-menu-basic-staff">
                    <div className="float-menu-basic-staff__background-white">
                        <div className="float-menu-basic-staff__title">PULSA PARA SELECCIONAR</div>
                        <div className="float-menu-basic-staff__row frist-row">
                            <button className="float-menu-basic-staff__button button-contain " 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSredonda)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BSredonda)}}>
                                    <img src={ImageFigure.BSredonda} alt="BSredonda" width={60} height={40}></img>
                            </button>
                            <button className="float-menu-basic-staff__button button-contain "  
                                onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSblancaAlta)}}
                                onClick={()=>{onClickCreateFigure(ImageFigure.BSblancaAlta)}}>
                                <img src={ImageFigure.BSblancaAlta} alt="BSblancaAlta" width={60} height={40}></img>
                            </button>
                            <button className="float-menu-basic-staff__button button-contain " 
                                onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSblancaBaja)}}
                                onClick={()=>{onClickCreateFigure(ImageFigure.BSblancaBaja)}}>
                                <img src={ImageFigure.BSblancaBaja} alt="BSblancaBaja" width={60} height={40}></img>
                            </button>
                            <button className="float-menu-basic-staff__button button-contain " 
                                onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSnegraAlta)}}
                                onClick={()=>{onClickCreateFigure(ImageFigure.BSnegraAlta)}}>
                                <img src={ImageFigure.BSnegraAlta} alt="BSnegraAlta" width={60} height={40}></img>
                            </button>
                            <button className="float-menu-basic-staff__button button-contain " 
                                onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSnegraBaja)}}
                                onClick={()=>{onClickCreateFigure(ImageFigure.BSnegraBaja)}}>
                                <img src={ImageFigure.BSnegraBaja} alt="BSnegraBaja" width={60} height={40}></img>
                            </button>
                            <button className="float-menu-basic-staff__button button-contain " 
                                onTouchStart={()=>{onClickCreateFigure(ImageFigure.BScorcheaAlta)}}
                                onClick={()=>{onClickCreateFigure(ImageFigure.BScorcheaAlta)}}>
                                <img src={ImageFigure.BScorcheaAlta} alt="BScorcheaAlta" width={60} height={40}></img>
                            </button>
                            <button className="float-menu-basic-staff__button button-contain " 
                                onTouchStart={()=>{onClickCreateFigure(ImageFigure.BScorcheaBaja)}}
                                onClick={()=>{onClickCreateFigure(ImageFigure.BScorcheaBaja)}}>
                                <img src={ImageFigure.BScorcheaBaja} alt="BScorcheaBaja" width={60} height={40}></img>
                            </button>
                        </div>
                        <div className="float-menu-basic-staff__row frist-row">
                            <button className="float-menu-basic-staff__button button-contain " 
                                onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSsemicorcheaAlta)}}
                                onClick={()=>{onClickCreateFigure(ImageFigure.BSsemicorcheaAlta)}}>
                                <img src={ImageFigure.BSsemicorcheaAlta} alt="BSsemicorcheaAlta" width={60} height={40}></img>
                            </button>
                            <button className="float-menu-basic-staff__button button-contain " 
                                onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSsemicorcheaBaja)}}
                                onClick={()=>{onClickCreateFigure(ImageFigure.BSsemicorcheaBaja)}}>
                                <img src={ImageFigure.BSsemicorcheaBaja} alt="BSsemicorcheaBaja" width={60} height={40}></img>
                            </button>
                            <button className="float-menu-basic-staff__button button-contain " 
                                onTouchStart={()=>{onClickCreateFigure(ImageFigure.BScorcheaDobleAlta)}}
                                onClick={()=>{onClickCreateFigure(ImageFigure.BScorcheaDobleAlta)}}>
                                <img src={ImageFigure.BScorcheaDobleAlta} alt="BScorcheaDobleAlta" width={60} height={40}></img>
                            </button>
                            <button className="float-menu-basic-staff__button button-contain " 
                                onTouchStart={()=>{onClickCreateFigure(ImageFigure.BScorcheaDobleBaja)}}
                                onClick={()=>{onClickCreateFigure(ImageFigure.BScorcheaDobleBaja)}}>
                                <img src={ImageFigure.BScorcheaDobleBaja} alt="BScorcheaDobleBaja" width={60} height={40}></img>
                            </button>
                            <button className="float-menu-basic-staff__button button-contain " 
                                onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSsemicorcheaCuaAlta)}}
                                onClick={()=>{onClickCreateFigure(ImageFigure.BSsemicorcheaCuaAlta)}}>
                                <img src={ImageFigure.BSsemicorcheaCuaAlta} alt="BSsemicorcheaCuaAlta" width={90} height={40}></img>
                            </button>
                            <button className="float-menu-basic-staff__button button-contain " 
                                onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSsemicorcheaCuaBaja)}}
                                onClick={()=>{onClickCreateFigure(ImageFigure.BSsemicorcheaCuaBaja)}}>
                                <img src={ImageFigure.BSsemicorcheaCuaBaja} alt="BSsemicorcheaCuaBaja" width={90} height={40}></img>
                            </button>
                        </div>

                        <div className="float-menu-basic-staff__row">
                            <div className="float-menu-basic-staff__row__silences">
                                <button className="float-menu-basic-staff__button button-cover "  
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSsilencioDoble)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BSsilencioDoble)}}>
                                    <img src={ImageFigure.BSsilencioDoble} alt="BSsilencioDoble" width={25} height={40}></img>
                                </button>
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSsilencio)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BSsilencio)}}>
                                    <img src={ImageFigure.BSsilencio} alt="BSsilencio" width={25} height={40}></img>
                                </button>
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSsilencioCorchea)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BSsilencioCorchea)}}>
                                    <img src={ImageFigure.BSsilencioCorchea} alt="BSsilencioCorchea" width={25} height={40}></img>
                                </button>
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSsilencioSemicorchea)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BSsilencioSemicorchea)}}>
                                    <img src={ImageFigure.BSsilencioSemicorchea} alt="BSsilencioSemicorchea" width={25} height={40}></img>
                                </button>
                            </div>

                            <div>
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSnegraAltaNotaMedia)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BSnegraAltaNotaMedia)}}>
                                    <img src={ImageFigure.BSnegraAltaNotaMedia} alt="BSnegraAltaNotaMedia" width={25} height={40}></img>
                                </button>
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSnegraBajaNotaMedia)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BSnegraBajaNotaMedia)}}>
                                    <img src={ImageFigure.BSnegraBajaNotaMedia} alt="BSnegraBajaNotaMedia" width={25} height={40}></img>
                                </button>

                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSnegraAltaNotaAbajo)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BSnegraAltaNotaAbajo)}}>
                                    <img src={ImageFigure.BSnegraAltaNotaAbajo} alt="BSnegraAltaNotaAbajo" width={25} height={40}></img>
                                </button>
                                
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSnegraBajaNotaArriba)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BSnegraBajaNotaArriba)}}>
                                    <img src={ImageFigure.BSnegraBajaNotaArriba} alt="BSnegraBajaNotaArriba" width={25} height={40}></img>
                                </button>
                                
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSsemicorcheaAltaNotaMedia)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BSsemicorcheaAltaNotaMedia)}}>
                                    <img src={ImageFigure.BSsemicorcheaAltaNotaMedia} alt="BSsemicorcheaAltaNotaMedia" width={25} height={40}></img>
                                </button>
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSsemicorcheaBajaNotaMedia)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BSsemicorcheaBajaNotaMedia)}}>
                                    <img src={ImageFigure.BSsemicorcheaBajaNotaMedia} alt="BSsemicorcheaBajaNotaMedia" width={25} height={40}></img>
                                </button>
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSsemicorcheaBajaNotaAbajo)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BSsemicorcheaBajaNotaAbajo)}}>
                                    <img src={ImageFigure.BSsemicorcheaBajaNotaAbajo} alt="BSsemicorcheaBajaNotaAbajo" width={25} height={40}></img>
                                </button>
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSsemicorcheaBajaNotaArriba)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BSsemicorcheaBajaNotaArriba)}}>
                                    <img src={ImageFigure.BSsemicorcheaBajaNotaArriba} alt="BSsemicorcheaBajaNotaArriba" width={25} height={40}></img>
                                </button>
                                
                            </div>

                            <div className="float-menu-basic-staff__row__alterations">
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSbemol)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BSbemol)}}>
                                    <img src={ImageFigure.BSbemol} alt="BSbemol" width={25} height={40}></img>
                                </button>
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSsostenido)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BSsostenido)}}>
                                    <img src={ImageFigure.BSsostenido} alt="BSsostenido" width={25} height={40}></img>
                                </button>
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSbecuadro)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BSbecuadro)}}>
                                    <img src={ImageFigure.BSbecuadro} alt="BSbecuadro" width={25} height={40}></img>
                                </button>
                            </div>
                            
                        </div>
                        <div className="float-menu-basic-staff__row frist-row">
                            <div className="float-menu-basic-staff__row__signs-of-prolongation">
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSpuntillo)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BSpuntillo)}}>
                                    <img src={ImageFigure.BSpuntillo} alt="BSpuntillo" width={25} height={40}></img>
                                </button>
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BScalderonInverso)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BScalderonInverso)}}>
                                    <img src={ImageFigure.BScalderonInverso} alt="BScalderonInverso" width={25} height={40}></img>
                                </button>
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BScalderon)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BScalderon)}}>
                                    <img src={ImageFigure.BScalderon} alt="BScalderon" width={25} height={40}></img>
                                </button>
                                
                            </div>
                            <div className="float-menu-basic-staff__row__compasses">
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BS2por4)}} 
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BS2por4)}}>
                                    <img src={ImageFigure.BS2por4} alt="BS2por4" width={25} height={40}></img>
                                </button>
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BS3por4)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BS3por4)}}>
                                    <img src={ImageFigure.BS3por4} alt="BS3por4" width={25} height={40}></img>
                                </button>
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BS4por4)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BS4por4)}}>
                                    <img src={ImageFigure.BS4por4} alt="BS4por4" width={25} height={40}></img>
                                </button>
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BS3por8)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BS3por8)}}>
                                    <img src={ImageFigure.BS3por8} alt="BS3por8" width={25} height={40}></img>
                                </button>
                                
                            </div>
                            <div className="float-menu-basic-staff__row__repeating-signs">
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSrepetidorInicio)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BSrepetidorInicio)}}>
                                    <img src={ImageFigure.BSrepetidorInicio} alt="BSrepetidorInicio" width={25} height={40}></img>
                                </button>
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSrepetidorFinal)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BSrepetidorFinal)}}>
                                    <img src={ImageFigure.BSrepetidorFinal} alt="BSrepetidorFinal" width={25} height={40}></img>
                                </button>
                            </div>
                            <div className="float-menu-basic-staff__row__clefs">
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSclaveSol)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BSclaveSol)}}>
                                    <img src={ImageFigure.BSclaveSol} alt="BSclaveSol" width={25} height={40}></img>
                                </button>
                                <button className="float-menu-basic-staff__button button-cover" 
                                    onTouchStart={()=>{onClickCreateFigure(ImageFigure.BSclaveFa)}}
                                    onClick={()=>{onClickCreateFigure(ImageFigure.BSclaveFa)}}>
                                    <img src={ImageFigure.BSclaveFa} alt="BSclaveFa" width={25} height={40}></img>
                                </button>
                            </div>
                        </div>
                        
                        
                        <button className="undo-button" onClick={onClickUndoFigure} onTouchStart={onClickUndoFigure}>Deshacer</button>
                    </div>
                </div>
                </div>
            </DraggableEdit>

}


export default BasicStaffViewerPanel;
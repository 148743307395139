import { Group, Path } from '@progress/kendo-drawing';


export const drawScene=(surface,reticulaSeparacion,altoReticula,anchoReticula) =>{
    const group = new Group();

    let path; 
    
    
    for(let i=reticulaSeparacion;i<altoReticula;i+=reticulaSeparacion){

        path = new Path({
            stroke: {
                color: "black",
                width: 1,
                opacity:1

            }
        });
        
        path.moveTo(i, 0).lineTo(i, anchoReticula).close();

        group.append(path);
    }
    for(let i=reticulaSeparacion;i<anchoReticula;i+=reticulaSeparacion){

        path = new Path({
            stroke: {
                color: "black",
                width: 1,
                opacity:1

            }
        });
        
        path.moveTo(0, i).lineTo(altoReticula, i).close();

        group.append(path);
    }
    

    

    surface.draw(group);
}


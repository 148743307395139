import React,{useState,useEffect} from 'react';
import {useDispatch} from 'react-redux';
import AtPopup from '../../../../Atoms/At_Popup/At_Popup';
import ImageTestViewer from '../ImageTestViewer';
import {idiomas} from '../../../../../Constantes/idiomas';

import {addExerciseResult} from '../../../../../Store/Actions/ExerciseResultsActions';

import ExerciseResponse1 from '../../../../../Images/ExerciseResponse1.png';
import ExerciseResponse2 from '../../../../../Images/ExerciseResponse2.png';
import ExerciseResponse3 from '../../../../../Images/ExerciseResponse3.png';
import ExerciseResponse5 from '../../../../../Images/IconoEscribir.png';
import ExerciseResponse6 from '../../../../../Images/IconoInstrumento.png';
import ExerciseResponse7 from '../../../../../Images/IconoInvestigar.png';
import ExerciseResponse8 from '../../../../../Images/IconoPentagrama.png';

import {PositionAnswer,imagePrevAnswer,imagePrevAnswerSelect} from '../../../../../Constantes/ExercisesVars';
import {quitarPxFinal} from '../../../../../Services/CSSTratamiento';
import './AnswerIT.scss';

import { exercises } from '../../../../../Constantes/Exercises';
const AnswerIT=(props)=>{
    let {
        exercise,
        language,
        styleElement,
        viewer,
        correctExercises,
        answerExercises
    }=props;
    
    const dispatch=useDispatch();
    const [firstLoad, setFirstLoad] = useState(true);

    let questionDataByLanguage;

    questionDataByLanguage=exercise.questionData.filter((elem)=>{
        return elem.language==language
    });

    if(questionDataByLanguage[0]===undefined){
        questionDataByLanguage=exercise.questionData.filter((elem)=>{
            return elem.language==idiomas.castellano;
        });
    }
    questionDataByLanguage=questionDataByLanguage[0];

    const [thisResponse,setThisResponse]=useState([]);

    useEffect(() => {
            
        if(answerExercises!==undefined && answerExercises[0]!==undefined){
            let thisAnswerExercises=answerExercises.filter((elem)=>{
                return elem.exerciseData.idLinkExercise==exercise.idLinkExercise && elem.exerciseData.typeExercise == exercises.imagesTest;
            });

            if(thisAnswerExercises[0]!==undefined && 
                thisAnswerExercises[0].exerciseData!==undefined && 
                thisAnswerExercises[0].exerciseData.answer!==undefined){

                setThisResponse([thisAnswerExercises[0].exerciseData.answer]);
               
            }
        }
    }, [answerExercises]);

    let styleImg={
        width:styleElement.height,
        //height:styleElement.height,
        maxWidth:"50px",
        maxHeight:"50px",
    };

    if(quitarPxFinal(styleElement.height)<=quitarPxFinal(styleElement.width)){
        styleImg={
            width:styleElement.height,
            maxWidth:"50px",
            maxHeight:"50px",
        };
    }
    if(quitarPxFinal(styleElement.height)>quitarPxFinal(styleElement.width)){
        
        styleImg={
            width:styleElement.width,
            maxWidth:"50px",
            maxHeight:"50px",
        };
    }

    let classContent,classImg;
    if(exercise.positionAnswer!==undefined){
        if(PositionAnswer.center==exercise.positionAnswer){
            classContent=" div-content-it__center";
            classImg=" img-it__center";
            
        }
        if(PositionAnswer.leftTop==exercise.positionAnswer){
            classContent=" div-content-it__leftTop";
            classImg=" img-it__leftTop";
            
        }
        if(PositionAnswer.leftBottom==exercise.positionAnswer){
            classContent=" div-content-it__leftBottom";
            classImg=" img-it__leftBottom";
        }
        
        
    }else{
        classContent=" div-content-it__center";
        classImg=" img-it__center";
    }

    let imgPrevAnswer;
    
   if(exercise.iconPrevAnswer!==undefined){
        if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse1){
            imgPrevAnswer=ExerciseResponse1;
            
        }
        if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse2){
            imgPrevAnswer=ExerciseResponse2;
            
        }
        if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse3){
            imgPrevAnswer=ExerciseResponse3;
         
        }
        if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse4){
            imgPrevAnswer=ExerciseResponse3;
            styleImg={
                width:"20px",
            }
        }
        if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse5){
            imgPrevAnswer=ExerciseResponse5;
        }
        if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse6){
            imgPrevAnswer=ExerciseResponse6;
        }
        if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse7){
            imgPrevAnswer=ExerciseResponse7;
        }
        if(exercise.iconPrevAnswer==imagePrevAnswer.ExerciseResponse8){
            imgPrevAnswer=ExerciseResponse8;
        }
        
   }else{
        imgPrevAnswer=ExerciseResponse1;
   }

   useEffect(() => {
    if(correctExercises==true){
        let replyToSend={answer:thisResponse[0],thisExercise:exercise,typeExercise:exercises.imagesTest,isCorrect:setisCorrectExercise()};
     
         dispatch(addExerciseResult(replyToSend));
        }
    }, [correctExercises]);

    useEffect(() => {
        setClassNameIncorrectAnswer("");
        if(firstLoad && answerExercises){
            setFirstLoad(false);
            setisCorrectExercise();
        }
       }, [thisResponse])

    const [classNameIncorrectAnswer,setClassNameIncorrectAnswer]=useState("");
    const setisCorrectExercise=()=>{
        if(thisResponse[0]!==undefined && thisResponse[0].correct!==undefined){
            if(thisResponse[0].correct==false){
                setClassNameIncorrectAnswer("class-name-incorrect-answer-IT");
               }
            return thisResponse[0].correct;
        }else{
            return false;
        }
    }


    if(viewer){
        styleImg={
            ...styleImg,
            paddingLeft: "2px",
            paddingTop: "2px",
        }
        
        return(
            <AtPopup classNameOccupyAll="image-test-viewer__popup" noCloseInClickOut trigger={
                0!==thisResponse.length  ?
                <div className={"div-response-it div-response-it__overflow "+ classContent}>
                    {thisResponse.map((elem)=>{
                        return <img className={classNameIncorrectAnswer} width="100%" height="100%" src={elem.answer} alt="Imagen no disponible"/>
                    })}
                </div>
                :
                <div className={"div-response-it "+ classContent}>
                    <img className={classImg} style={styleImg} src={imgPrevAnswer} alt="imagen no disponible"/>
                </div>
            }    
            transparent
            >
                <ImageTestViewer style={exercise.fontStyle} typeQuestion={exercise.typeQuestion} value={thisResponse} onSaveResponse={setThisResponse} question={questionDataByLanguage}></ImageTestViewer>
            </AtPopup>
        )
    }else{
        
        return(
            <div className={"div-response-it "+ classContent}>
                <img className={classImg} style={styleImg} src={imgPrevAnswer} alt="imagen no disponible"/>
            </div>
        )
    }
}

export default AnswerIT;
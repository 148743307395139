export const FillableFieldType={
    stateText:"stateText",
    fillableField:"fillableField"
}

export const PositionAnswer={
    center:"center",
    leftTop:"leftTop",
    leftBottom:"leftbottom"
}

export const PositionAnswerSelect=[
    ["center","Centrado"],
    ["leftTop","Arriba Izquierda"],
    ["leftbottom","Abajo Izquierda"]
]

export const imagePrevAnswer={
    ExerciseResponse1:"ExerciseResponse1",
    ExerciseResponse2:"ExerciseResponse2",
    ExerciseResponse3:"ExerciseResponse3",
    ExerciseResponse4:"ExerciseResponse4",
    ExerciseResponse5:"ExerciseResponse5",
    ExerciseResponse6:"ExerciseResponse6",
    ExerciseResponse7:"ExerciseResponse7",
    ExerciseResponse8:"ExerciseResponse8"
}

export const imagePrevAnswerSelect=[
    ["ExerciseResponse1","Interrogacion 1"],
    ["ExerciseResponse2","Interrogacion 2"],
    ["ExerciseResponse3","Rayas dinamicas"],
    ["ExerciseResponse4","Rayas fijas"],
    ["ExerciseResponse5","Escribir"],
    ["ExerciseResponse6","Instrumento"],
    ["ExerciseResponse7","Investigar"],
    ["ExerciseResponse8","Pentagrama"],
]


export const  typeQuestionOfIT={
    text:"text",
    image:"image"
}

export const  typeQuestionOfITSelect=[
    ["image","Imagen"],
    ["text","Texto"],
    
]